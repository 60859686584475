/** Helper function to generate email content. */

/**
 * Helper function to generate email content.
 * @category Helpers
 * @module EmailTemplates
 * @param {string} title - The title of the email.
 * @param {Array} fields - An array of objects representing the fields in the email.
 * @param {string} fields.label - The label of the field.
 * @param {string} fields.value - The value of the field.
 * @returns {string} - The generated email content.
 */
import { FormatDateToMDY, FormatEmailTemplateTime } from '../imports/API/TextFormatingFunctions';

/**
 * Generates an email content with the given title and fields.
 *
 * @param {string} title - The title of the email.
 * @param {Array<{ label: string, value: string }>} fields - The array of fields containing label and value.
 * @returns {string} The generated email content.
 * @throws {Error} If an error occurs during the generation process.
 * @example generateEmailContent('Customer Call Log', [{ label: 'Dispatcher', value: 'John Doe' }, { label: 'KioskID', value: '1234' }]);
 * @example generateEmailContent('Technician Call Log', [{ label: 'Dispatcher', value: 'John Doe' }, { label: 'KioskID', value: '1234' }]);
 * @example generateEmailContent('Store Call Log', [{ label: 'Dispatcher', value: 'John Doe' }, { label: 'KioskID', value: '1234' }]);
 * @example generateEmailContent('Bug Report', [{ label: 'Bug Report #1234', value: 'This is a bug report.' }, { label: '', value: '-John Doe' }]);
 * @example generateEmailContent('End Of Shift Report', [{ label: '', value: 'This is the end of shift report.' }, { label: '', value: '-John Doe' }]);
 * @memberof module:EmailTemplates
 * @method generateEmailContent
 */
const generateEmailContent = (title, fields) => {
	if (title === 'End Of Shift Report') {
		let rows = fields.map(({ label, value }) => `<tr><td width="300">${label}</td></tr><tr><td>${value}</td></tr>`).join('');
		const msg = `<div width="100%",style="text-align:left;">
		<h1 style="text-align:center;">${title}</h1>
		<table border="0", width="100%">
		<tbody style="text-align:left;">
				${rows}
				</tbody>
				</table >
				</div >`;
		return msg;
	} else {
		let rows = fields.map(({ label, value }) => `<tr><td width="300">${label}</td> <td>${value}</td></tr>`).join('');
		const msg = `<div width="100%",style="text-align:left;">
		<h1 style="text-align:center;">${title}</h1>
		<table border="1", width="100%">
		<tbody style="text-align:left;">
				${rows}
				</tbody>
				</table >
				</div >`;
		return msg;
	}
};

// Helper function to map props array to an object
/**
 * Maps an array of props to an object with element IDs as keys and values as values.
 *
 * @param {Array} propsArray - The array of props to be mapped.
 * @returns {Object} - The object with element IDs as keys and values as values.
 * @throws {Error} - If an error occurs during the mapping process.
 * @example mapPropsToFields([{ element_id: 'Dispatcher', value: 'John Doe' }, { element_id: 'KioskID', value: '1234' }]);
 * @memberof module:EmailTemplates
 * @method mapPropsToFields
 */
const mapPropsToFields = (propsArray) => {
	const propsObject = {};
	propsArray.forEach(({ element_id, value }) => {
		propsObject[element_id] = value;
	});
	return propsObject;
};

/**
 * Generates an email content for the Customer Call Log.
 *
 * @param {Array} propsArray - The array of props.
 * @returns {string} - The generated email content.
 * @throws {Error} - If an error occurs during the generation process.
 * @example CustomerLogEmail([{ element_id: 'Dispatcher', value: 'John Doe' }, { element_id: 'KioskID', value: '1234' }]);
 * @memberof module:EmailTemplates
 * @method CustomerLogEmail
 */
export const CustomerLogEmail = (propsArray) => {
	try {
		const props = mapPropsToFields(propsArray);
		const fields = [
			{ label: 'Dispatcher', value: props.user || props.Dispatcher },
			{ label: 'KioskID', value: props.kiosk_location },
			{ label: 'Date Of Issue', value: FormatDateToMDY(props.date_of_issue) },
			{ label: 'Person Calling', value: props.person_calling },
			{ label: 'Reason For Calling', value: props.reason_for_call.replace(/\'/g, '') },
			{ label: 'Plate Number', value: props.plate_number },
			{ label: 'Last 4 Vin', value: props.vin },
			{ label: 'Customer Name', value: props.customer_name.replace(/[\'\"\`]/g, '') },
			{ label: 'Customer Number', value: props.customer_phone },
			{ label: 'Is Service Tech Needed', value: props.kiosk_needs_service },
			{ label: 'Sticker Resolution', value: props.sticker_resolution.replace(/\'/g, '') },
			{ label: 'Notes', value: props.notes.replace(/[\'\"\`]/g, '') },
		];
		return generateEmailContent('Customer Call Log', fields);
	} catch (e) {
		console.error(`ERROR: EmailTemplates.CustomerLogEmail: ${e} on ${new Date()}`);
	}
};

/**
 * Generates an email content for a technician call log.
 *
 * @param {Array} propsArray - The array of properties for the email template.
 * @returns {string} - The generated email content.
 * @throws {Error} - If an error occurs during the generation process.
 * @example TechLogEmail([{ element_id: 'Dispatcher', value: 'John Doe' }, { element_id: 'KioskID', value: '1234' }]);
 * @memberof module:EmailTemplates
 * @method TechLogEmail
 */
export const TechLogEmail = (propsArray) => {
	try {
		const props = mapPropsToFields(propsArray);
		const fields = [
			{ label: 'Dispatcher', value: props.user },
			{ label: 'KioskID', value: props.kiosk_location },
			{ label: 'Date Of Issue', value: FormatDateToMDY(props.date_of_issue) },
			{ label: 'Time Of Call', value: FormatEmailTemplateTime(props.call_time) },
			{ label: 'Call Duration', value: `${props.call_duration} mins` },
			{ label: 'Technician Calling', value: props.technician },
			{ label: 'Reason For Calling', value: props.reason_for_call },
			{ label: 'Call Results', value: props.call_results },
			{ label: 'Notes', value: props.notes },
		];
		return generateEmailContent('Technician Call Log', fields);
	} catch (e) {
		console.error(`ERROR: EmailTemplates.TechLogEmail: ${e} on ${new Date()}`);
	}
};

/**
 * Generates an email content for the Store Call Log.
 *
 * @param {Array} propsArray - The array of properties.
 * @returns {string} - The generated email content.
 * @throws {Error} - If an error occurs during the generation process.
 * @example StoreLogEmail([{ element_id: 'Dispatcher', value: 'John Doe' }, { element_id: 'KioskID', value: '1234' }]);
 * @memberof module:EmailTemplates
 * @method StoreLogEmail
 */
export const StoreLogEmail = (propsArray) => {
	try {
		const props = mapPropsToFields(propsArray);
		const fields = [
			{ label: 'Dispatcher', value: props.user },
			{ label: 'KioskID', value: props.kiosk_location },
			{ label: 'Returned Voicemail', value: props.rvm ? 'Yes' : 'No' },
			{ label: 'Store Phone', value: props.store_phone },
			{ label: 'Date Of Issue', value: FormatDateToMDY(props.date_of_issue) },
			{ label: 'Issue', value: props.issue },
			{ label: 'Notes', value: props.notes },
		];
		return generateEmailContent('Store Call Log', fields);
	} catch (e) {
		console.error(`ERROR: EmailTemplates.StoreLogEmail: ${e} on ${new Date()}`);
	}
};

/**
 * Generates the email content for a bug report.
 *
 * @param {Array} propsArray - The array of props.
 * @returns {string} - The generated email content.
 * @throws {Error} - If an error occurs while generating the email content.
 * @example BugReportEmail([{ element_id: 'Dispatcher', value: 'John Doe' }, { element_id: 'KioskID', value: '1234' }]);
 * @memberof module:EmailTemplates
 * @method BugReportEmail
 */
export const BugReportEmail = (propsArray) => {
	try {
		const props = mapPropsToFields(propsArray);
		const fields = [
			{ label: `Bug Report #${props.bug_id}`, value: props.notes },
			{ label: '', value: `-${props.user}` },
		];
		return generateEmailContent('Bug Report', fields);
	} catch (e) {
		console.error(`ERROR: EmailTemplates.BugReportEmail: ${e} on ${new Date()}`);
	}
};

/**
 * Generates the email content for a Service Alarm Report.
 *
 * @param {Array} propsArray - The array of props.
 * @returns {string} - The generated email content.
 * @throws {Error} - If an error occurs while generating the email content.
 * @example BugReportEmail([{ element_id: 'Dispatcher', value: 'John Doe' }, { element_id: 'KioskID', value: '1234' }]);
 * @memberof module:EmailTemplates
 * @method ServiceAlarmEmail
 */
export const ServiceAlarmEmail = (propsArray) => {
	try {
		const props = mapPropsToFields(propsArray);
		const fields = [
			{ label: `Alert Resolution`, value: props.alert_resolution },
			{ label: 'Call Time', value: props.call_time },
			{ label: `Date of Issue`, value: props.date_of_issue },
			{ label: 'Notes', value: props.notes },
			{ label: `Service Name`, value: props.service_name },
			{ label: 'Urgent Report', value: props.urgent_report },
			{ label: 'User', value: `-${props.user}` },
		];
		return generateEmailContent('Service Alarm Report', fields);
	} catch (e) {
		console.error(`ERROR: EmailTemplates.ServiceAlarmEmail: ${e} on ${new Date()}`);
	}
};

/**
 * Generates an End Of Shift Email template.
 *
 * @param {Array} propsArray - An array of props.
 * @returns {string} - The generated email content.
 * @throws {Error} - If an error occurs during the generation process.
 * @example EOSEmail([{ element_id: 'Dispatcher', value: 'John Doe' }, { element_id: 'KioskID', value: '1234' }]);
 * @memberof module:EmailTemplates
 * @method EOSEmail
 */
export const EOSEmail = (propsArray) => {
	try {
		const props = mapPropsToFields(propsArray);
		const fields = [
			{ label: '', value: props.notes },
			{ label: '', value: `-${props.user}` },
		];
		return generateEmailContent('End Of Shift Report', fields);
	} catch (e) {
		console.error(`ERROR: EmailTemplates.EOSEmail: ${e} on ${new Date()}`);
	}
};


/**
 * Generates an email content for location request.
 *
 * @param {Array} propsArray - The array of props.
 * @returns {string} - The generated email content.
 * @throws {Error} - If an error occurs during the generation process.
 */
export const locationRequestEmail = (propsArray) => {
	try {
		const props = mapPropsToFields(propsArray);
		const fields = [
			{ label: 'Store Name', value: props.store_name },
			{label: 'Date of Request', value: FormatDateToMDY(props.date_of_call)},
			{ label: 'Contact Name', value: props.customer_name },
			{ label: 'Contact Phone', value: props.customer_phone },
			{ label: 'Store Phone', value: props.store_phone },
			{ label: 'City', value: props.store_city },
			{ label: 'Notes', value: props.notes },
			{ label: 'Third Party Renewals', value: props.third_party_renewals},
		];
		return generateEmailContent('Location Request', fields);
	} catch (e) {
		console.error(`ERROR: EmailTemplates.locationRequestEmail: ${e} on ${new Date()}`);
	}
}