
import React from 'react';

const gradient = [
    'rgba(0, 255, 255, 0)',
    'rgba(0, 255, 255, 1)',
    'rgba(0, 191, 255, 1)',
    'rgba(0, 127, 255, 1)',
    'rgba(0, 63, 255, 1)',
    'rgba(0, 0, 255, 1)',
    'rgba(0, 0, 223, 1)',
    'rgba(0, 0, 191, 1)',
    'rgba(0, 0, 159, 1)',
    'rgba(0, 0, 127, 1)',
    'rgba(63, 0, 91, 1)',
    'rgba(127, 0, 63, 1)',
    'rgba(191, 0, 31, 1)',
    'rgba(255, 0, 0, 1)'
]

const Heatmap = ({data,visible}) => {
    const mapData = data.map(location => ({
        location: new google.maps.LatLng(location.Latitude,location.Longitude), weight: location.times_serviced
    }))

    const {HeatmapLayer} = require('@react-google-maps/api')

    return visible ? (<HeatmapLayer data={mapData} options={{gradient: gradient}} />) : null;
}

function compareProps(prevProps,nextProps){
    if(prevProps.visible == nextProps.visible && prevProps.data.length == nextProps.data.length){
        return true
    }
    return false
}

export default React.memo(Heatmap,compareProps);