import React, { useEffect, useState } from 'react';
import '../StyleSheets/TechPortal.css';
import RTILogo from '../../public/Images/regitechLogo.png';
import Login from '../../public/Images/logout.png';
import {Link} from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import { FaBars, FaInbox, FaFileAlt, FaSignOutAlt, FaQuestionCircle, FaSearch } from 'react-icons/fa';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from 'react-router-dom';

import AlertBar from './AlertBar';

const MENU = [
  {
    name: 'Jobs',
    icon: <FaInbox />,
    action: 'jobs'
  },
  {
    name: 'Service Log',
    icon: <FaFileAlt />,
    action: 'sr'
  },
  {
    name: 'Tech Help',
    icon: <FaQuestionCircle />,
    action: 'help'
  },
  {
    name: 'Logout',
    icon: <FaSignOutAlt />,
    action: 'logout'
  }
];

const NAVMENU = [
  {
    name: 'Jobs',
    icon: <FaInbox />,
    action: 'jobs'
  },
  {
    name: 'Service Log',
    icon: <FaFileAlt />,
    action: 'sr'
  },
  {
    name: 'Tech Help',
    icon: <FaQuestionCircle />,
    action: 'help'
  },
];

const NavBar = () => {
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [name, setName] = useState(sessionStorage.getItem('techName').split(' ')[0].trim());
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const { vertical, horizontal, open } = state;
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');

  const GetUser = () => {
    let data = window.sessionStorage.getItem('techName');

    if (data !== null) {
      return data;
    } else {
      return "Login";
    }
  };

  const LogoutUser = async () => {
    try {
      const user = await fetch(GetUser());

      if (user !== null) {
        setSeverity('success');
        setMessage(`${name} logout is successful`);
        setOpenSnack(true);
        window.sessionStorage.removeItem('techName');
        window.sessionStorage.removeItem('tid');
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
      setSeverity('error');
      setMessage(`logout was not successful, please contact tech help for further assistance.`);
      setOpenSnack(true);
    }
  };

  const RenderUserName = () => {
    let divToRender = null;

    let user = GetUser();

    if (user !== 'Login') {
      divToRender = (
        <li className="dropdown">
          <a className="dropbtn">{user}</a>
          <div className="dropdown-content">
            <Link to="/tech-portal/login" onClick={LogoutUser}>Logout</Link>
          </div>
        </li>
      );
    } else {
      divToRender = (
        <li>{user}</li>
      );
    }
    return divToRender;
  };

  const toggleDrawer = () => {
    setMenuOpen(prevState => !prevState);
  };

  const renderMenuItems = () => {
    return (
      <List>
        <ListItem className='drawer-title'>{name}</ListItem>
        <div className='divider'></div>
        {MENU.map(item => (
          <div id={item.action} key={item.name}>
            <Button style={{ fontSize: 16 }} className='list-button' key={item.name} id={item.action} startIcon={item.icon ? item.icon : null} variant="text" onClick={() => handleActionSelect(item.action)}>{item.name}</Button>
          </div>
        ))}
      </List>
    );
  };

  const handleActionSelect = (_action) => {
    switch (_action) {
      case MENU[0].action: navigate('/tech-portal');
        break;
      case MENU[1].action: navigate('/tech-portal/service');
        break;
      case MENU[2].action: navigate('/tech-portal/help');
        break;
      case MENU[3].action: LogoutUser(); window.location.reload();
        break;
    }
    toggleDrawer();
  };

  const handleNavMenu = (_action) => {
    switch (_action) {
      case MENU[0].action: navigate('/tech-portal');
        break;
      case MENU[1].action: navigate('/tech-portal/service');
        break;
      case MENU[2].action: navigate('/tech-portal/help');
        break;
    }
  };

  const logoutMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const logoutMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = (e) => {
    if (e.keyCode === 13) {
      const search_val = e.target.value;
      navigate(`/tech-portal/help?search=${search_val}`);
    }
  };

  return (
    <nav className="navbar">
      <Drawer anchor='right' open={menuOpen} onClose={toggleDrawer}>
        {renderMenuItems()}
      </Drawer>
      <div className="navbar-inner">
        <div className="inner-container">
          <ul className="nav" id="menu">
            <li className="brand-container">
              <a className="brand" href="../tech-portal">
                <img className='RTILogoImage' name='RTILogo' width="200px" src={`/${RTILogo}`} alt="" />
              </a>
            </li>
            <li className='nav-large flex-just-center flex-wrap flex-start flex-just-between'>
              {NAVMENU.map(item => (
                <div id={item.action} key={item.name}>
                  <Button sx={{ fontSize: 16 }} key={item.name} id={item.action} startIcon={item.icon ? item.icon : null} variant="text" onClick={() => handleNavMenu(item.action)}>{item.name}</Button>
                </div>
              ))}
            </li>
            <li className='nav-large'>
              <div className='flex-align-center'>
                <FormControl style={{ marginRight: 90 }} variant="standard">
                  <Input
                    style={{ width: 200, fontSize: 16 }}
                    id="help-input"
                    autoFocus
                    placeholder='Help...'
                    startAdornment={
                      <InputAdornment position="start">
                        <FaSearch />
                      </InputAdornment>
                    }
                    onKeyDown={handleSearch}
                  />
                </FormControl>
                <Button sx={{ marginRight: 8, fontSize: 16 }} aria-controls='menu-list-grow' aria-haspopup='true' variant='contained' onClick={logoutMenuOpen}>
                  {name}
                </Button>
                <Menu id="menu-list-grow"
                  sx={{ backgroundColor: '#f5f5f5', borderRadius: '4px', boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)' }}
                  elevation={0}
                  getcontentanchorel={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={logoutMenuClose}
                >
                  <MenuItem
                    onClick={LogoutUser}
                    sx={{ fontSize: '14px', padding: '10px 20px', '&:hover': { backgroundColor: '#e0e0e0' }}}
                  >
                    logout
                  </MenuItem>
                  <AlertBar
                    anchorOrigin={{ vertical, horizontal }}
                    autoHideDuration={3000}
                    visible={openSnack}
                    severity={severity}
                    message={message}
                    key={vertical + horizontal}
                  />
                </Menu>
              </div>
            </li>
            <li className='nav-small'>
              <div className='flex-align-center'>
                <IconButton className='nav-small' onClick={toggleDrawer} size="large">
                  <FaBars />
                </IconButton>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
