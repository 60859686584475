/**
 * TODO: If the part is a modem, allow the creation of a basic modem label on a similar page.
 * TODO: Change the modal's styling. Use MUI.
 */

import React, { useEffect, useState } from 'react';
import { DateToLocale, DateToMDY } from '../API/TextFormatingFunctions';
import Spinner from '../../components/Spinner';
import AlertBar from '../../components/AlertBar';
import { getEmailSignature, validateBinLocation } from '../../utilities/util';
import { SingularInventoryPartController } from './InventoryItemControllers/SingularItemController/SingularInventoryPartController';
import { BulkInventoryController } from './InventoryItemControllers/BulkInventoryController/BulkInventoryController';
import { AttachItemModal } from './InventoryItemModals/AttachItemModal';
import { DetachItemModal } from './InventoryItemModals/DetachItemModal';
import { ItemAuditModal } from './InventoryItemModals/ItemAuditModal';
import { NewItemModal } from './InventoryItemModals/NewItemModal';
import { useCustomContext } from '../../hoc/Context';
import { useLocation } from 'react-router-dom';

import '../../StyleSheets/InventorySystem.css';

const InventoryItem = ({ history }) => {
	const location = useLocation();

	const [core, path, subPath] = window.location.pathname.split('/');
	const item = location.state
		? location.state.inv_item
		: subPath === 'bulk-item'
		? { barcode: null, bulk: true }
		: { barcode: null };
	const models = location.state && location.state.models ? location.state.models : [];
	const [newItem, setNewItem] = useState(false);

	const [itemCreated, setItemCreated] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [auditOpen, setAuditOpen] = useState(false);
	const [vendors, setVendors] = useState([]);
	const [modelNum, setModelNum] = useState(null);
	const [form, setForm] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [errors, setErrors] = useState({});
	const [isModem, setIsModem] = useState(false);
	const [loading, setLoading] = useState(true);
	const [audit, setAudit] = useState([]);
	const [editing, setEditing] = useState(false);
	const [servicing, setServicing] = useState(false);
	const [visible, setVisible] = useState(false);
	const [tabIdx, setTabIdx] = useState(0);
	const [bulk, setBulk] = useState([]);
	const [bulkScan, setBulkScan] = useState([]);
	const [itemOpen, setItemOpen] = useState(false);
	const [detachOpen, setDetachOpen] = useState(false);
	const [bins, setBins] = useState([]);
	const [itemType, setItemType] = useState('');
	const [newRefurb, setNewRefurb] = useState('');
	const [serviceSelect, setServiceSelect] = useState([]);
	const [carrierOption, setCarrierOption] = useState(
		form && form.item_info !== undefined && form.item_info && form.item_info.carrier ? form.item_info.carrier : ''
	);
	const [prevNotes, setPrevNotes] = useState([]);
	const [snackbar, setSnackbar] = useState({
		visible: false,
		message: '',
		severity: 'success',
	});
	const { userState, userDispatch } = useCustomContext();

	const singularItemProps = {
		bins: bins,
		item: item,
		models: models,
		form: form,
		loading: loading,
		isModem: isModem,
		itemType: itemType,
		onToggleItem: onToggleItem,
		tabIdx: tabIdx,
		handleAutoTab: handleAutoTab,
		editing: editing,
		servicing: servicing,
		newItem: newItem,
		errors: errors,
		onNavigateToItem: onNavigateToItem,
		toggleEditing: toggleEditing,
		toggleServicing: toggleServicing,
		handleUpdateItem: handleUpdateItem,
		onToggleDetach: onToggleDetach,
		onToggleAudit: onToggleAudit,
		newRefurb: newRefurb,
		serviceSelect: serviceSelect,
		handleNewRefurbSelect: handleNewRefurbSelect,
		carrierOption: carrierOption,
		handleCarrierSelect: handleCarrierSelect,
		handleServiceSelect: handleServiceSelect,
		deployCount: deployCount,
		onAddItem: onAddItem,
		userState: userState,
		prevNotes: prevNotes,
		toggleVisibility: toggleVisibility,
		maskPass: maskPass,
		visible: visible,
	};

	const bulkItemProps = {
		item: item,
		form: form,
		bins: bins,
		bulkScan: bulkScan,
		editing: editing,
		subPath: subPath,
		handleAutoTab: handleAutoTab,
		onScanBulk: onScanBulk,
		removeBulkItem: removeBulkItem,
		onAddItem: onAddItem,
	};

	const newItemModalProps = {
		onModalClose: onModalClose,
		onCreateItem: onCreateItem,
		uploading: uploading,
		errors: errors,
		vendors: vendors,
	};

	const auditItemModalProps = {
		auditData: auditData,
		onToggleAudit: onToggleAudit,
		auditType: 'Hardware',
	};

	const attachItemModalProps = {
		onToggleItem: onToggleItem,
		validateModem: validateModem,
	};

	const detachItemModalProps = {
		onToggleDetach: onToggleDetach,
		onDetachItem: onDetachItem,
	};

	useEffect(() => {
		// for items with barcodes - singular item
		let mounted = true;

		if (mounted) {
			retrieveData(true);
			if (item.code == 'MODM') {
				setIsModem(true);
			} else setIsModem(false);
		}

		return () => {
			mounted = false;
		};
	}, [item.barcode]);

	useEffect(() => {
		//for opening the new item modal
		$('#new-item').toggleClass('flex-box');
	}, [modalOpen]);

	useEffect(() => {
		//auditing a singular item
		$('#item-audit').toggleClass('flex-box');
	}, [auditOpen]);

	useEffect(() => {
		//attaching a child singular item to a parent item
		$('#attach-item').toggleClass('flex-box');
	}, [itemOpen]);

	useEffect(() => {
		//detaching a child singular item to a parent item
		$('#detach-item').toggleClass('flex-box');
	}, [detachOpen]);

	function deployCount() {
		const deploy_arr = [];
		const id = 'WI';
		const reduce_locations = audit.filter((el) => el.location.substring(0, 2) === id);
		reduce_locations.forEach((kiosk_deploy) =>
			!deploy_arr.includes(kiosk_deploy.location) ? deploy_arr.push(kiosk_deploy.location) : null
		);
		const count = deploy_arr.length ? deploy_arr.length : 0;
		return count;
	}

	function handleServiceSelect(event) {
		// Destructure due to setState being an async function to avoid error
		const { value, checked } = event.target;
		const refurbValues = ['Refurbished', 'New', 'Recycled'];
		value === 'Refurbished' || value === 'New' || value === 'Recycled' ? setNewRefurb(value) : null;
		!serviceSelect.includes(value) && !refurbValues.includes(value)
			? setServiceSelect((prevState) => [...prevState, value])
			: serviceSelect.includes(value) && !checked
			? setServiceSelect(serviceSelect.filter((item) => item !== value))
			: null;
	}

	function handleNewRefurbSelect(event) {
		setNewRefurb(event.target.value);
	}

	function handleCarrierSelect(event) {
		setCarrierOption(event.target.value);
	}
	const retrieveData = (load) => {
		if (load) {
			setLoading(true);
		}

		setItemType('');
		setEditing(false);

		socket.emit('getAllBinLocations', (res) => {
			if (!res) {
				console.error(`InventoryItem.getAllBinLocations: There was an issue calling this method`);
			} else {
				setBins(res);
			}
		});

		if (subPath !== 'bulk-item') {
			socket.emit('getInventory', item.barcode, (audit) => {
				if (!audit) {
					console.error(`InventoryPart.getInventory: There was an issue calling this method`);
					setLoading(false);
				} else {
					if (audit.length) {
						setNewItem(false);
						const notesArr = [];
						const data = audit[0];
						data.item_info = JSON.parse(data.item_info ? data.item_info : {});
						audit.forEach((el) => notesArr.push(JSON.parse(el.notes)));
						const notesCopy = notesArr.filter((el) => el);
						setPrevNotes(notesCopy);
						setForm(data);
						setIsModem(item.code === 'MODM');
						if (item.code === 'TSCP' || (item.has_carrier && item.requires_serial)) {
							setItemType('Parent');
						} else if (item.has_carrier && !item.requires_serial) {
							setItemType('SIM');
						} else {
							setItemType('Parent');
						}
						setAudit(audit);
						setLoading(false);
					} else {
						setIsModem(item.code === 'MODM');
						if (item.has_carrier && item.requires_serial) {
							setItemType('Parent');
						} else if (item.has_carrier && !item.requires_serial) {
							setItemType('SIM');
						} else if (item.code !== 'PPAR') {
							setItemType('Parent');
						} else {
							setItemType('Child');
						}
						setNewItem(true);
						setLoading(false);
						resetForm();
						document.getElementById('serial').focus();
					}
				}
			});
		} else {
			socket.emit('getBulkItems', (res) => {
				if (!res) {
					console.error(`InventoryPart.getBulkItems: There was an issue calling this method`);
					setLoading(false);
				} else {
					setBulk(res);
					setNewItem(true);
					setLoading(false);
					resetForm();
				}
			});
		}
	};

	function handleAutoTab(event) {
		// tab through the fields for a singular item. Does not seem to work with the enter key
		if (event.key === 'Enter') {
			setTabIdx((prevState) => prevState + 1);
		}
	}

	function onDetachItem() {
		//detach a singular child item from a parent item
		const itemRID = form.item_info.attached_items.id;
		if (itemRID) {
			socket.emit('getInventory', itemRID, async (res) => {
				if (res && res.length) {
					// modem exists
					const parentItemInfo = JSON.parse(res[0].item_info);
					// check that modem has this item attached to it
					if (parentItemInfo.attached_items && parentItemInfo.attached_items.id == item.barcode) {
						// detach item from modem
						delete form.item_info.attached_items;
						delete parentItemInfo.attached_items;

						const newAddedItemData = {
							...form,
							action: 'UPDATED',
							user: localStorage.getItem('FullName'),
							info: {
								...form.item_info,
							},
						};

						const newParentItemData = {
							...res[0],
							action: 'UPDATED',
							user: localStorage.getItem('FullName'),
							info: {
								...parentItemInfo,
							},
						};

						const _data = [newParentItemData, newAddedItemData];

						await updateIntoInventory(_data, 'update');

						// update both parent item and child part
						setSnackbar((prevState) => ({
							...prevState,
							visible: true,
							message: `Success! Attached item has been detached from this part.`,
							severity: 'success',
						}));

						$('#detach-item').toggleClass('flex-box');
					} else {
						// good to attach
						setSnackbar((prevState) => ({
							...prevState,
							visible: true,
							message: `WARNING: The current part attached to this item does not match the part number selected. Please check the item you're trying to detach and try again.`,
							severity: 'warning',
						}));
					}
				} else {
					// parent item doesn't exist
					setSnackbar((prevState) => ({
						...prevState,
						visible: true,
						message: `ERROR: We could not find a parent item with that RID#.  Please try again.`,
						severity: 'error',
					}));
				}
			});
		} else {
			setSnackbar((prevState) => ({
				...prevState,
				visible: true,
				message: `ERROR: There was an unexpected error on our end. Please notify the developer.`,
				severity: 'error',
			}));
		}
	}

	function onNavigateToItem(_event, _barcode) {
		$.post(`/api/scan-item`, { data: _barcode }, (path) => {
			if (!path.error) {
				if (!path.data) {
					navigate(path.path);
				} else {
					navigate(path.path, { state: path.data });
				}
			} else {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message:
						'SERVER ERROR: There was an issue navigating to this item. Please contact the developer for this issue.',
					severity: 'error',
				}));
			}
		});
	}

	function onScanBulk(event) {
		// Scan Bulk Items into inventory - e.g. sticker bundles
		if (event.keyCode === 13) {
			const barcode = document.getElementById('barcode').value;
			const valid = validateBulkScan(barcode);

			if (valid.valid) {
				document.getElementById('barcode').value = '';
				if (bulkScan.length) {
					const _copy = [...bulkScan];
					_copy.unshift({ id: 0, barcode });
					const _final_copy = _copy.map((el, idx) => ({ id: idx, barcode: el.barcode }));
					setBulkScan(_final_copy);
				} else {
					setBulkScan([{ id: 0, barcode }]);
				}

				document.getElementById('barcode').focus();
			} else {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: `WARNING: ${valid.msg}`,
					severity: 'warning',
				}));
			}
		}
	}

	function removeBulkItem(event) {
		// bulk item
		const id = event.target.id;
		const _copy = [...bulkScan];
		_copy.splice(id, 1);
		const _final_copy = _copy.map((el, idx) => ({ id: idx, barcode: el.barcode }));
		setBulkScan(_final_copy);
	}

	function validateBulkScan(_barcode) {
		// Make sure that the bulk item was not already scanned or empty
		document.getElementById('barcode').value = '';
		return bulkScan.find((el) => el.barcode == _barcode)
			? {
					valid: false,
					msg: `Oops! Looks like you have already scanned this item in. Please double check that you are scanning the correct barcode.`,
			  }
			: { valid: true };
	}

	async function onAddItem(controllerData) {
		// Handles adding singular and bulk items into inventory
		const valid = await validate(controllerData);
		setTabIdx(0);
		if (valid.valid && !valid.confirm) {
			// item is ok to enter into inventory
			const vals = [...valid.values];
			await updateIntoInventory(vals, 'add');
		} else if (!valid.valid && valid.confirm) {
			// error with option
			const res = confirm(valid.msg);
			if (res) {
				retrieveVendorInfo();
			}
		} else {
			// error has occurred
			setSnackbar((prevState) => ({
				...prevState,
				visible: true,
				message: `WARNING: ${valid.msg}`,
				severity: 'warning',
			}));
		}
	}

	const updateIntoInventory = (vals, action) => {
		// all items
		return new Promise(async (resolve, reject) => {
			try {
				socket.emit('updateInventory', vals, vals[0].info, action, (res) => {
					if (!res) {
						console.error(`InventoryPart.updateIntoInventory: There was an issue calling this method`);
						reject(false);
					} else {
						setSnackbar((prevState) => ({
							...prevState,
							visible: true,
							message: `Success! Item ${
								action == 'add' ? 'added into inventory' : action == 'update' ? 'updated' : ''
							}.`,
							severity: 'success',
						}));
						const data = res[0][0];
						setForm(data);
						setEditing(false);
						retrieveData(true);
						resolve(true);
						subPath === 'bulk-item' ? window.location.reload(true) : null;
					}
				});
			} catch (error) {
				console.error(`InventoryPart.updateIntoInventory: ${error}`);
				reject(error);
			}
		});
	};

	const validateNewItem = () => {
		// Validate the fields for a new item being added. Make sure that they all have the required values before attempting to add it to the database
		const item_name = document.getElementById('new-item-name').value;
		const model = document.getElementById('new-item-model').value;
		const units = document.getElementById('new-item-units').value;
		const price = document.getElementById('new-item-price').value;
		const vendor = vendors.find(
			(vendor) => vendor.name.toUpperCase() == document.getElementById('new-item-vendor').value.toUpperCase()
		);
		const desc = document.getElementById('new-item-description').value;

		const inputs = [
			{
				item_name,
				item_code: item.id,
				model,
				units,
				price: parseFloat(price).toFixed(2),
				vendor: vendor ? vendor.id : null,
				desc,
				requires_serial: parseInt(item.requires_serial) ? 1 : 0,
				has_carrier: parseInt(item.has_carrier) ? 1 : 0,
			},
		];

		if (!item_name) {
			setErrors({
				itemName: true,
			});
			return { valid: false, msg: `You must provide an item name.` };
		} else if (!model) {
			setErrors({
				newModel: true,
			});
			return { valid: false, msg: `You must provide a model # for this item.` };
		} else if (!units || units <= 0) {
			setErrors({
				units: true,
			});
			return { valid: false, msg: `Units per Item cannot be zero or a negative value.` };
		} else if (price < 0) {
			setErrors({
				price: true,
			});
			return { valid: false, msg: `Price per Item cannot be a negative value.` };
		}

		setErrors({});
		return { valid: true, values: inputs };
	};

	const resetForm = () => {
		// singular item, bulk item
		// Resets all the input fields from an item when transferring to an existing item that does not have any values associated with that barcode
		setForm({
			serial: '',
			imei: '',
			qty: '',
			location: '',
			model: '',
			barcode: '',
			item_info: {
				serial: '',
				imei: '',
				phone: '',
				sim: '',
				password: '',
				firmware: '',
				carrier: '',
				part_num: '',
				attached_items: { id: '' },
			},
			action: '',
			user: '',
			refurb: '',
			item_id: '',
			notes: '',
		});
		setNewRefurb('');
		const field_array = [
			'serial',
			'imei',
			'location',
			'model',
			'firmware',
			'pass',
			'sim',
			'phone',
			'item',
			'carrier',
			'part-num',
		];

		field_array.map((el) => (document.getElementById(el) ? (document.getElementById(el).value = '') : null));
		document.getElementById('refurb') ? (document.getElementById('refurb').checked = false) : null;
		document.querySelector('#attached-items') ? (document.querySelector('#attached-items').dataset.value = '') : null;
	};

	const validate = async (controllerData) => {
		// Validate the information in the input fields for new singular items. Make sure all fields match the info type to be passed into inventory
		return new Promise(async (resolve, reject) => {
			setErrors({});
			const qty = item.units >= 500 ? item.units : 1;
			const serial = controllerData.serial ? controllerData.serial : '';
			const imei = controllerData.imei ? controllerData.imei : '';
			const sim = controllerData.sim ? controllerData.sim : '';
			const phone = controllerData.phone ? controllerData.phone : '';
			const location = controllerData.location ? controllerData.location : '';
			const pass = controllerData.pass ? controllerData.pass : '';
			const firmware = controllerData.firmware ? controllerData.firmware : '';
			const refurb = newRefurb === 'Refurbished' ? 1 : 0 || (form && form.refurbished) ? 1 : 0;
			const carrier = controllerData.carrier ? controllerData.carrier : '';
			const part_num = controllerData.part_num ? controllerData.part_num : '';
			const model = controllerData.model ? controllerData.model : '';
			const cur_attached_items = controllerData.cur_attached_items ? controllerData.cur_attached_items : '';
			const found = await getItemByModel(model);
			const bulk_item_found =
				item.bulk && !item.item_id
					? bulk.find((el) => el.item_name.toUpperCase() == controllerData.selected_item.toUpperCase()).id
					: null;
			const item_id = found.found
				? found.item_id
				: item && item.item_id
				? item.item_id
				: item.bulk && !item.item_id
				? bulk_item_found
				: itemType === 'SIM' && carrier
				? await getItemIDBySIMCarrier(carrier)
				: null;
			let inputs = [];
			const _bin = bins.find(
				(bin) => bin.name.toUpperCase() == location.toUpperCase() || bin.code.toUpperCase() == location.toUpperCase()
			);
			let binFound = await validateBinLocation(location);

			if (binFound) {
				// bin exists
				const _allowed = _bin.allowed ? JSON.parse(_bin.allowed) : null;
				// check if item is allowed in bin
				if (_bin && _allowed && !_allowed.includes(`${item.id}`)) {
					// bin has sctrict parameters
					binFound = false;
				}
			}

			const common_fields = {
				serial,
				imei,
				qty,
				location: _bin.code,
				model,
				info: {
					serial,
					imei,
					phone,
					sim,
					password: pass,
					firmware,
					carrier,
					part_num,
					attached_items: { id: cur_attached_items },
				},
				action: (binFound && _bin.offsite) || location.toUpperCase().startsWith('WI') ? 'OUT' : 'IN', // if location is a kiosk, item is being taken out of inventory
				user: localStorage.getItem('FullName').split(' ')[0],
				action: binFound.offsite || location.toUpperCase().startsWith('WI') ? 'OUT' : 'IN', // if location is a kiosk, item is being taken out of inventory
				user: localStorage.getItem('FullName'),
				refurb: location.toUpperCase().startsWith('WI') ? 1 : refurb,
				item_id: item_id,
			};

			if (bulkScan.length) {
				bulkScan.forEach((bulkItem) => {
					inputs.push({
						...common_fields,
						barcode: bulkItem.barcode,
					});
				});
			} else {
				inputs.push({
					...common_fields,
					barcode: item.barcode,
				});
			}
			await errorHandler(serial, binFound, imei, qty, location, model, part_num, carrier, inputs);

			resolve({ valid: true, values: inputs });
		});
	};

	async function errorHandler(serial, binFound, imei, qty, location, model, part_num, carrier, inputs) {
		// Handle errors and provide an error message describing what went wrong. Could move this to a function to pass around to components. Keep in parent InventoryItem.js
		// If no errors return the data to be updated into inventory
		return new Promise(async (resolve, reject) => {
			const regex = /https:|http:/;
			let item_id;
			if (!item.bulk && itemType !== 'Child' && !serial) {
				setErrors({
					serial: true,
				});
				resolve({ valid: false, msg: `You must provide a serial # for this item.` });
			} else if (!binFound) {
				setErrors({
					location: true,
				});
				resolve({
					valid: false,
					msg: `Our system cannot validate the destination that you have selected. The destination may not exist or is not allowed to be transferred to this location.`,
				});
			} else if (itemType === 'Parent' && isModem && !imei) {
				setErrors({
					imei: true,
				});
				resolve({ valid: false, msg: `You must provide an IMEI for this item.` });
			} else if (!qty) {
				setErrors({
					qty: true,
				});
				resolve({ valid: false, msg: `Please make sure that a quantity is selected.` });
			} else if (!location) {
				setErrors({
					location: true,
				});
				resolve({ valid: false, msg: `Please choose a destination for this item.` });
			} else if (!item.bulk && itemType !== 'Child' && !model) {
				setErrors({
					model: true,
				});
				resolve({ valid: false, msg: `Please enter the model # for this item.` });
			} else if (itemType === 'Parent' && isModem && imei && serial && imei.toUpperCase() == serial.toUpperCase()) {
				setErrors({
					serial: true,
				});
				resolve({
					valid: false,
					msg: `Invalid S/N or IMEI. Make sure that both S/N and IMEI do not match. Please correct this issue and try again.`,
				});
			} else if (!item.bulk && serial.match(regex)) {
				setErrors({
					serial: true,
				});
				resolve({
					valid: false,
					msg: `Invalid S/N. Please check that this items serial number entered is correct, and try again.`,
				});
			} else if (itemType === 'Child' && !item.requires_serial && !carrier) {
				setErrors({
					serial: true,
				});
				resolve({ valid: false, msg: `Carrier cannot be blank.` });
			} else if (!item.bulk && itemType !== 'Child' && !newRefurb && !newItem) {
				resolve({ valid: false, msg: `Must select an option between New Item and Refurbished.` });
			} else if (!item.bulk && itemType !== 'Child' && !newRefurb) {
				resolve({ valid: false, msg: `Please select between New Item or Refurbished.` });
			} else if (!item.bulk && itemType !== 'Child' && !part_num) {
				setErrors({
					partNum: true,
				});
				resolve({ valid: false, msg: `Part number cannot be blank.` });
			}
			resolve({ valid: true, values: inputs });
		});
	}

	const getItemByModel = (model) => {
		// get item by model. Applies to bulk items and singular items
		return new Promise((resolve, reject) => {
			socket.emit('getItemByModel', model, (res) => {
				if (!res) {
					console.error(`InventoryPart.getItemByModel: There was an issue calling this method`);
					reject(false);
				} else {
					if (!res.length) {
						setErrors({
							model: true,
						});
						resolve({ found: false });
					} else {
						resolve({ found: true, item_id: res[0].id });
					}
				}
			});
		});
	};
	const getItemIDBySIMCarrier = (carrier) => {
		// get item by model. Applies to bulk items and singular items
		return new Promise((resolve, reject) => {
			socket.emit('getItemIDBySIMCarrier', carrier, (res) => {
				if (!res) {
					console.error(`InventoryPart.getItemIDBySIMCarrier: There was an issue calling this method`);
					reject(false);
				} else {
					if (!res.length) {
						reject();
					} else {
						resolve(res[0][0].id);
					}
				}
			});
		});
	};

	const retrieveVendorInfo = () => {
		// get info for an item that is being entered in for the first time. Possibly applies to singular items
		socket.emit('getInventoryVendors', (res) => {
			if (!res) {
				console.error(`InventoryPart.getInventoryVendors: There was an issue calling this method`);
			} else {
				setVendors(res);
				setModelNum(document.getElementById('model').value);
				setModalOpen(true);
			}
		});
	};

	const onModalClose = () => {
		setModelNum(null);
		setModalOpen(false);
	};

	const onCreateItem = () => {
		// Create an new item for the first time that does not exist in the inventory table already
		const valid = validateNewItem();

		if (valid.valid) {
			setUploading(true);

			socket.emit('insertInventoryItems', valid.values, ([res]) => {
				const [id_val] = res;
				if (!id_val.id) {
					setUploading(false);
					console.error(`InventoryPart.insertInventoryItems: There was an issue calling this method`);
				} else {
					setUploading(false);
					onModalClose();
					setItemCreated(id_val.id);
					setSnackbar((prevState) => ({
						...prevState,
						visible: true,
						message: `Success! Item was created.`,
						severity: 'success',
					}));
				}
			});
		} else {
			setSnackbar((prevState) => ({
				...prevState,
				visible: true,
				message: `WARNING: ${valid.msg}`,
				severity: 'warning',
			}));
		}
	};

	function maskPass(pass) {
		// Hide the password for modems / singular items
		pass == undefined ? (pass = '') : pass;
		return !visible ? pass.replace(/[a-zA-Z0-9:.,?!@#%&$^(){}"~`'=+/<>]/g, '*') : pass;
	}

	function toggleVisibility() {
		// Show the password for modems / singular items
		setVisible((prevState) => !prevState);
	}

	function auditData() {
		// Pull history for singular items and where they have been in the past and what actions happened to them
		return audit.map((el) => ({
			key: el.id,
			cells: [
				{ data: DateToLocale(new Date(el.created)) },
				{ data: el.created_by },
				{ data: el.qty },
				{ data: el.location },
				{ data: el.action },
			],
		}));
	}

	function onToggleAudit() {
		setAuditOpen((prevState) => !prevState);
	}

	const handleCloseSnack = () => {
		setSnackbar((prevState) => ({
			...prevState,
			visible: false,
		}));
	};

	function toggleEditing() {
		setEditing((prevState) => !prevState);
	}

	function toggleServicing() {
		setServicing((prevState) => !prevState);
	}

	async function handleUpdateItem(controllerData) {
		// Update the information for a singular item. If the singular item is being received from a kiosk, send an email to accounting
		setTabIdx(0);
		const serial = controllerData.serial ? controllerData.serial : form.serial;
		const imei = controllerData.imei;
		const sim = controllerData.sim;
		const phone = controllerData.phone;
		const location = controllerData.location;
		const pass = controllerData.pass;
		const firmware = controllerData.firmware;
		const refurb = newRefurb === 'Refurbished' ? 1 : 0 || (form && form.refurbished) ? 1 : 0;
		const carrier = controllerData.carrier;
		const part_num = controllerData.part_num ? controllerData.part_num : item.part_num;
		const model = controllerData.model;
		const item_notes = controllerData.item_notes;
		const cur_attached_items = controllerData.cur_attached_items;
		const new_action = controllerData.new_action;
		const servicesSelected = controllerData.servicesSelected;
		const deploy_count = controllerData.deployCount;
		const timestamp = controllerData.timestamp;
		let send_info = false;
		if (form.action == 'OUT' && new_action == 'UPDATED' && itemType === 'Parent') {
			// modem coming back from the field, send email to accounting
			send_info = true;
		}

		const _bin = bins.find(
			(bin) => bin.name.toUpperCase() == location.toUpperCase() || bin.code.toUpperCase() == location.toUpperCase()
		);
		let binFound = true;

		if (binFound && _bin) {
			// bin exists
			const _allowed = _bin.allowed ? JSON.parse(_bin.allowed) : null;
			// check if item is allowed in bin
			if (_bin && _allowed && !_allowed.includes(`${item.id}`)) {
				// bin has strict parameters
				binFound = false;
			}
		}

		const inputs = [
			{
				serial,
				imei,
				item_id: form.item_id,
				qty: item.units,
				location: _bin
					? _bin.code
					: location.toUpperCase().includes('WI') && location.length == 6
					? location.toUpperCase()
					: location,
				model: model,
				barcode: item.barcode,
				info: {
					serial,
					imei,
					phone,
					sim,
					password: pass,
					firmware,
					carrier,
					part_num,
					attached_items: { id: cur_attached_items },
				},
				action: new_action,
				user: localStorage.getItem('FullName'),
				refurb: location.toUpperCase().startsWith('WI') ? 1 : refurb,
				notes: item_notes ? { servicesSelected, item_notes, deploy_count, refurb, timestamp } : null,
			},
		];
		await updateIntoInventory(inputs, 'update');
		if (itemType === 'Parent' && cur_attached_items !== '') {
			try {
				updateChildItemLocation(cur_attached_items, location);
			} catch (error) {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: `WARNING: Could not update the location of the attached item. Please try again`,
					severity: 'warning',
				}));
			}
		}

		if (send_info) {
			const msg = `
            <div>
                <h1 style="text-align:center;">Modem Returned from Field</h1>
                <p>A modem has been returned from the field.  Please review the information listed below, and take the necessary steps to pause or remove this modems service.</p>
                <label>Location: ${form.location}</label><br>
                <label>S/N: ${serial}</label><br>
                <label>IMEI: ${form.item_info.imei}</label><br>
                <label>Phone #: ${form.item_info.phone}</label><br>
                <label>SIM: ${form.item_info.sim}</label><br>
                <label>Carrier: ${form.item_info.carrier}</label><br>
                <label>RID: ${form.barcode}</label><br>
                ${getEmailSignature('Auto Message')}
            </div>
            `;
			const opts = {
				to: 'accounting@registration-technology.com',
				bcc: 'self',
				subject: `Modem Returned from Field`,
				msg: msg,
			};

			$.post('/api/send_email_async', { email: opts }, (res) => {
				if (!res.status || res.status == 'failed') {
					console.error(`InventoryItem.api.send_email_async: ${res.msg}`);
				}
			});
		}
	}

	function onToggleItem() {
		setItemOpen((prevState) => !prevState);
	}

	function onToggleDetach() {
		setDetachOpen((prevState) => !prevState);
	}

	// Update the child item with the location of the parent item
	const updateChildItemLocation = (addedItem, parentItemLocation) => {
		// Update the attached singular item. Child item is attached to a parent item
		socket.emit('getInventory', addedItem, async (res) => {
			if (res && res.length) {
				const childItemInfo = JSON.parse(res[0].item_info);
				// Check to make sure that the modem is in fact attached to the childItem
				if (itemType === 'Parent') {
					const newAddedItemData = {
						...res[0],
						action: 'UPDATED',
						location: parentItemLocation,
						user: localStorage.getItem('FullName'),
						info: {
							...childItemInfo,
							attached_items: {
								// Keep the same attached item that was already attached to the SIM
								id: childItemInfo.attached_items.id,
							},
						},
					};
					// Has to be passed as an array so that the updateIntoInventory can find it at [0]
					const _data = [newAddedItemData];
					// update both Parent Item and Child Item card
					await updateIntoInventory(_data, 'update');
				}
			}
		});
	};

	function validateModem() {
		//Validate that the modem and the SIMC are showing the same information
		// singular item
		const itemRID = document.getElementById('modem-rid').value;

		if (itemRID) {
			socket.emit('getInventory', itemRID, async (res) => {
				if (res && res.length) {
					// modem exists
					const parentItemInfo = JSON.parse(res[0].item_info);
					// check that modem doesn't already have a sim attached to it
					if (parentItemInfo.attached_items && parentItemInfo.attached_items.id.startsWith('SIMC')) {
						setSnackbar((prevState) => ({
							...prevState,
							visible: true,
							message: `WARNING: A SIM card is already attached to this modem. Please detach the current SIM card from this modem and try again.`,
							severity: 'warning',
						}));
					} else if (parentItemInfo.attached_items && parentItemInfo.attached_items.id.startsWith('PPAR')) {
						setSnackbar((prevState) => ({
							...prevState,
							visible: true,
							message: `WARNING: A part is already attached to this printer. Please detach the current part from this printer and try again.`,
							severity: 'warning',
						}));
					} else {
						// good to attach
						// attach sim to modem
						const newAddedItemData = {
							...form,
							action: 'UPDATED',
							location: res[0].location,
							user: localStorage.getItem('FullName'),
							info: {
								...form.item_info,
								attached_items: {
									// attach Parent Part to Child Part card
									id: res[0].barcode,
								},
							},
						};

						const newParentItemData = {
							...res[0],
							action: 'UPDATED',
							user: localStorage.getItem('FullName'),
							info: {
								...parentItemInfo,
								attached_items: {
									// attach Parent Part to Child Part card
									id: item.barcode,
								},
							},
						};

						const _data = [newParentItemData, newAddedItemData];
						// update both modem and childItem card
						await updateIntoInventory(_data, 'update');

						setSnackbar((prevState) => ({
							...prevState,
							visible: true,
							message: `Success! Item has been attached to this modem.`,
							severity: 'success',
						}));
						onToggleItem();
					}
				} else {
					// modem doesn't exist
					setSnackbar((prevState) => ({
						...prevState,
						visible: true,
						message: `ERROR: We could not find a modem with that RID#.  Please try again.`,
						severity: 'error',
					}));
				}
			});
		} else {
			setSnackbar((prevState) => ({
				...prevState,
				visible: true,
				message: `WARNING: Please scan a modem RID#`,
				severity: 'warning',
			}));
		}
	}

	// for entering in a new item / viewing the audit history modal / attaching/detaching an item to a parent modals
	return (
		<div>
			{modalOpen ? NewItemModal(newItemModalProps) : null}

			{auditOpen ? ItemAuditModal(auditItemModalProps) : null}

			{itemOpen ? AttachItemModal(attachItemModalProps) : null}

			{detachOpen ? DetachItemModal(detachItemModalProps) : null}

			<AlertBar
				visible={snackbar.visible}
				onClose={handleCloseSnack}
				message={snackbar.message}
				severity={snackbar.severity}
			/>
			{/*New Items - Both Bulk and Singular*/}
			{!loading && subPath === 'bulk-item' && item.barcode ? (
				<div className="flex-align-center item-field-container">{BulkInventoryController(bulkItemProps)}</div>
			) : (!loading && !newItem) || (!loading && newItem) ? (
				<div className="flex-align-center item-field-container">
					<h1>{form && form.item_name ? form.item_name : item.item}</h1>
					<p>
						{form && form.action == 'UPDATED' ? 'Updated' : 'Created'}{' '}
						{form && form.created ? DateToLocale(new Date(form.created)) : ''} -{' '}
						{form && form.created_by ? form.created_by : 'N/A'}
					</p>
					<div className="divider" id="divider"></div>
					{editing ? <label>Editing</label> : null}
					{!editing
						? SingularInventoryPartController(singularItemProps)
						: SingularInventoryPartController(singularItemProps)}
					<div className="divider" id="divider"></div>
				</div>
			) : (
				<Spinner margin="center" />
			)}
		</div>
	);
};

export default InventoryItem;
