import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AlertBar from '../../components/AlertBar';
import Button from '@mui/material/Button';
import Logo from '../../../public/Images/regitechLogo.png';
import { MdAccountCircle, MdCropFree, MdNotificationsNone, MdNotificationsActive } from 'react-icons/md';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import { useCustomContext } from '../../hoc/Context.js';
import '../../StyleSheets/PaperChange.css';
import { DateToLocaleShort } from '../../utilities/util';
import { styled } from '@mui/material/styles'; // Use styled from Material-UI

// const useStyles = {
//   nm: {
//     fontSize: '20px',
//   },
//   lg: {
//     fontSize: '13px',
//   },
// };

const USER_OPTS = [
	{ id: 'dispatch', name: 'Dispatch Console' },
	{ id: 'inventory', name: 'Inventory' },
	{ id: 'purchase_orders', name: 'Purchase Orders' },
	{ id: 'paper', name: 'Paper' },
	{ id: 'sticker-audit', name: 'Sticker Audit' },
	{ id: 'send_items', name: 'Items to Send' },
	{ id: 'received_items', name: 'Items Received' },
	{ id: 'barcodes', name: 'Barcode Generator' },
	{ id: 'sticker_counter', name: 'Sticker Counter' },
	{ id: 'logout', name: 'Logout' },
];

const TEST_URL = 'http://localhost:3000';
const PROD_URL = 'https://console.ez-tab.net';

const Inventory = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [core, path, subPath] = location.pathname.split('/');
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [notifications, setNotifications] = useState([]);
//   const classes = useStyles;

	const [snackbar, setSnackbar] = useState({
		visible: false,
		message: '',
		severity: 'success',
	});
	const CORE_PREFIX = 'PC';

	const { userState } = useCustomContext();

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			retrieveData();
			document.body.addEventListener(
				'click',
				_.debounce(() => {
					endSession(); // logout if user is inactive for more than 60 mins
				}, 60 * 60 * 1000)
			); // check activity every 60 mins
		}

		return () => {
			mounted = false;
			document.body.removeEventListener('click', () => {});
		};
	}, []);

	/**
	 * Retrieves inventory notifications from the server and updates the state with the received data.
	 */
	const retrieveData = () => {
		socket.emit('getInvNotifications', userState.user.uid, (res) => {
			if (!res) {
				console.error(`Inventory.getInvNotifications: There was an issue calling this method`);
			} else {
				const notifs = JSON.parse(res[0].notfs);
				setNotifications(notifs);
			}
		});
	};

	/**
	 * Closes the snack bar.
	 */
	const handleCloseSnack = () => {
		setSnackbar((prevState) => ({
			...prevState,
			visible: false,
		}));
	};

	/**
	 * Closes the options by resetting the anchor elements.
	 */
	const handleCloseOptions = () => {
		setAnchorEl(null);
		setAnchorEl2(null);
	};

	/**
	 * Handles the opening of options.
	 *
	 * @param {Event} event - The event object.
	 */
	const handleOpenOptions = (event) => {
		setAnchorEl(event.currentTarget);
	};

	/**
	 * Handles the opening of notifications.
	 *
	 * @param {Event} event - The event object.
	 */
	const handleOpenNotifications = (event) => {
		setAnchorEl2(event.currentTarget);
	};

	/**
	 * Handles the selection of an option.
	 *
	 * @param {Event} event - The event object.
	 */
	const handleOptionSelect = (event) => {
		const option = event.target.id;

		switch (option) {
			case 'logout':
				endSession();
				break;
			case 'purchase_orders':
				navigate(`/inventory/purchase-order/`);
				setAnchorEl(null);
				break;
			case 'received_items':
				navigate(`/inventory/received-items/`);
				setAnchorEl(null);
				break;
			case 'send_items':
				navigate(`/inventory/send-items/`);
				setAnchorEl(null);
				break;
			case 'dispatch':
				navigate(`/`);
				setAnchorEl(null);
				break;
			case 'inventory':
				navigate(`/inventory/items`);
				setAnchorEl(null);
				break;
			case 'barcodes':
				navigate(`/inventory/barcodes/`);
				setAnchorEl(null);
				break;
			case 'paper':
				navigate(`/inventory/paper`);
				setAnchorEl(null);
				break;
			case 'sticker-audit':
				navigate(`/inventory/sticker-audit`);
				setAnchorEl(null);
				break;
			case 'sticker_counter':
				navigate(`/inventory/sticker-counter`);
				setAnchorEl(null);
				break;
		}
	};

	/**
	 * Ends the current session and performs necessary cleanup tasks.
	 */
	const endSession = () => {
		window.location.replace(`${userState.user.environment === 'production' ? PROD_URL : TEST_URL}/login`);
		window.sessionStorage.removeItem('invAcc');
		window.localStorage.removeItem('FullName');
		window.localStorage.removeItem('user');
		window.localStorage.removeItem('local-dispatch-console-user');
	};

	/**
	 * Handles the scan event.
	 *
	 * @param {string} data - The scanned data.
	 * @returns {void}
	 */
	function onScan(data) {
		const upperData = data.toUpperCase();
		$.post(`/api/scan-item`, { data: upperData }, (path) => {
			if (!path.error) {
				if (!path.data) {
					navigate(path.path, { replace: true });
					navigate(0);
				} else {
					navigate(path.path, { state: path.data });
					navigate(0);
				}
			} else {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: 'SERVER ERROR: There was an issue calling this method. Please contact the developer for this issue.',
					severity: 'error',
				}));
			}
		});
	}

	/**
	 * Handles the event when a serial is looked up.
	 * @param {Event} event - The event object.
	 */
	function onSerialLookUp(event) {
		if (event.keyCode === 13) {
			const serial = event.target.value.toUpperCase();
			socket.emit('getItemBySerial', serial, (res) => {
				if (!res) {
					setSnackbar((prevState) => ({
						...prevState,
						visible: true,
						message:
							'SERVER ERROR: There was an issue calling this method. Please contact the developer for this issue.',
						severity: 'error',
					}));
					console.error(`Inventory.getItemBySerial: There was an issue calling this method`);
				} else {
					if (res.length) {
						if (serial.toUpperCase().substring(0, 2) === CORE_PREFIX) {
							const paper_path =
								res && res[0] && res[0].barcode
									? `/inventory/paper/${res[0].barcode}`
									: `/inventory/receive/${res[0].barcode}`;
							navigate(paper_path, { state: { inv_item: res[0] } });
							navigate(0);
						} else {
							navigate(`/inventory/receive/${res[0].barcode}`, { state: { inv_item: res[0] } });
						}
					} else {
						onScan(serial);
					}
				}
			});
		}
	}

  /**
   * Handles the click event on a notification.
   *
   * @param {Event} event - The click event.
   * @returns {void}
   */
  const onClickNotification = (event) => {
    const [idx, type] = event.target.id.split('-');

		updateNotifications(idx);
		handleCloseOptions();

		switch (type) {
			case 'purchase_order':
				navigate(`/inventory/purchase-order/`);
				break;
		}
	};

	/**
	 * Updates the inventory notifications.
	 *
	 * @param {number} idx - The index of the notification.
	 * @returns {void}
	 */
	const updateNotifications = (idx) => {
		socket.emit('updateInvNotification', userState.user.uid, idx, (res) => {
			if (!res) {
				console.error(`Inventory.updateInvNotification: There was an issue calling this method`);
			} else {
				retrieveData();
			}
		});
	};

	return (
		<div className='tech-app-container' style={{ height: '100%', overflowX: 'hidden' }}>
			<div className='mainContainer-pcore'>
				<div className='pinv-navbar'>
					<div>
						<a href={'/inventory'}>
							<img src={`${Logo}`} width='300px' style={{ padding: '10px 0px 0px 10px' }} alt='Logo' />
						</a>
					</div>
					<div style={{ display: 'flex', alignItems: 'flex-end' }} className='pinv-navbar-user'>
						{subPath ? (
							<div style={{ marginRight: 40 }} className='innerContainer-pcore' tabIndex={0}>
								<FormControl variant='standard'>
									<Input
										style={{ width: 232, fontSize: 16 }}
										id='scan-barcode-small'
										placeholder='Scan RID, Serial # or SIM #...'
										startAdornment={
											<InputAdornment position='start'>
												<MdCropFree />
											</InputAdornment>
										}
										onKeyDown={onSerialLookUp}
									/>
								</FormControl>
							</div>
						) : null}
						<div style={{ width: 312 }} className='flex-align-center flex-just-between'>
							<div>
								<IconButton id='notification-button' onClick={handleOpenNotifications} size='large'>
									<Badge
										id='notification-button'
										overlap='rectangular'
										badgeContent={notifications.length}
										color='secondary'
									>
										<MdNotificationsNone id='notification-button' style={{ fontSize: 28 }} />
									</Badge>
								</IconButton>
								<Menu
									id='notification-menu'
									anchorEl={anchorEl2}
									anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
									transformOrigin={{ vertical: 'top', horizontal: 'left' }}
									open={Boolean(anchorEl2)}
									onClose={handleCloseOptions}
								>
									{notifications.map((notif, idx) => {
										const temp = JSON.parse(`${notif}`);
										return (
											<MenuItem key={temp.id} id={`${idx}-${temp.type}`} onClick={onClickNotification}>
												<div
													key={temp.id}
													id={`${idx}-${temp.type}`}
													style={{ width: 288 }}
													className='flex-align-center'
												>
													<MdNotificationsActive style={{ color: 'royalblue', marginRight: 10 }} />
													<p id={`${idx}-${temp.type}`}>{`${DateToLocaleShort(new Date(temp.timestamp))} - ${
														temp.msg
													}`}</p>
												</div>
											</MenuItem>
										);
									})}
								</Menu>
							</div>

              <div>
                <Button
                  id="user-opts-button"
                  style={{ fontSize: 20 }}
                  variant="outlined"
                  color="primary"
                  startIcon={<MdAccountCircle id="user-opts-button" onClick={handleOpenOptions} />}
                  onClick={handleOpenOptions}
                >
                  {userState.user.fullName}
                </Button>
                <Menu
                  id="user-opts"
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseOptions}
                >
                  {USER_OPTS.map((opt) => (
                    <MenuItem id={opt.id} key={opt.id} onClick={handleOptionSelect}>
                      {opt.name}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </div>
          </div>
        </div>
        {children}
      </div>
      <AlertBar
        visible={snackbar.visible}
        onClose={handleCloseSnack}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </div>
  );
};

export default Inventory;
