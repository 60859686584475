import React, { useEffect, useState } from 'react';
import Spinner from '../../components/Spinner.js';
import '../../StyleSheets/TechPortal.css';
import CustomTable from '../../components/Table.js';
import AlertBar from '../../components/AlertBar.js';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DateToLocale, DateToYMD, FormatDateToMDY } from '../API/TextFormatingFunctions.js';
import env from '../../../env.config.json' assert { type: "json" };
import { logger } from '../../utilities/logging.js';
import { FormatTimeLocale, formatTimeToHHMM, getTimeOfDay } from '../../utilities/util.js';
import { WiDaySunny as DayIcon, WiNightClear as NightIcon } from 'react-icons/wi'; // Replaced icons
import Modal from '../../components/Modal.js';
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

const _file = require('path').basename(__filename);

const CurJobHeaders = [
    {id: 'assigned', name: 'assigned',sortable: true, numeric: false},
    {id: 'job', name: 'job #',sortable: true, numeric: false},
    {id: 'issue', name: 'issue',sortable: true, numeric: false},
    {id: 'your eta', name: 'your eta'},
    {id: 'address', name: 'address'},
    {id: 'directions', name: 'directions'},
];

const PrevJobHeaders = [
    {id: 'job', name: 'job #',sortable: true, numeric: false},
    {id: 'issue', name: 'issue',sortable: true, numeric: false},
    {id: 'address', name: 'address'},
    {id: 'completed', name: 'completed'}
];

const PortalHome = () => {
    const navigate = useNavigate();
    const [loading,setLoading] = useState(true);
    const [jobs,setJobs] = useState([]);
    const [name,setName] = useState("");
    const [resRate,setResRate] = useState(0);
    const [tech,setTech] = useState({});
    const [sending,setSending] = useState(false);
    const [selected,setSelected] = useState(null);
    const [tof,setTof] = useState(getTimeOfDay(new Date().getHours()));
    const [completedJobs,setCompletedJobs] = useState([]);
    const [modalOpen,setModalOpen] = useState(false);
    const [snackbar,setSnackbar] = useState({
        visible: false,
        message: '',
        severity: 'success'
    });

    useEffect(() => {
        retrieveData(true);
        const user = sessionStorage.getItem('techName');

        if(user){
            setName(user.split(' ')[0].trim());
        }
    },[]);

    useEffect(() => {
        $('#eta-modal').toggleClass('flex-box');
    },[modalOpen]);

    const retrieveData = (_load) => {
        if(_load) setLoading(true);
        const tid = sessionStorage.getItem('tid');

        if(tid){
            socket.emit('getJobsByTechID',tid,(res) => {
                if(!res){
                    setSnackbar(prevState => ({
                        ...prevState,
                        visible: true,
                        message: 'SERVER ERROR: There was an issue retrieving data for this page.  Please notify tech support of this issue.',
                        severity: 'error'
                    }));
                    logger.error(_file,retrieveData.name,`There was an issue calling this method`);
                }else{
                    const [openJobs,pendingJobs,completeJobs,tech,rate] = res;
                    setTech({
                        ...tech[0]
                    });
                    setJobs([...openJobs,...pendingJobs]);
                    setCompletedJobs(completeJobs);
                    setLoading(false);
                    setTimeout(() => {  // used to mimic lazy loading
                        setResRate(rate);
                    },250);
                }
            });
        }
    };

    const renderTableButtons = () => {
        return (
            <div></div>
        );
    };

    const renderCurJobData = () => {
        return jobs.length ? jobs.map(job => ({
            key: job.ID,
            cells: [
                {data: job.AssignedDate ? DateToLocale(new Date(job.AssignedDate)) : "Pending"},
                {data: job.JobID },
                {data: job.issue },
                {data: job.Responses && JSON.parse(job.Responses).find(res => res.Number == `+1${tech.phone}`) ? JSON.parse(job.Responses).find(res => res.Number == `+1${tech.phone}`).ETA : (
                    <Button id={job.JobID} variant="outlined" color="primary" onClick={() => onProvideEta(job.JobID)}>provide eta</Button>
                )},
                {data: job.dest_address },
                {data: (
                    <a style={{color: 'blue', textDecoration: 'underline'}} target="_blank" href={
                        `${env.GOOGLE_MAP_DEF}My+Location&destination=${job.dest_address}&travelmode=driving`
                    }>Get Directions</a>
                )}
            ]
        })) :
        [{
            key: 0,
            cells: [
                {data: `There are no jobs open for you at this time.`, span: 6},
            ]
        }];
    };

    const onProvideEta = (idx) => {
        setSelected(idx);
        toggleModal();
    };

    const renderPrevJobData = () => {
        return completedJobs.length ? completedJobs.map(job => ({
            key: job.ID,
            cells: [
                {data: job.JobID},
                {data: job.issue },
                {data: job.dest_address },
                {data: DateToLocale(new Date(job.CompletedDate))}
            ]
        })) :
        [{
            key: 0,
            cells: [
                {data: `You don't have any previous jobs to display.`, span: 4}
            ]
        }];
    };

    const handleCloseSnack = () => {
        setSnackbar(prevState => ({
            ...prevState,
            visible: false
        }));
    };

    const rowClick = (e) => {
        const {id} = e.target;
        navigate(`tech-portal/jobs?jid=${id}&tid=${sessionStorage.getItem('tid')}`);
    };

    const toggleModal = () => {
        setModalOpen(prevState => !prevState);
    };

    const handleETA = () => {
        const dateEta = document.getElementById('date-eta').value;
        const timeEta = document.getElementById('time-eta').value;

        if(!dateEta && !timeEta){
            setSnackbar(prevState => ({
                ...prevState,
                visible: true,
                message: 'WARNING: You must provide a date and time for your ETA.',
                severity: 'warning'
            }));
        }else{
            setSending(true);
            const msid = `RTI${selected}`;
            const eta = `${selected} ${FormatDateToMDY(dateEta)} ${FormatTimeLocale(timeEta)}`;
            const mobile = `+1${tech.phone}`;

            $.post('/api/twilio/sms-in',{From: mobile, Body: eta, SmsSid: msid},(res) => {
                if(res && res.msg){
                    setSending(false);
                    setSnackbar(prevState => ({
                        ...prevState,
                        visible: true,
                        message: `SERVER ERROR: ${res.msg}`,
                        severity: 'error'
                    }));
                }else{
                    setSnackbar(prevState => ({
                        ...prevState,
                        visible: true,
                        message: `Thank you, we have received your ETA!`,
                        severity: 'success'
                    }));
                    setSending(false);
                    retrieveData();
                    toggleModal();
                }
            });
        }
    };

    // takes in a decimal and converts it into a percent
    const formatDisplayPercent = (_num = 0) => {
        return _num ? `${Math.round(_num * 100)}%` : 'N/A';
    };

    const formatProgressDisplay = (_num = 0) => {
        const rate = (_num * 100);
        return rate > 55 ? 'blue' : rate <= 55 && rate > 35 ? 'orange' : 'red';
    };

    return (
        <div>
            {!loading ?
                <div className='flex-column flex-align-center'>
                    <AlertBar
                        visible={snackbar.visible}
                        onClose={handleCloseSnack}
                        message={snackbar.message}
                        severity={snackbar.severity}
                    />
                    <Modal modal_id='eta-modal' dimension={{width: 500}} modal_header="Provide Your ETA" onClose={toggleModal}>
                        <div className='flex-column flex-just-center flex-align-center'>
                            <p>Please input your ETA and click the <b>CONFIRM</b> button below to respond to this job.</p>
                            <div style={{ marginBottom: 46 }}>
                                <TextField style={{ minWidth: 200 }} id="date-eta" className="table-date-input space-below" label="Date" type="date" defaultValue={DateToYMD(new Date())} /><br></br>
                                <TextField style={{ minWidth: 200 }} id="time-eta" className="table-date-input space-below" label="Time" type="time" defaultValue={formatTimeToHHMM(new Date().toLocaleTimeString())} />
                            </div>
                            {!sending ? <span style={{ paddingBottom: 40 }}>
                                <Button style={{ fontSize: 16, width: 200 }} variant="outlined" color="primary" onClick={handleETA}>Confirm</Button>
                            </span> :
                            <span className='text-center' style={{ width: '75%', paddingBottom: 40 }}>
                                <label>Sending ETA...</label><br></br>
                                <LinearProgress />
                            </span>
                            }
                        </div>
                    </Modal>
                    <div className='flex-align-center flex-just-center'>
                        <h1 className='title'>Good {tof}, {name}</h1>
                        {tof == env.TIME_OF_DAY.EVENING.DISPLAY ? <NightIcon style={{fontSize: 40, color: 'darkslategray'}} /> : <DayIcon style={{fontSize: 40, color: 'gold'}} />}
                    </div>
                    <div className='flex-just-center flex-wrap'>
                        <div className='flex-column flex-align-center job-tbl-wrapper'>
                            <div className='flex-column flex-align-center'>
                                <h1>Your Open Jobs</h1>
                                <CustomTable
                                    paginate
                                    loading_data={loading} 
                                    headers={CurJobHeaders} 
                                    rows={renderCurJobData()} 
                                    table_buttons={renderTableButtons()}
                                    onRefresh={() => retrieveData(true)}
                                />
                            </div>
                            <div style={{width: '75%'}} className='flex-just-around job-tbl-wrapper'>
                                <div className='tech-card-res-wrapper'>
                                    <h1>Response Rate</h1>
                                    <Card>
                                        <CardContent>
                                            <div style={{position: 'relative'}}>
                                                <CircularProgress style={{width: 150, height: 150}} className={`percent-img`} variant='determinate' value={resRate.length ? (resRate[0].rate * 100) : 0} />
                                                <label className='percent-label'>{resRate.length ? formatDisplayPercent(resRate[0].rate) : 'N/A'}</label>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                                <div className='tech-card-res-wrapper'>
                                    <h1>Jobs Accepted</h1>
                                    <Card>
                                        <CardContent>
                                            <div style={{position: 'relative'}}>
                                                <CircularProgress style={{width: 150, height: 150}} className={`percent-img`} variant='determinate' value={resRate.length ? (resRate[0].acceptance_rate * 100) : 0} />
                                                <label className='percent-label'>{resRate.length ? formatDisplayPercent(resRate[0].acceptance_rate) : 'N/A'}</label>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        </div>
                        <div id="tech-job-right" className='flex-column flex-align-center job-tbl-wrapper'>
                            <h1>Previous Jobs</h1>
                            <CustomTable
                                paginate
                                loading_data={loading} 
                                headers={PrevJobHeaders} 
                                rows={renderPrevJobData()} 
                                table_buttons={renderTableButtons()}
                                onClick={rowClick}
                                onRefresh={() => retrieveData(true)}
                            />
                        </div>
                    </div>
                </div>
                : <Spinner />        
            }
        </div>
    );
};

export default PortalHome;
