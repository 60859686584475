import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App.js';
import ServiceReport from './imports/ServiceReportUI/ServiceReport.js';
import './StyleSheets/ServiceReport.css';
import Login from './components/LoginModal.js';
import Jobs from './imports/JobsUI/Jobs.js';
import PaperChange from './imports/PaperChangeUI/PaperChange.js';
import AlertsTable from './imports/TableManagementUI/AlertTable.js';
import UsersTable from './imports/TableManagementUI/UsersTable.js';
import IssueTable from './imports/TableManagementUI/IssueTable.js';
import KioskTable from './imports/TableManagementUI/KioskTable.js';
import MobileEmailLists from './imports/TableManagementUI/MobileEmailLists.js';
import JobResponseTable from './imports/TableManagementUI/JobResponseTable.js';
import ServiceReportTable from './imports/TableManagementUI/ServiceReportTable.js';
import TechSupportSchedule from './imports/TableManagementUI/TechSupportSchedule.js';
import TechnicianTable from './imports/TableManagementUI/TechnicianTable.js';
import TimeEntryTable from './imports/TableManagementUI/TimeEntryTable.js';
import PersonalTimeSheet from './imports/TableManagementUI/PersonalTimeSheet.js';
import ServiceReportAnalytics from './imports/TableManagementUI/ServiceReportAnalytics.js';
import TechAnalytics from './imports/TableManagementUI/TechAnalytics.js';
import TimeOffCalendar from './imports/TechTimeOffUI/TimeOffCalendar.js';
import TechTimeOff from './imports/TechTimeOffUI/TechTimeOff.js';
import AlertAnalytics from './imports/TableManagementUI/AlertAnalytics.js';
import DriveTimeTable from './imports/TableManagementUI/DriveTimeTable.js';
import CallLogTable from './imports/TableManagementUI/CallLogTable.js';
import KioskAnalytics from './imports/TableManagementUI/KioskAnalytics.js';
import TechCallLogTable from './imports/TableManagementUI/TechCallLogTable.js';
import IssueTracking from './imports/TableManagementUI/IssueTracking.js';
import StickerCounter from './imports/InventorySystem/StickerCounter.js';
import StickerAuditTable from './imports/InventorySystem/StickerAudit/StickerAuditTable.js';
import NotFound from './components/NotFound.js';
import TechPortalHome from './imports/TechPortal/TechPortalHome.js';
import TechLogin from './imports/TechPortal/TechLogIn.js';
import PortalHome from './imports/TechPortal/PortalHome.js';
import Auth from './hoc/Auth.js';
import MainAuth from './hoc/MainAuth.js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import KioskMap from './imports/GoogleMapUI/KioskMap.js';
import EmployeeCalendar from './imports/TechTimeOffUI/EmployeeCalendar.js';
import Confirmation from './imports/TechPortal/Confirmation.js';
import ProtectedRoute from '../src/hoc/ProtectedRoute.js';
import env from '../env.config.json' assert { type: 'json' };
import PaperCores from './imports/InventorySystem/PaperCores.js';
import InvAuth from './hoc/InvAuth.js';
import PasswordReset from './components/PasswordReset.js';
import Inventory from './imports/InventorySystem/Inventory.js';
import ScanItem from './imports/InventorySystem/ScanItem.js';
import InventoryItem from './imports/InventorySystem/InventoryItem.js';
import AllItems from './imports/InventorySystem/AllItems.js';
import PurchaseOrders from './imports/InventorySystem/PurchaseOrders.js';
import ItemsReceived from './imports/InventorySystem/ItemsReceived.js';
import PrintBarcode from './imports/InventorySystem/PrintBarcode.js';
import InventoryBin from './imports/InventorySystem/InventoryBin.js';
import PaperInventory from './imports/InventorySystem/PaperInventory.js';
import ShortURL from './components/ShortURL.js';
import Help from './imports/TechPortal/Help.js';
import TechSolutions from './imports/TableManagementUI/TechSolutions.js';
import RTISolutions from './imports/TableManagementUI/RTISolutions.js';
import TechJobs from './imports/TechPortal/Jobs.js';
import EmailList from './imports/TableManagementUI/EmailList.js';

const container = document.getElementById('appRoot');
const root = createRoot(container);

root.render(
    <Router>
        <Routes>
            <Route path="/service" element={<ServiceReport />} />
            <Route path="/short/:id" element={<ShortURL />} />
            <Route path="/login" element={<Login redirectPath="/" />} />
            <Route path="/confirm" element={<Confirmation />} />
            <Route path="/forgot-pass" element={<PasswordReset type="forgot" />} />
            <Route path="/tech-portal/forgot-pass" element={<PasswordReset />} />
            <Route
                path="/inventory/*"
                element={
                    <InvAuth redirectPath="/login">
                        <Inventory>
                            <Routes>
                                <Route path="/paper/" element={<PaperInventory />} />
                                <Route path="/bin/:id?" element={<InventoryBin />} />
                                <Route path="/barcodes/" element={<PrintBarcode />} />
                                <Route path="/sticker-counter/" element={<StickerCounter />} />
                                <Route path="/sticker-audit/" element={<StickerAuditTable />} />
                                <Route path="/purchase-order/" element={<PurchaseOrders />} />
                                <Route path="/purchase-order/:id?" element={<PurchaseOrders />} />
                                <Route path="/received-items/" element={<ItemsReceived />} />
                                <Route path="/items" element={<AllItems />} />
                                <Route path="/send-items/" element={<PaperCores />} />
                                <Route path="/bulk-item/" element={<InventoryItem />} />
                                <Route path="/receive/" element={<ScanItem />} />
                                <Route path="/receive/:id?" element={<InventoryItem />} />
                                <Route path="/paper/" element={<ScanItem />} />
                                <Route path="/paper/:id?" element={<PaperCores />} />
                                <Route path="*" element={<ScanItem />} />
                            </Routes>
                        </Inventory>
                    </InvAuth>
                }
            />
            <Route path="/tech-portal/login" element={<TechLogin />} />
            <Route
                path="/tech-portal"
                element={
                    <Auth>
                        <TechPortalHome>
                            <Routes>
                                <Route path="*" element={<PortalHome />} />
                                <Route path="/service" element={<ServiceReport />} />
                                <Route path="/help" element={<Help />} />
                                <Route path="/jobs" element={<TechJobs />} />
                            </Routes>
                        </TechPortalHome>
                    </Auth>
                }
            />
            <Route
                path="*"
                element={
                    <MainAuth redirectPath="/login">
                        <App>
                            <Routes>
                                <Route path="*" element={<Jobs />} />
 
                                {`${env.USER_PERMS[2]} ${env.USER_PERMS[3]} ${env.USER_PERMS[0]}` ? (
                                    <>
                                        <Route path="/alerts_table" element={<AlertsTable />} />
                                        <Route path="/users" element={<UsersTable />} />
 
                                        <Route path="/kiosks" element={<KioskTable />} />
                                        <Route path="/kiosk_issues" element={<IssueTable />} />
                                        <Route path="/messaging" element={<MobileEmailLists />} />
                                        <Route path="/job_responses" element={<JobResponseTable />} />
                                        <Route path="/service_reports" element={<ServiceReportTable />} />
                                        <Route path="/tech_support_schedule" element={<TechSupportSchedule />} />
                                        <Route path="/technicians" element={<TechnicianTable />} />
 
                                        <Route path="/sr_analytics" element={<ServiceReportAnalytics />} />
                                        <Route path="/kiosk_analytics" element={<KioskAnalytics />} />
                                        <Route path="/tech_analytics" element={<TechAnalytics />} />
                                        <Route path="/calendar" element={<EmployeeCalendar />} />
                                        <Route path="/tech_time_off" element={<TechTimeOff />} />
                                        <Route path="/alert_analytics" element={<AlertAnalytics />} />
                                        <Route path="/drive_times" element={<DriveTimeTable />} />
                                        <Route path="/call_logs" element={<CallLogTable />} />
                                        <Route path="/tech_call_logs" element={<TechCallLogTable />} />
                                        <Route path="/issue_tracking" element={<IssueTracking />} />
                                    </>
                                ) : null}
 
                                {`${env.USER_PERMS[2]} ${env.USER_PERMS[0]}` ? (
                                    <>
                                        <Route path="/paper" element={<PaperChange />} />
                                        <Route path="/time_off" element={<TimeOffCalendar />} />
                                        <Route path="/time_entries" element={<TimeEntryTable />} />
                                        <Route path="/map" element={<KioskMap />} />
 
                                        <Route path="/tech-solutions" element={<TechSolutions />} />
                                        <Route path="/rti-solutions" element={<RTISolutions />} />
                                        <Route path="/email-list" element={<EmailList />} />
                                    </>
                                ) : null}
 
                                <Route path="/my_time_sheet" element={<ProtectedRoute perms="all" element={<PersonalTimeSheet />} />} />
                                <Route path="/password-reset" element={<ProtectedRoute perms="all" element={<PasswordReset />} />} />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </App>
                    </MainAuth>
                }
            />
        </Routes>
    </Router>
);
 