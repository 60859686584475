/**
 * @file ReportModal.js
 * @component
 * @category User Interface
 * @returns {JSX.Element} The Kiosk Analytics component.
 * @module ReportModal
 */
import React, { useState, useEffect } from 'react';
import Button from './Button';
import Spinner from './Spinner';
import LogForm from './Logs';
import { CustomerLogEmail, TechLogEmail, StoreLogEmail, BugReportEmail, EOSEmail, locationRequestEmail, ServiceAlarmEmail } from './EmailTemplates';
import { CurrentTime } from '../imports/API/Moment';
import { DateToYMD } from '../imports/API/TextFormatingFunctions';
import '../StyleSheets/Chat.css';
import { useCustomContext } from '../hoc/Context';
import HeaderContainer from './ReportModalHeaderContainer';
import ReportBody from './ReportModalBody';
import axios from 'axios';

const ReportModal = (props) => {
	const { userState, userDispatch } = useCustomContext();
	const initial_state = {
		Submitting: false,
		AllReports: true,
		ReportSelected: '',
		LogHeader: '',
		LogSelected: null,
		ReportSaved: false,
		ChangesMade: false,
		IndexSelected: null,
		SavedLogs: userState.user.user_logs && userState.user.user_logs.length ? JSON.parse(userState.user.user_logs) : [],
	};

	const [Dropdown, setDropdown] = useState(false);
	const [Loading, setLoading] = useState(true);
	const [EmailList, setEmailList] = useState([]);
	const [retrieveData, setRetrieveData] = useState(true);
	const [LogsData, setLogsData] = useState(initial_state);
	const [selectedSavedLog, setSelectedSavedLog] = useState(null);

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			if (retrieveData) {
				$('#report-modal').draggable({ cancel: '.all-report-body' }); // prevents the chat messaging body from being draggable so that elements can be copied.
				axios
					.post('/api/table_data', { table: 'report_table' })
					.then((res) => {
						if (res.data.status === 'OK') {
							const [email_list] = res.data.data;

							setEmailList(email_list);
							setRetrieveData(false);
							setLoading(false);
						} else {
							setRetrieveData(false);
							setLoading(false);
						}
					})
					.catch((error) => {
						setRetrieveData(false);
						setLoading(false);
					});
			} else {
				// if initial data has already been retrieved
				setLogsData((prevData) => ({
					...prevData,
					SavedLogs: JSON.parse(userState.user.user_logs),
				}));
				setLoading(false);
			}
		}

		return () => {
			mounted = false;
		};
	}, [JSON.parse(userState.user.user_logs).length]); // get new logs object if log length has changed

	/**
	 * Function that handles the back button action.
	 * @memberof module:ReportModal
	 * @param {boolean} submitted - Indicates whether the form has been submitted.
	 * @returns {void}
	 */
	const BackButton = (submitted) => {
		setLoading(true);

		if (LogsData.ReportSaved || (!LogsData.ChangesMade && !LogsData.ReportSaved) || submitted) {
			// did user save this report? OR user did not make any changes to the report
			socket.emit('getUserByUsername', userState.user.username, (user) => {
				if (!user) {
					console.error('There was an issue validating user information.');
				} else {
					userDispatch({
						type: 'UPDATE_USER_LOGS',
						user_logs: user[0].UserLogs,
					});
					setLogsData(initial_state);
					setLoading(false);
				}
			});
		} else if (LogsData.ChangesMade && !LogsData.ReportSaved) {
			// were changes made and the report was not saved
			let response = confirm(
				'WARNING: Are you sure you want to go back without saving your changes?\n\nPlease make sure you have saved your changes before going back.\n\nIf you would like to continue without saving, click OK, otherwise click CANCEL and save your changes.'
			);

			if (response) {
				socket.emit('getUserByUsername', userState.user.username, (user) => {
					if (!user) {
						console.error('There was an issue validating user information.');
					} else {
						userDispatch({
							type: 'UPDATE_USER_LOGS',
							user_logs: user[0].UserLogs,
						});
						setLogsData(initial_state);
						setLoading(false);
					}
				});
			} else {
				setLogsData((prevData) => ({
					...prevData,
					Submitting: false,
				}));
				setLoading(false);
			}
		}
	};

	/**
	 * Renders the saved logs.
	 *
	 * @returns {JSX.Element} The rendered saved logs.
	 * @memberof module:ReportModal
	 */
	function RenderSavedLogs() {
		// LogsData.SavedLogs.length == '[]' ? true : false);
		if (typeof LogsData.SavedLogs === 'string') {
			return (
				<tr>
					<td>
						<div className='all-report-body-container'></div>
						<div className='message-body'>No logs saved</div>
					</td>
				</tr>
			);
		} else {
			return LogsData.SavedLogs.map((log, index) => {
				return (
					<tr title={`${log.display_name} - ${FormatTimestamp(log.timestamp)}`} key={index} id={index}>
						<td id={index}>
							<div className='all-report-body-container' id={index}>
								<div
									className='message-body'
									id={index}
									style={{
										marginRight: 'auto',
										padding: '6px 0px',
										width: '100%',
									}}
								>
									<div id={index}>
										{log.display_name} - {FormatTimestamp(log.timestamp)}
									</div>
								</div>
								<div style={{ display: 'flex', width: '100%' }}>
									<div
										id={index}
										style={{
											fontSize: '12px',
											padding: '6px 0px',
											marginRight: 'auto',
										}}
									>
										<Button style={{ fontSize: '18px' }} id={index} color='rti-close' click={GetSavedLog}>
											&#9998;
										</Button>
									</div>
									<div id={index} style={{ fontSize: '12px', padding: '6px 0px' }}>
										<Button id={index} color='rti-close' click={RemoveSavedLog}>
											&times;
										</Button>
									</div>
								</div>
							</div>
						</td>
					</tr>
				);
			});
		}
	}

	/**
	 * Formats a timestamp into a human-readable format.
	 *
	 * @param {string} timestamp - The timestamp to be formatted.
	 * @returns {string} The formatted timestamp.
	 * @memberof module:ReportModal
	 */
	function FormatTimestamp(timestamp) {
		let today = new Date();
		let [date, time] = timestamp.split(' ');
		let [y, month, d] = date.split('-');
		let [h, m, s] = time.split(':');

		let hours = parseInt(h) > 12 && parseInt(h) <= 23 ? parseInt(h) - 12 : parseInt(h);

		let today_formatted = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
		let timestamp_formatted = `${y}-${parseInt(month)}-${parseInt(d)}`;

		return `${
			today_formatted == timestamp_formatted ? 'Today' : `${parseInt(month)}/${parseInt(d)}/${y}`
		} @ ${hours}:${m} ${parseInt(h) >= 0 && parseInt(h) < 12 ? 'A.M.' : 'P.M.'}`;
	}

	/**
	 * Retrieves a saved log based on the provided event target ID.
	 * @memberof module:ReportModal
	 * @param {Event} e - The event object containing the target ID.
	 * @returns {void}
	 */
	const GetSavedLog = (e) => {
		const id = e.target.id;
		let saved_log = LogsData.SavedLogs[id];
		setSelectedSavedLog(saved_log);
		setLogsData((prevData) => ({
			...prevData,
			LogSelected: saved_log,
			IndexSelected: id,
			AllReports: false,
			ReportSelected: saved_log.type,
			LogHeader: saved_log.display_name,
		}));
	};

	/**
	 * Removes a saved log from the LogsData.SavedLogs array.
	 *
	 * @memberof module:ReportModal
	 * @param {Event} e - The event object.
	 * @returns {void}
	 */
	const RemoveSavedLog = (e) => {
		let index = e.target.id;
		let saved_logs = LogsData.SavedLogs;
		// update saved logs before calling setstate
		let response = confirm(`Are you sure you want to remove this log?\nIf YES, then click OK, otherwise click CANCEL.`);

		if (response) {
			saved_logs.splice(index, 1);
			SaveUserLog(saved_logs, (res) => {
				if (!res) {
					props.onAlert({
						message: `ERROR: There was an issue removing this user's log.`,
						severity: 'error',
					});
					console.error('there was an issue saving this users logs');
				} else {
					props.onAlert({
						message: 'Log removed successfully',
						severity: 'success',
					});
				}
			});
		}
	};

	/**
	 * Saves the log form data.
	 * @memberof module:ReportModal
	 * @returns {void}
	 */
	const SaveLog = () => {
		let form_inputs = document.getElementsByName('form-input');
		let form_fields = [];
		let saved_logs = [...LogsData.SavedLogs];
		let log_selected = LogsData.LogSelected;

		form_inputs.forEach((el) => {
			if (el.id !== 'COVR' && el.id !== 'Sticker' && el.id !== 'Receipt' && el.id !== 'Previously Mailed') {
				if (el.id.includes('-') || el.id == 'option3') {
					// secondary option selected, append to previous element
					form_fields[form_fields.length - 1].value = `${form_fields[form_fields.length - 1].value} - ${el.value}`;
				} else {
					form_fields.push({
						element_id: el.id,
						value: el.value,
					});
				}
			}
		});

		if (saved_logs.length == 0) {
			// this is the first log form being saved
			saved_logs.push({
				type: LogsData.ReportSelected,
				display_name: LogsData.LogHeader,
				fields: form_fields,
				timestamp: CurrentTime(),
			});
		} else if (saved_logs.length > 0) {
			if (log_selected) {
				// log exists, update log information
				// get index of selected log and update fields
				saved_logs[LogsData.IndexSelected].fields = form_fields;
				saved_logs[LogsData.IndexSelected].timestamp = CurrentTime();
			} else {
				// log does not exist, push new saved log
				saved_logs.push({
					type: LogsData.ReportSelected,
					display_name: LogsData.LogHeader,
					fields: form_fields,
					timestamp: CurrentTime(),
				});
			}
		}

		SaveUserLog(saved_logs, (res) => {
			if (!res) {
				props.onAlert({
					message: `ERROR: There was an issue saving this user's log.`,
					severity: 'error',
				});
			} else {
				props.onAlert({
					message: `Log has been saved.`,
					severity: 'success',
				});
				setLogsData((prevData) => ({ ...prevData, ReportSaved: true }));
			}
		});
	};

	/**
	 * Saves the user log by sending it to the server.
	 * @memberof module:ReportModal
	 * @param {Object} form_to_save - The form data to be saved.
	 * @param {Function} callback - The callback function to be called after saving the user log.
	 * @returns {void}
	 */
	function SaveUserLog(form_to_save, callback) {
		const logs = JSON.stringify(form_to_save);
		socket.emit('saveUserLog', logs, userState.user.uid, (res) => {
			if (!res) {
				callback(false);
			} else {
				// need to dispatch to get new user saved logs
				userDispatch({ type: 'UPDATE_USER_LOGS', user_logs: logs });
				callback(true);
			}
		});
	}

	/**
	 * Submits the log form.
	 * @memberof module:ReportModal
	 * @returns {void}
	 */
	const SubmitLog = () => {
		// Helper function to process form inputs
		/**
		 * Processes the inputs of a form.
		 * @memberof module:ReportModal
		 * @param {Array} inputs - An array of input elements.
		 * @returns {Object} - An object containing the form inputs to submit and a validity flag.
		 */
		const processFormInputs = (inputs) => {
			let form_to_submit = [];
			let valid = true;

			inputs.forEach((el) => {
				if (!el.value && el.id !== 'notes') {
					valid = false;
				}
				if (el.type === 'checkbox' && !['COVR', 'Sticker', 'Receipt', 'Previously Mailed'].includes(el.id)) {
					form_to_submit.push({ element_id: el.id, value: el.checked });
				} else if (!['COVR', 'Sticker', 'Receipt', 'Previously Mailed'].includes(el.id)) {
					if (el.id.includes('-') || el.id === 'option3') {
						form_to_submit[form_to_submit.length - 1].value += ` - ${el.value}`;
					} else {
						form_to_submit.push({ element_id: el.id, value: el.value });
					}
				}
			});

			return { form_to_submit, valid };
		};

		// Helper function to append document checks
		/**
		 * Appends document checks to the form to be submitted.
		 * @memberof module:ReportModal
		 * @param {Array} form_to_submit - The form to be submitted.
		 * @returns {void}
		 */
		const appendDocumentChecks = (form_to_submit) => {
			const covr_docs = document.getElementById('COVR')?.checked || null;
			const sticker_docs = document.getElementById('Sticker')?.checked || null;
			const recpt_docs = document.getElementById('Receipt')?.checked || null;
			const mailed_docs = document.getElementById('Previously Mailed')?.checked || null;

			if (covr_docs || sticker_docs || recpt_docs || mailed_docs) {
				form_to_submit[7].value += `${mailed_docs ? ' - Previously Mailed' : ''}${covr_docs ? ' - COVR' : ''}${
					sticker_docs ? ' - Sticker' : ''
				}${recpt_docs ? ' - Receipt' : ''}`;
			}
		};

		/**
		 * Handles the submission of a log.
		 *
		 * @param {Object} form_to_submit - The form data to submit.
		 * @param {string} log_type - The type of log.
		 * @param {Array} email_list - The list of email recipients.
		 * @param {string} subject - The subject of the email.
		 * @param {boolean} log_selected - Indicates if a log is selected.
		 * @returns {void}
		 * @memberof module:ReportModal
		 */
		const handleLogSubmission = (form_to_submit, log_type, email_list, subject, log_selected) => {
			const to = email_list;
			const msg =
				LogsData.ReportSelected === 'CustomerLog'
					? CustomerLogEmail(form_to_submit)
					: LogsData.ReportSelected === 'TechLog'
					? TechLogEmail(form_to_submit)
					: LogsData.ReportSelected === 'StoreLog'
					? StoreLogEmail(form_to_submit)

					: LogsData.ReportSelected === 'locationRequest' 
					? locationRequestEmail(form_to_submit)
					: LogsData.ReportSelected === 'Bug'
					? BugReportEmail(form_to_submit)
					: LogsData.ReportSelected === 'EOS'
					? EOSEmail(form_to_submit)
					: ServiceAlarmEmail(form_to_submit); //Was EOSEmail.
			if (log_selected) {
				// insertLog(form_to_submit, log_type);
				UpdateAndSend(form_to_submit, msg, to, subject, true, log_type);
			} else {
				// insertLog(form_to_submit, log_type);
				UpdateAndSend(form_to_submit, msg, to, subject, false, log_type);
			}
		};

		// Main function logic
		let form_inputs = document.getElementsByName('form-input');
		let { form_to_submit, valid } = processFormInputs(form_inputs);
		let log_selected = LogsData.LogSelected;
		const email_form =
			LogsData.ReportSelected === 'Bug'
				? 'Bug Report'
				: LogsData.ReportSelected === 'EOS'
				? 'Shift Report'
				: 'Call Log';
		const full_name = userState.user.fullName;
		// const email_list = EmailList.find((list) => list.ListName === email_form);
		const email_list = EmailList.filter((list) => list.ListName == email_form)[0];

		const subject =
			LogsData.ReportSelected === 'CustomerLog'
				? 'Customer Call Log'
				: LogsData.ReportSelected === 'TechLog'
				? 'Technician Call Log'
				: LogsData.ReportSelected === 'StoreLog'
				? 'Store Call Log'
				: LogsData.ReportSelected === 'Bug'
				? 'Bug Report'
				: LogsData.ReportSelected === 'serviceAlarm'
				? 'Service Alarm Report'
				: LogsData.ReportSelected === 'locationRequest'
				? 'Kiosk Location Request'
				: 'End Of Shift Report';
		const log_type = LogsData.ReportSelected;

		form_to_submit.push({ element_id: 'user', value: full_name });
		appendDocumentChecks(form_to_submit);

		if (LogsData.ReportSelected === 'StoreLog') {
			const kiosk_id = form_to_submit[0].value.split(' ')[0].toUpperCase().trim();
			if (valid) {
				socket.emit('getKioskInfo', kiosk_id, (kiosk) => {
					if (!kiosk || kiosk.length === 0) {
						props.onAlert({
							message: `ERROR: Please make sure that the kiosk you have entered is correct and try submitting again.`,
							severity: 'error',
						});
						console.error(`ERROR: ReportModal.getKioskInfo: There was an issue calling this method on ${new Date()}`);
						setLogsData((prevData) => ({ ...prevData, Submitting: false }));
					} else {
						form_to_submit.push({
							element_id: 'store_phone',
							value: kiosk[0].StorePhone || 'No Phone Number Available',
						});
						handleLogSubmission(form_to_submit, log_type, email_list, subject, log_selected);
					}
				});
			} else {
				setLogsData((prevData) => ({ ...prevData, Submitting: false }));
				props.onAlert({
					message: `OOPS! Looks like you forgot to fill out a required field. Please make sure all fields are filled out before submitting your log.`,
					severity: 'warning',
				});
			}
		} else if (LogsData.ReportSelected === 'Bug') {
			if (valid) {
				socket.emit('selectLastBugID', (bug_id) => {
					if (!bug_id) {
						setLogsData((prevData) => ({ ...prevData, Submitting: false }));
						props.onAlert({
							message: `ERROR: Looks like we have encountered an issue with sending this bug report. Please notify the developer of this issue directly.`,
							severity: 'error',
						});
						console.error(
							`ERROR: ReportModal.selectLastBugID: There was an issue calling this method on ${new Date()}`
						);
					} else {
						let id = `${'0'.repeat(4 - bug_id.toString().length)}${bug_id + 1}`;
						form_to_submit.push({ element_id: 'bug_id', value: id });
						const to = email_list;
						const msg = BugReportEmail(form_to_submit);

						if (log_selected) {
							UpdateAndSend(form_to_submit, msg, to, subject, true);
						} else {
							UpdateAndSend(form_to_submit, msg, to, subject, false);
						}
					}
				});
			} else {
				setLogsData((prevData) => ({ ...prevData, Submitting: false }));
				props.onAlert({
					message: `OOPS! Looks like you forgot to fill out a required field. Please make sure all fields are filled out before submitting your log.`,
					severity: 'warning',
				});
			}
		} else {
			if (valid) {
				handleLogSubmission(form_to_submit, log_type, email_list, subject, log_selected);
			} else {
				setLogsData((prevData) => ({ ...prevData, Submitting: false }));
				props.onAlert({
					message: `OOPS! Looks like you forgot to fill out a required field. Please make sure all fields are filled out before submitting your log.`,
					severity: 'warning',
				});
			}
		}
	};

	/**
	 * Fetches kiosk information from the server.
	 * @memberof module:ReportModal
	 * @param {string} kiosk_id - The ID of the kiosk.
	 * @returns {Promise<object>} - A promise that resolves with the kiosk information.
	 * @throws {Error} - If the kiosk information is not found.
	 */
	const fetchKioskInfo = async (kiosk_id) => {
		return new Promise((resolve, reject) => {
			socket.emit('getKioskInfo', kiosk_id, (kiosk) => {
				if (!kiosk) {
					console.error(`ERROR: ReportModal.fetchKioskInfo: There was an issue calling this method on ${new Date()}`);
					reject(new Error('Kiosk info not found'));
				} else {
					resolve(kiosk);
				}
			});
		});
	};

	/**
	 * Checks if an array of objects has an element with the specified element_id equal to 'kiosk_location'.
	 * @param {Array} array - The array of objects to be checked.
	 * @returns {string|boolean} - The value of the 'kiosk_location' element if found, otherwise false.
	 * @memberof module:ReportModal
	 */
	const hasKioskLocation = (array) => {
		for (const obj of array) {
			// Check if the current object has element_id equal to 'kiosk_location'
			if (obj.element_id === 'kiosk_location') {
				// Return the value if found
				return obj.value;
			}
		}
		// Return false if kiosk_location not found
		return false;
	};

	/**
	 * Updates and sends a form submission along with relevant information.
	 * @memberof module:ReportModal
	 * @param {Array} form_to_submit - The form data to be submitted.
	 * @param {string} msg - The message to be sent in the email.
	 * @param {string} to - The recipient of the email.
	 * @param {string} subject - The subject of the email.
	 * @param {boolean} remove_log - Indicates whether to remove the log.
	 * @param {string} log_type - The type of log.
	 * @returns {void}
	 */
	async function UpdateAndSend(form_to_submit, msg, to, subject, remove_log, log_type) {
		const kioskLocation = hasKioskLocation(form_to_submit);
		const findKioskObj = kioskLocation ? await fetchKioskInfo(kioskLocation) : null;

		const KioskID_Loc =
			kioskLocation === 'No Loc' || !findKioskObj ? 'WI0000' : findKioskObj[0]?.KioskID_Loc || 'WI0000';

		const sanitize = (value) => {
			if (typeof value !== 'string') {
				return value;
			}
			return value.replace(/['"`]/g, '') || value;
		};

		const findField = (value) => {
			const field = form_to_submit.find((field) => field.element_id === value);
			return field.value;
		};

		const createLogObject = (logType, form_to_submit, KioskID_Loc) => {
			switch (logType) {
				case 'CustomerLog':
					return {
						user: findField('user'),
						kiosk_location: sanitize(findField('kiosk_location')),
						date_of_issue: findField('date_of_issue'),
						person_calling: sanitize(findField('person_calling')),
						reason_for_call: sanitize(findField('reason_for_call')),
						plate_number: findField('plate_number'),
						vin: findField('vin'),
						customer_name: sanitize(findField('customer_name')),
						customer_phone: findField('customer_phone'),
						kiosk_needs_service: sanitize(findField('kiosk_needs_service')),
						sticker_resolution: sanitize(findField('sticker_resolution')),
						sticker_year: findField('sticker_year'),
						notes: sanitize(findField('notes')),
						"power_cycled": findField('power-cycled'),
						"address_confirmed": findField('address-confirmed'),
						"refund_requested": findField('refund-requested'),
						KioskID_Loc: KioskID_Loc,
					};
				case 'TechLog':
					return {
						user: findField('user'),
						kiosk_location: findField('kiosk_location').substring(0, 6),
						date_of_issue: findField('date_of_issue'),
						call_time: findField('call_time'),
						call_duration: findField('call_duration'),
						technician: sanitize(findField('technician')),
						reason_for_call: sanitize(findField('reason_for_call')),
						call_results: sanitize(findField('call_results')),
						notes: sanitize(findField('notes')),
						KioskID_Loc: KioskID_Loc,
					};
				case 'StoreLog':
					return {
						kiosk_location: sanitize(findField('kiosk_location')),
						user: findField('user'),
						reason_for_call: sanitize(findField('issue')),
						notes: findField('notes'),
						date_of_issue: findField('date_of_issue'),
						voicemail_returned: findField('voicemail_returned'),
						power_cycled: findField('power-cycled'),
						KioskID_Loc: KioskID_Loc,
					};
				case 'Bug':
					return {
						Name: form_to_submit[2].value,
						Date: DateToYMD(new Date()),
						Msg: form_to_submit[1].value,
					};
				case 'EOS':
					return {
						user: findField('user'),
						notes: findField('notes'),
					};
				case 'serviceAlarm':
					return {
						service_name: findField('service_name'),
						date_of_issue: findField('date_of_issue'),
						call_time: findField('call_time'),
						alert_resolution: findField('alert_resolution'),
						notes: findField('notes'),
						user: findField('user'),
						urgent_report: findField('urgent_report'),
					};
				case 'locationRequest':
					return {
						date_of_call: findField('date_of_call'),
						store_name: sanitize(findField('store_name')),
						store_city: sanitize(findField('store_city')),
						customer_name: sanitize(findField('customer_name')),
						customer_phone: findField('customer_phone'),
						store_phone: findField('store_phone'),
						rev_share: findField('rev_share'),
						third_party_renewals: findField('third_party_renewals'),
						notes: findField('notes'),
						user: findField('user'),
					};

				default:
					console.error('Unknown log type');
					return null;
			}
		};

		const logObj = createLogObject(LogsData.ReportSelected, form_to_submit, KioskID_Loc);
		if (!logObj) return;

		const eventEmitterMap = {
			CustomerLog: 'insertLog',
			TechLog: 'insertLog',
			StoreLog: 'insertLog',
			serviceAlarm: 'insertLog',
			locationRequest: 'insertLog',
			EOS: 'insertLog',
			Bug: 'insertBugReport',
		};

		const eventEmitter = eventEmitterMap[LogsData.ReportSelected];
		SendEmail(msg, to, subject, remove_log, eventEmitter, logObj, log_type, form_to_submit);
	}

	/**
	 * Sends an email with the specified parameters.
	 * @memberof module:ReportModal
	 * @param {string} msg - The message to be sent in the email.
	 * @param {string} to - The recipient of the email.
	 * @param {string} subject - The subject of the email.
	 * @param {boolean} remove_log - Indicates whether to remove the log.
	 * @param {string} eventEmitter - The event emitter to be used.
	 * @param {object} logObj - The log object.
	 * @param {string} log_type - The type of log.
	 * @param {object} form_to_submit - The form to be submitted.
	 */
	const SendEmail = (msg, to, subject, remove_log, eventEmitter, logObj, log_type, form_to_submit) => {
		if (eventEmitter) {
			const emitterVariables = eventEmitter !== 'insertBugReport' ? [logObj, log_type] : [logObj];
			socket.emit(eventEmitter, ...emitterVariables, (res) => {
				if (!res) {
					console.error(`ERROR: ReportModal.${eventEmitter}: There was an issue calling this method on ${new Date()}`);
				} else if (LogsData.ReportSelected === 'Bug' && form_to_submit[0].value) {
					let send_to = [];
					JSON.parse(to.MsgTo).forEach((user) => {
						send_to.push({
							body: 'Urgent Bug Report\n' + logObj.Msg,
							to: user,
							from: logObj.Name,
							time: CurrentTime(),
							type: 'admin',
						});
					});
					axios.post('/api/send_message', { messages: send_to }, (res) => {});
				}
				SendEmailTemplate(msg, to, subject, remove_log);
			});
		} else {
			SendEmailTemplate(msg, to, subject, remove_log);
		}
	};

	/**
	 * Sends an email template.
	 * @memberof module:ReportModal
	 * @param {string} msg - The message content of the email.
	 * @param {object} to - The recipient of the email.
	 * @param {string} to.EmailTo - The email address of the recipient.
	 * @param {string} subject - The subject of the email.
	 * @param {boolean} remove_log - Indicates whether to remove the log after sending the email.
	 * @returns {void}
	 */
	function SendEmailTemplate(msg, to, subject, remove_log) {
		let saved_logs = [...LogsData.SavedLogs];
		axios
			.get('/send', { params: { to: to.EmailTo, subject: subject, text: msg } })
			.then((response) => {
				if (
					response.data.startsWith('250') ||
					response.status === 200 ||
					response.data.startsWith('Mail sent successfully')
				) {
					if (remove_log) {
						saved_logs.splice(LogsData.IndexSelected, 1);

						SaveUserLog(saved_logs, (res) => {
							if (!res) {
								setLogsData((prevData) => ({ ...prevData, Submitting: false }));
								props.onAlert({
									message: `ERROR: There was an issue updating this log.`,
									severity: 'error',
								});
							} else {
								props.onAlert({
									message: `Log has been submitted.`,
									severity: 'success',
								});
								BackButton(true);
							}
						});
					} else {
						props.onAlert({
							message: `Log has been submitted.`,
							severity: 'success',
						});
						BackButton(true);
					}
				} else {
					setLogsData((prevData) => ({ ...prevData, Submitting: false }));
					props.onAlert({
						message: `ERROR: There was an issue with submitting this report`,
						severity: 'error',
					});
				}
			})
			.catch((error) => {
				console.error('Error sending email:', error);
				setLogsData((prevData) => ({ ...prevData, Submitting: false }));
				props.onAlert({
					message: `ERROR: There was an issue with submitting this report`,
					severity: 'error',
				});
			});
	}

	/**
	 * Renders a dropdown menu for creating new reports.
	 * @returns {JSX.Element} The rendered dropdown menu.
	 * @memberof module:ReportModal
	 */
	function NewReportDropdown() {
		let divtorender = null;

		divtorender = (
			<div className='report-dropdown' id='report-dropdown'>
				<div id='report-dropdown-inner' style={{ padding: '12px 0px' }}>
					<div>
						<Button id='customer-log' name='Customer Call' color='rti-dropdown' click={CreateNewReport}></Button>
					</div>
					<div>
						<Button id='tech-log' name='Technician Call' color='rti-dropdown' click={CreateNewReport}></Button>
					</div>
					<div>
						<Button id='store-log' name='Store Call' color='rti-dropdown' click={CreateNewReport}></Button>
					</div>
					<div>
						<Button id='eos' name='End Of Shift' color='rti-dropdown' click={CreateNewReport}></Button>
					</div>
					<div>
						<Button id='bug-report' name='Bug Report' color='rti-dropdown' click={CreateNewReport}></Button>
					</div>
					<div>
						<Button
							id='service-alarm-report'
							name='Service Alarm'
							color='rti-dropdown'
							click={CreateNewReport}
						></Button>
					</div>
					<div>
						<Button id='location-request' name='Location Request' color='rti-dropdown' click={CreateNewReport}></Button>
					</div>
					<div>
						<Button color='rti-dropdown' click={() => $('#report-dropdown').slideToggle()}>
							<div style={{ fontSize: '19px', fontWeight: 'bold' }}>&times;</div>
						</Button>
					</div>
				</div>
			</div>
		);
		return divtorender;
	}

	/**
	 * Creates a new report based on the selected report type.
	 * @memberof module:ReportModal
	 * @param {Event} e - The event object triggered by the report selection.
	 * @returns {void}
	 */
	function CreateNewReport(e) {
		$('#report-dropdown').toggle();
		setLoading(true);
		switch (e.target.id) {
			case 'customer-log':
				setLogsData((prevData) => ({
					...prevData,
					AllReports: false,
					ReportSelected: 'CustomerLog',
					LogHeader: 'Customer Log',
				}));
				setLoading(false);
				break;
			case 'store-log':
				setLogsData((prevData) => ({
					...prevData,
					AllReports: false,
					ReportSelected: 'StoreLog',
					LogHeader: 'Store Log',
				}));
				setLoading(false);

				break;
			case 'tech-log':
				setLogsData((prevData) => ({
					...prevData,
					AllReports: false,
					ReportSelected: 'TechLog',
					LogHeader: 'Tech Log',
				}));
				setLoading(false);

				break;
			case 'eos':
				setLogsData((prevData) => ({
					...prevData,
					AllReports: false,
					ReportSelected: 'EOS',
					LogHeader: 'End Of Shift',
				}));
				setLoading(false);

				break;
			case 'bug-report':
				setLogsData((prevData) => ({
					...prevData,
					AllReports: false,
					ReportSelected: 'Bug',
					LogHeader: 'Bug Report',
				}));
				setLoading(false);

				break;
			case 'service-alarm-report':
				setLogsData((prevData) => ({
					...prevData,
					AllReports: false,
					ReportSelected: 'serviceAlarm',
					LogHeader: 'Service Alarm Report',
				}));
				setLoading(false);

				break;
			case 'location-request':
				setLogsData((prevData) => ({
					...prevData,
					AllReports: false,
					ReportSelected: 'locationRequest',
					LogHeader: 'Kiosk Location Request',
				}));
				setLoading(false);

				break;
			default:
				console.error(`Unknown report type: ${reportType}`);
		}
	}

	function HandleDropdown() {
		$('#report-dropdown').slideToggle();
		setDropdown(!Dropdown);
	}

	const HandleSubmitting = () => {
		setSelectedSavedLog(null);
		setLogsData((prevData) => ({ ...prevData, Submitting: true }));
		SubmitLog();
	};

	const HandleChangesMade = () => {
		setLogsData((prevData) => ({ ...prevData, ChangesMade: true }));
	};

	return (
		<div id='report-modal-container' className='report-modal-container'>
			<div className='report-modal' id='report-modal'>
				<HeaderContainer LogsData={LogsData} BackButton={BackButton} />
				{Loading ? (
					<Spinner margin='chat-margin' />
				) : (
					<ReportBody
						LogsData={LogsData}
						Loading={Loading}
						RenderSavedLogs={RenderSavedLogs}
						NewReportDropdown={NewReportDropdown}
						HandleDropdown={HandleDropdown}
						LogForm={LogForm}
						HandleChangesMade={HandleChangesMade}
						SaveLog={SaveLog}
						SavedLogs={selectedSavedLog}
						HandleSubmitting={HandleSubmitting}
					/>
				)}
			</div>
		</div>
	);
};

export default ReportModal;
