import React, { useEffect, useState } from 'react';
import { useCustomContext } from './Context';
import { Navigate } from 'react-router-dom';

/**
 *Desctiption ProtectedRoute is a higher order component that checks if the user has the required permissions to access the route.
 * @param {Object} props
 * @returns {React.Component} ProtectedRoute
 */
const ProtectedRoute = ({ perms, element }) => {
  const [auth, setAuth] = useState(null);
  const { userState } = useCustomContext();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if ((perms.includes(userState.user.permissions) && userState.user.access) || perms === 'all') {
        setAuth(true);
      } else {
        setAuth(false);
      }
    }

        return () => {
            mounted = false;
        }
    },[]);

    return (
        <div>
            {auth == true ?
                <Routes>
                    <Route exact={props.exact} path={props.path} component={props.element ? props.element : null} render={props.render ? props.render : null} />
                </Routes>
            : auth == false ? <Navigate to="/" /> : null}
        </div>
    )
}

export default ProtectedRoute;
