import React, { useState,useEffect } from 'react';
import CustomTable from '../../components/Table.js';
import AlertBar from '../../components/AlertBar.js';
import '../../StyleSheets/InventorySystem.css';
import Button from '@mui/material/Button';
import { MdGetApp } from 'react-icons/md';
import { CSVLink } from 'react-csv';
import env from '../../../env.config.json' assert { type: "json" };
import { useCustomContext } from '../../hoc/Context.js';

const TableHeaders = [
    {id: 'status', name: 'Status'},
    {id: 'name', name: 'Name'},
    {id: 'start', name: 'Start'},
    {id: 'return', name: 'Return'},
    {id: 'notes', name: 'Notes'}    
]

const TableHeadersHidden = [
    {id: 'status', name: 'Status'},
    {id: 'name', name: 'Name'},
    {id: 'start', name: 'Start'},
    {id: 'return', name: 'Return'}    
]

const TimeOffCalendar = (props) => {
    const [Calendar, setCalendar] = useState([]);
    const [TimeOffObj, setTimeOffObj] = useState({
        StartDate: "",
        ReturnDate: "",
        FullName: "",
        Notes: "",
        ResponseNotes: "",
        IsActive: "",
        Status: "",
        TimeOffID: "",
        Approved: false,
        CheckApprove: false,
        CheckReject: false,
        DisableOptions: false,
    });
    const [snackbar,setSnackbar] = useState({
        visible: false,
        message: '',
        severity: 'success'
    })
    const [AllUsers, setAllUsers] = useState([]);
    const [Loading, setLoading] = useState(true);
    const {userState, userDispatch} = useCustomContext();

    useEffect(() => {
        let mounted = true;

        if(mounted){
            socket.emit('selectEmpCalendar', (calendar) => {
                socket.emit('selectUserEmail', (emails) => {
                    setCalendar(calendar);
                    setAllUsers(emails);
                    setLoading(false);
                });
            });            
        }

        return () => {
            mounted = false;
        }
    },[])

    const RowClick = (e) => {

        const obj = Calendar.filter(req => req.TimeOffID == e.target.id).map(req => ({
            StartDate: req.StartDate,
            ReturnDate: req.ReturnDate,
            FullName: req.FullName,
            Notes: req.Notes,
            ResponseNotes: req.ResponseNotes === null ? "" : req.ResponseNotes,
            IsActive: req.IsActive,
            Status: req.Status,
            TimeOffID: req.TimeOffID,
            Approved: req.Status == 'Approved' ? true : false,
            CheckApprove: req.Status == 'Approved' ? true : false,
            CheckReject: req.Status == 'Rejected' ? true : false,
            DisableOptions: req.Status !== 'Pending' ? true : false,
        }))

        setTimeOffObj(obj[0]);

        document.getElementById('myModal').style.display = "block";
        document.getElementById('modal_timeoff').style.display = "block";
    }

    function RefreshData(){
        socket.emit('selectEmpCalendar', (calendar) => {
            setCalendar(calendar);
            setTimeOffObj({
                StartDate: "",
                ReturnDate: "",
                FullName: "",
                Notes: "",
                ResponseNotes: "",
                IsActive: "",
                Status: "",
                TimeOffID: "",
                Approved: false,
                CheckApprove: false,
                CheckReject: false,
                DisableOptions: false,
            });
        });
    }

    const NoSelectedHandler = () => {
        document.getElementById('cancel-request-modal').style.display = 'none';
    }

    const YesSelectedHandler = () => {        
        let objectToInsert = {
            StartDate: TimeOffObj.StartDate,
            ReturnDate: TimeOffObj.ReturnDate,
            PrevNotes: TimeOffObj.Notes,
            Status: "Cancelled",
            Response: TimeOffObj.ResponseNotes,
            TimeOffID: TimeOffObj.TimeOffID,
        }

        const _userEmail = AllUsers.filter(user => user.FullName === TimeOffObj.FullName)[0].Email;

        socket.emit('cancelTimeOffRequest', objectToInsert, (boolean) => {
            if(!boolean){
                setSnackbar(prevState => ({
                    ...prevState,
                    visible: true,
                    message: 'ERROR: There was an error cancelling this request',
                    severity: 'error'
                }))
            }else{
                SendCancelEmail([_userEmail,'brian@registration-technology.com'], objectToInsert.Status, objectToInsert.Response, objectToInsert.StartDate, objectToInsert.ReturnDate, objectToInsert.PrevNotes);

                // Close Modal
                    document.getElementById('cancel-request-modal').style.display = 'none';
                    (document.getElementById('modal_timeoff')).style.display = "none";
                    (document.getElementById('myModal')).style.display = "none";

                    setSnackbar(prevState => ({
                        ...prevState,
                        visible: true,
                        message: "Time off response recorded. Emails will be sent shortly.",
                        severity: 'success'
                    }))
    
                    RefreshData();
            }
        })
    }

    function SendCancelEmail(userEmail, Status, Response, StartDate, ReturnDate, PrevNotes){
        let to = (userEmail);
        let text =
            `<div width="100%",style="text-align:center;">` +
            `<h1 style="text-align:center;">Time Off Request Cancelled</h1>` +
            `<table style="background-color:black; width: 400px;">` +
                `<tbody>` +
                `<tr><td style="background-color:bisque; width: 100px;">Employee</td><td style="background-color:white;">${TimeOffObj.FullName}</td></tr>` +
                `<tr><td style="background-color:bisque;">Requested Start Date</td><td style="background-color:white;">${StartDate}</td></tr>` +
                `<tr><td style="background-color:bisque;">Requested Return Date</td><td style="background-color:white;">${ReturnDate}</td></tr>` +
                `<tr><td style="background-color:bisque;">Request Notes</td><td style="background-color:white;">${PrevNotes}</td></tr>` +
                `<tr><td style="background-color:bisque;">Request Status</td><td style="background-color:white;">${Status}</td></tr>` +
                `<tr><td style="background-color:bisque;">Response</td><td style="background-color:white;">${Response}</td></tr>` +
                `</tbody>` +
            `</table >` +
            `</div >`;
        $.get("/send", { to: to, subject: `RTI Time Off Request Cancelled- ${TimeOffObj.FullName}`, text: text }, function (data) { });
    }

    const CancelRequest = () => {
        document.getElementById('cancel-request-modal').style.display = 'block'
    }

    const CloseButton = () => {
        document.getElementById('modal_timeoff').style.display = "none";
        document.getElementById('myModal').style.display = "none";
        
        setTimeOffObj({ 
            StartDate: "",
            ReturnDate: "",
            FullName: "",
            Notes: "",
            ResponseNotes: "",
            IsActive: "",
            Status: "",
            TimeOffID: "",
            Approved: "",
            Rejected: "",
            CheckApprove: false,
            CheckReject: false,
            DisableOptions: false,
        });
    }

    const SaveButton = () => {
        let approve = document.getElementById("approveCheckbox");
        let reject = document.getElementById("rejectCheckbox");
        let status = "";

        if(approve.checked === true){
            status = "Approved"
        } 
        if(reject.checked === true){
            status = "Rejected"
        }
        if(approve.checked === false && reject.checked === false){
            setSnackbar(prevState => ({
                ...prevState,
                visible: true,
                message: "You need to select a checkbox",
                severity: 'warning' 
            }))
            status = "";
        }
        if(approve.checked === true && reject.checked === true){
            setSnackbar(prevState => ({
                ...prevState,
                visible: true,
                message: "You can't check both checkboxes",
                severity: 'warning' 
            }))
            status = "";
        }

        let objectToInsert = {
            StartDate: TimeOffObj.StartDate,
            ReturnDate: TimeOffObj.ReturnDate,
            PrevNotes: TimeOffObj.Notes,
            Status: status,
            Response: TimeOffObj.ResponseNotes,
            TimeOffID: TimeOffObj.TimeOffID
        }

        const _userEmail = AllUsers.filter(user => user.FullName === TimeOffObj.FullName)[0].Email;
        
        if(status !== "") {
            socket.emit('updateEmpTimeOffRequest', objectToInsert, (boolean) => {
                if (boolean) {
                    // Send Emails
                    SendEmail([_userEmail,'brian@registration-technology.com'], objectToInsert.Status, objectToInsert.Response, objectToInsert.StartDate, objectToInsert.ReturnDate, objectToInsert.PrevNotes);

                    // Close Modal
                    (document.getElementById('modal_timeoff')).style.display = "none";
                    (document.getElementById('myModal')).style.display = "none";

                    setSnackbar(prevState => ({
                        ...prevState,
                        visible: true,
                        message: "Time off response recorded. Emails will be sent shortly.",
                        severity: 'success' 
                    }))
    
                    RefreshData();
                }
                else {//Notify user of Failure
                    setSnackbar(prevState => ({
                        ...prevState,
                        visible: true,
                        message: "Error recording time off response.",
                        severity: 'error' 
                    }))
                }
            });
        }
    }

    function SendEmail(userEmail, Status, Response, StartDate, ReturnDate, PrevNotes) {
        let to = (userEmail);
        let text =
            `<div width="100%",style="text-align:center;">` +
            `<h1 style="text-align:center;">Time Off Request Response</h1>` +
            `<table style="background-color:black; width: 400px;">` +
                `<tbody>` +
                `<tr><td style="background-color:bisque; width: 100px;">Employee</td><td style="background-color:white;">${TimeOffObj.FullName}</td></tr>` +
                `<tr><td style="background-color:bisque;">Requested Start Date</td><td style="background-color:white;">${StartDate}</td></tr>` +
                `<tr><td style="background-color:bisque;">Requested Return Date</td><td style="background-color:white;">${ReturnDate}</td></tr>` +
                `<tr><td style="background-color:bisque;">Request Notes</td><td style="background-color:white;">${PrevNotes}</td></tr>` +
                `<tr><td style="background-color:bisque;">Request Status</td><td style="background-color:white;">${Status}</td></tr>` +
                `<tr><td style="background-color:bisque;">Response</td><td style="background-color:white;">${Response}</td></tr>` +
                `</tbody>` +
            `</table >` +
            `</div >`;
            
        $.get("/send", { to: to, subject: `RTI Time Off Request Response- ${TimeOffObj.FullName}`, text: text }, (res) => {});
    }

    const ApproveHandler = (e) => {
        const checked = e.target.checked;
        setTimeOffObj(prevState => ({...prevState,CheckApprove: checked}));
    }

    const RejectHandler = (e) => {
        const checked = e.target.checked;
        setTimeOffObj(prevState => ({...prevState,CheckReject: checked}));
    }

    const ResponseHandler = (e) => {
        const value = e.target.value;
        setTimeOffObj(prevState => ({...prevState,ResponseNotes:value}));
    }

    function RenderTimeOffModal(){
        return (
            <div className="modal" id="myModal" >
                <div className="modal-content" id="modal_timeoff">
                    <div className="DispatcherForm">

                        <div className="Modal-Background" id="cancel-request-modal" style={{zIndex: '3', display: 'none', textAlign: 'center'}}>
                            <div className="Delete-Modal">
                                <div className="Delete-Content">
                                    <div id="warning" style={{color: 'red', fontSize: '100px'}}>
                                        !
                                    </div>
                                    <div id="warning-prompt" >
                                        <p style={{fontSize: '20px'}} ><b>Are you sure you want to cancel this request for {TimeOffObj.FullName} on {TimeOffObj.StartDate}?</b></p>
                                        <p style={{marginBottom: '16%'}}>(This action will remove the request from the Time Off Calendar)</p>
                                    </div>
                                    <div>
                                        <button style={{marginRight: '12%'}} className="RTIButtonInv1" onClick={NoSelectedHandler}>No</button>
                                        <button className="RTIButtonInv1" onClick={YesSelectedHandler}>Yes</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="FirstLayer">
                            <label>Time Off Request For:</label>
                            <p><b>{TimeOffObj.FullName}</b></p>
                            <br />
                            <label>Time Off Start Date:</label>
                            <p><b>{TimeOffObj.StartDate}</b></p>
                            <br />
                            <label>Time Off Return Date:</label>
                            <p><b>{TimeOffObj.ReturnDate}</b></p>
                            <br />
                            <label>Provided Notes:</label>
                            <p style={{width: "418px"}}><b>{TimeOffObj.Notes}</b></p>
                        </div>

                        <div className="SecondLayer" >
                            <label>Approve</label>
                            <input type="checkbox" id="approveCheckbox" checked={TimeOffObj.CheckApprove} disabled={TimeOffObj.DisableOptions} onChange={ApproveHandler}></input>
                            <br />
                            <label>Reject </label>
                            <input type="checkbox" id="rejectCheckbox" checked={TimeOffObj.CheckReject} disabled={TimeOffObj.DisableOptions} onChange={RejectHandler}></input>
                            <br />
                            <br />
                            <label>Your Notes:</label>
                            <br />
                            <textarea className="NotesInput" id="NotesInput" value={TimeOffObj.ResponseNotes} style={{width: '100%', height: '75px'}} onChange={ResponseHandler}></textarea>
                        </div>

                        <div className='flex-wrap flex-just-between'>
                            <button className="RTIButton" id="DISPFormClose" onClick={CloseButton}>CLOSE</button>
                            <button className="RTIButton" id="DISPFormClose" onClick={CancelRequest}>CANCEL REQUEST</button>
                            <button className="RTIButton" id="DISPFormClose" onClick={RemoveRequest}>REMOVE REQUEST</button>
                            <button className="RTIButton" id="DISPFormSave" onClick={SaveButton}>SAVE</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    const RemoveRequest = () => {
        const res = prompt(`You are about to remove this request from the calendar.  If you want to remove this request, then type DELETE in the inbox below, otherwise click CANCEL.`)
        if(res === 'DELETE'){
            const objectToInsert = {
                StartDate: TimeOffObj.StartDate,
                ReturnDate: TimeOffObj.ReturnDate,
                PrevNotes: TimeOffObj.Notes,
                Status: "Deleted",
                Response: TimeOffObj.ResponseNotes,
                TimeOffID: TimeOffObj.TimeOffID
            }

            socket.emit('updateEmpTimeOffRequest', objectToInsert, (boolean) => {
                if (boolean) {
                    // Close Modal
                    (document.getElementById('modal_timeoff')).style.display = "none";
                    (document.getElementById('myModal')).style.display = "none";

                    setSnackbar(prevState => ({
                        ...prevState,
                        visible: true,
                        message: "Success!  Time off request removed.",
                        severity: 'success' 
                    }))
    
                    RefreshData();
                }
                else {//Notify user of Failure
                    setSnackbar(prevState => ({
                        ...prevState,
                        visible: true,
                        message: "Error removing this time off request.",
                        severity: 'error' 
                    }))
                }
            });
        }
    }

    const getTableData = () => {
        const date = new Date();
        date.setMonth(date.getMonth() - 12);

        return Calendar
        .map(calendar => {
            return userState.user.permissions === env.USER_PERMS[0] ? {
                key: calendar.TimeOffID,
                cells: [
                    {data: calendar.Status},
                    {data: calendar.FullName},
                    {data: calendar.StartDate},
                    {data: calendar.ReturnDate},
                    {data: calendar.Notes}                  
                ]
            } :
            {
                key: calendar.TimeOffID,
                cells: [
                    {data: calendar.Status},
                    {data: calendar.FullName},
                    {data: calendar.StartDate},
                    {data: calendar.ReturnDate}               
                ]
            } 
        })
        .filter(row => row.cells[0].data != 'Cancelled' && row.cells[0].data != 'Deleted')
        .filter(row => date.getTime() <= new Date(row.cells[2].data).getTime() && row.cells[0].data != 'Cancelled')
        .sort((a,b) => new Date(b.cells[2].data.split(' ')[0]).getTime() - new Date(a.cells[2].data.split(' ')[0]).getTime())
    }

    const renderTableButtons = () => {
        return userState.user.permissions === env.USER_PERMS[0] ? <div style={{display: 'flex'}}>
            <CSVLink style={{ background: 'none', border: 'none' }} target="_blank" className="hidden" id="table-download" data={downloadData()} filename={"time-off-calendar.csv"} ></CSVLink>
            <Button
                className="rti-blue-round"
                variant="contained"
                startIcon={<MdGetApp />}
                onClick={() => document.getElementById('table-download').click()}>Download</Button>
        </div> : null;
    }

    const downloadData = () => {
        return Calendar.map(calendar => ({
            "Name": calendar.FullName,
            "Start": calendar.StartDate,
            "Return": calendar.ReturnDate,
            "Notes": calendar.Notes,
            "Status": calendar.Status
        }))
        .filter(data => !data.Start.includes('undefined'))
        .sort((a,b) => new Date(b.Start.split(' ')[0]).getTime() - new Date(a.Start.split(' ')[0]).getTime());
    }

    const handleCloseSnack = () => {
        setSnackbar(prevState => ({
            ...prevState,
            visible: false
        }))
    }

    return(
        <div className="ViewedContentContainer" id="OpenContainer" >
            <div style={{height: '100%'}}>
                {RenderTimeOffModal()}

                <div className="TitleBarContainer">
                    <h1 title="Time Off Requests">Time Off Requests</h1>
                </div>
                <AlertBar
                    visible={snackbar.visible}
                    message={snackbar.message}
                    severity={snackbar.severity}
                    onClose={handleCloseSnack}
                />
                <CustomTable
                    paginate
                    loading_data={Loading}
                    headers={userState.user.permissions === env.USER_PERMS[0] ? TableHeaders : TableHeadersHidden}
                    rows={getTableData()}
                    onClick={RowClick}
                    table_buttons={renderTableButtons()}
                />
            </div>
        </div>
    )
}

export default TimeOffCalendar;