// TODO: Update the other buttons to MUI buttons for subtracting and adding techs
// TODO: Fix the save button, re-write the function for saving.
// TODO: Add loading into the modal so that if a process is happening you cannot interact
// TODO: Find all SQL calls and move them to a stored procedure.
// TODO: Add in a useEffect for removing or adding items to the lists, this will make it so that it will remove the items immediately instead of waiting for another click.
import React, { useState, useEffect } from 'react';
import '../../StyleSheets/TMmodal.css';
import {
	FormatDateFromYear2,
	CheckJSON,
} from '../../imports/API/TextFormatingFunctions.js';
import Spinner from '../../components/Spinner';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { AddressField } from '../../components/AddressField.js';
import IconButton from '@mui/material/IconButton';
import { MdAdd } from 'react-icons/md';
import { MdRemove } from 'react-icons/md';

export const KioskModal = (props) => {
	const kioskKeyExists = !!props.kioskKey;
	const [kioskID, setKioskID] = useState(kioskKeyExists ? props.kioskModal.KioskID : '');
	const [kioskID_Loc, setKioskID_Loc] = useState(kioskKeyExists ? props.kioskModal.KioskID_Loc : '');
	const [kioskInstallDate, setKioskInstallDate] = useState(
		FormatDateFromYear2(
			kioskKeyExists && props.kioskModal.KioskInstallDate ? props.kioskModal.KioskInstallDate : new Date()
		)
	);
	const [storeHours, setStoreHours] = useState(kioskKeyExists ? JSON.parse(props.kioskModal.StoreHours) : []);
	const [storeHoursValue, setStoreHoursValue] = useState('');
	const [storeHoursInput, setStoreHoursInput] = useState('');
	const [storeName, setStoreName] = useState(kioskKeyExists ? props.kioskModal.StoreName : '');
	const [storePhone, setStorePhone] = useState(kioskKeyExists ? props.kioskModal.StorePhone : '');
	const [storeAddress, setStoreAddress] = useState(kioskKeyExists ? props.kioskModal.StoreAddress : '');
	const [storeCounty, setStoreCounty] = useState(kioskKeyExists ? props.kioskModal.StoreCounty : '');
	const [storeCity, setStoreCity] = useState(kioskKeyExists ? props.kioskModal.StoreCity : '');
	const [storeState, setStoreState] = useState(kioskKeyExists ? props.kioskModal.StoreState : '');
	const [storeZip, setStoreZip] = useState(kioskKeyExists ? props.kioskModal.StoreZip : '');
	const [hasSwitch, setHasSwitch] = useState(kioskKeyExists ? props.kioskModal.has_switch : false);
	const [paperBridge, setPaperBridge] = useState(kioskKeyExists ? props.kioskModal.paper_bridge : false);
	const [starFirmware, setStarFirmware] = useState(
		kioskKeyExists && props.kioskModal.star_firmware ? formatDate(props.kioskModal.star_firmware) : ''
	);

	// CREATED_DATE: 24SEP20
	const [skiSlope, setSkiSlope] = useState(
		kioskKeyExists && props.kioskModal.ski_slope ? formatDate(props.kioskModal.ski_slope) : ''
	);

	// CREATED_DATE: 24SEP24
	const [osInstall, setOsInstall] = useState(
		kioskKeyExists && props.kioskModal.os_installed ? formatDate(props.kioskModal.os_installed) : ''
	);

	const [lastCleaned, setLastCleaned] = useState(
		kioskKeyExists && props.kioskModal.cleaned ? formatDate(props.kioskModal.cleaned) : ''
	);
	const [technicianValue, setTechnicianValue] = useState('');
	const [primaryTechsInput, setPrimaryTechsInput] = useState('');
	const [primaryTechs, setPrimaryTechs] = useState(kioskKeyExists ? JSON.parse(props.kioskModal.PrimaryTechs) : []);
	const [primaryTechValue, setPrimaryTechValue] = useState('');
	const [backupTechsInput, setBackupTechsInput] = useState('');
	const [backupTechs, setBackupTechs] = useState(kioskKeyExists ? JSON.parse(props.kioskModal.BackupTechs) : []);
	const [backupTechValue, setBackupTechValue] = useState('');
	const [paperTechInput, setPaperTechInput] = useState('');
	const [paperTechValue, setPaperTechValue] = useState('');
	const [paperTech, setPaperTech] = useState(kioskKeyExists ? props.kioskModal.PaperChanger : '');
	const [serverID, setServerID] = useState(kioskKeyExists ? props.kioskModal.ServerID : '');
	const [modemType, setModemType] = useState(kioskKeyExists ? props.kioskModal.ModemType : '');
	const [carrier, setCarrier] = useState(kioskKeyExists ? props.kioskModal.Carrier : '');
	const [carrierNumber, setCarrierNumber] = useState(kioskKeyExists ? props.kioskModal.CarrierNumber : '');
	const [notes, setNotes] = useState(kioskKeyExists ? props.kioskModal.Notes : '');
	const [notesTimestamp, setNotesTimestamp] = useState(kioskKeyExists ? props.kioskModal.NotesTimestamp : '');
	const [activeOrInactive, setActiveOrInactive] = useState(kioskKeyExists ? props.kioskModal.ActiveOrInactive : '');
	const [numberOfStarRolls, setNumberOfStarRolls] = useState(kioskKeyExists ? props.kioskModal.NumberOfStarRolls : '');
	const [techDriveTimeList, setTechDriveTimeList] = useState([]);
	const [kioskOld, setKioskOld] = useState(kioskKeyExists ? props.kioskModal : {});
	const [techs, setTechs] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			socket.emit('selectAllFromTechnicians', (result) => {
				setTechs(result);
			});
			setLoading(false);
			// props.kioskModal ? setLoading(false) : setLoading(true);
		}
		return () => {
			mounted = false;
		};
	}, []);

	/**
	 * Formats a timestamp into a string representation of a date in the format "YYYY-MM-DD".
	 *
	 * @param {number} timestamp - The timestamp to be formatted.
	 * @returns {string} The formatted date string.
	 */
	function formatDate(timestamp) {
		let date = new Date(timestamp);
		let year = date.getUTCFullYear();
		let month = ('0' + (date.getUTCMonth() + 1)).slice(-2); // Months are 0-based in JS
		let day = ('0' + date.getUTCDate()).slice(-2);

		let formattedDate = `${year}-${month}-${day}`;

		return formattedDate;
	}

	/**
	 * Handles the onChange event for the KioskModal component.
	 *
	 * @param {Event} e - The event object.
	 * @param {string} whichValue - The value indicating which field is being changed.
	 * @returns {void}
	 */
	function onChange(e, whichValue) {
		switch (whichValue) {
			case 'KioskID':
				setKioskID(e.target.value);
				break;
			case 'KioskInstallDate':
				setKioskInstallDate(e.target.value);
				break;
			case 'StoreHours':
				setStoreHours(e.target.value);
				break;
			case 'StoreName':
				setStoreName(e.target.value);
				break;
			case 'StorePhone':
				setStorePhone(e.target.value);
				break;
			case 'StoreAddress':
				setStoreAddress(e.target.value);
				break;
			case 'StoreCounty':
				setStoreCounty(e.target.value);
				break;
			case 'StoreCity':
				setStoreCity(e.target.value);
				break;
			case 'StoreState':
				setStoreState(e.target.value);
				break;
			case 'StoreZip':
				setStoreZip(e.target.value);
				break;
			case 'PrimaryTechsInput':
				setPrimaryTechsInput(e.target.value);
				break;
			case 'PrimaryTechs':
				setPrimaryTechs(e.target.value);
				break;
			case 'BackupTechsInput':
				setBackupTechsInput(e.target.value);
				break;
			case 'BackupTechs':
				setBackupTechs(e.target.value);
				break;
			case 'PaperTechInput':
				setPaperTechInput(e.target.value);
				break;
			case 'PaperTech':
				setPaperTech(e.target.value);
				break;
			case 'ServerID':
				setServerID(e.target.value);
				break;
			case 'ModemType':
				setModemType(e.target.value);
				break;
			case 'Carrier':
				setCarrier(e.target.value);
				break;
			case 'CarrierNumber':
				setCarrierNumber(e.target.value);
				break;
			case 'KioskNotes':
				setNotes(e.target.value);
				break;
			case 'ActiveOrInactive':
				setActiveOrInactive(e.target.value);
				break;
			case 'NumberOfStarRolls':
				setNumberOfStarRolls(e.target.value);
		}
	}

	/**
	 * Calculates the drive time for each tech and sorts them in ascending order.
	 */
	const techAndDriveTime = () => {
		// Gets all Techs for this specific kiosk
		let driveTime = [];

		driveTime = props.driveTimes;

		// Grabs the techname and drivetime from the filtered array
		// Has to be in string form to go through RenderList()
		// Convert to get total number of Minutes
		function getTechDriveTime(id) {
			let hours = 0,
				mins = 0,
				total = 0;

			let totalMins = id.DriveTime.split(' ');
			if (totalMins.length > 2) {
				hours = parseInt(totalMins[0] * 60);
				mins = parseInt(totalMins[2]);
				total = hours + mins;
			} else {
				mins = parseInt(totalMins[0]);
				total = mins;
			}
			const techDriveTime = { Name: id.TechName, Time: total };

			return techDriveTime;
		}

		// maps the tech array to a new array of objects for the fields we want
		let timefilterdArray = driveTime.map(getTechDriveTime);

		// Sort Techs by Drive Time ASC
		timefilterdArray.sort(function (a, b) {
			if (a.Time < b.Time) {
				return -1;
			}
			if (b.Time > a.Time) {
				return 1;
			}
			return 0;
		});

		function getNewTimes(id) {
			const driveTime = id.Name + ', ' + id.Time + ' mins';
			return driveTime;
		}

		const result = timefilterdArray.map(getNewTimes);

		setTechDriveTimeList(result);
	};

	/**
	 * Returns the technician's name from the given value.
	 *
	 * @param {string} value - The value containing the technician's name in the format "First Name, 00 mins".
	 * @returns {string} The technician's name.
	 */
	const GetTechName = (value) => {
		value = value.replace(/,/g, ''); // remove comma from format "First Name, 00 mins"
		value = value.split(' '); // seperate fields
		value = value[0] + ' ' + value[1]; // get first and last name

		return value;
	};

	/**
	 * Handles the display logic for different cases in the KioskModal.
	 *
	 * @param {Event} e - The event object.
	 */
	const DisplayList = (e) => {
		switch (e.target.className) {
			//Displays the Technician List
			case 'PrimaryTechNameInput':
				//Move
				document.getElementById('TablePrimaryTechnicianList').style.display = 'block';
				document.getElementById('TableBackupTechnicianList').style.display = 'none';
				document.getElementById('TablePaperTechnicianList').style.display = 'none';
				break;

			//Sets the Text in the Dispatch To Input Box to the name of the item selected in the Technician List
			case 'PrimaryTechnicianList':
				setPrimaryTechsInput(GetTechName(e.target.id));
				document.getElementById('TablePrimaryTechnicianList').style.display = 'none';
				document.getElementById('TableBackupTechnicianList').style.display = 'none';
				document.getElementById('TablePaperTechnicianList').style.display = 'none';
				break;

			//Displays the Technician List
			case 'BackupTechNameInput':
				//Move
				document.getElementById('TablePrimaryTechnicianList').style.display = 'none';
				document.getElementById('TableBackupTechnicianList').style.display = 'block';
				document.getElementById('TablePaperTechnicianList').style.display = 'none';
				setBackupTechsInput(GetTechName(e.target.id));
				// TODO: Figure out how to set the tech list differently
				// setState({ SelectedTechnicianList: 'BackupTechs' });
				break;

			//Sets the Text in the Dispatch To Input Box to the name of the item selected in the Technician List
			case 'BackupTechnicianList':
				setBackupTechsInput(GetTechName(e.target.id));
				document.getElementById('TablePrimaryTechnicianList').style.display = 'none';
				document.getElementById('TableBackupTechnicianList').style.display = 'none';
				document.getElementById('TablePaperTechnicianList').style.display = 'none';
				break;

			//Displays the Technician List
			case 'PaperTechNameInput':
				//Move
				document.getElementById('TablePrimaryTechnicianList').style.display = 'none';
				document.getElementById('TableBackupTechnicianList').style.display = 'none';
				document.getElementById('TablePaperTechnicianList').style.display = 'block';
				// TODO: Figure out how to set the tech list differently
				// setState({ SelectedTechnicianList: 'PaperTech' });
				break;

			//Sets the Text in the Dispatch To Input Box to the name of the item selected in the Technician List
			case 'PaperTechnicianList':
				// TODO: Figure out how to set the tech list differently
				setPaperTechInput(GetTechName(e.target.id));
				// setState({ PaperTechInput: GetTechName(e.target.id) });
				document.getElementById('TablePrimaryTechnicianList').style.display = 'none';
				document.getElementById('TableBackupTechnicianList').style.display = 'none';
				document.getElementById('TablePaperTechnicianList').style.display = 'none';
				break;

			//Makes the Background for a selected Part in the List Grey
			case 'StoreHoursList':
				setStoreHoursValue(e.target.id);
				// setState({ StoreHoursValue: e.target.id });
				let hoursListArray = document.getElementsByClassName('StoreHoursList');
				for (let j = 0; j < hoursListArray.length; j++) {
					hoursListArray[j].style.backgroundColor = '#ffffff';
				}
				document.getElementById(e.target.id).style.backgroundColor = '#808080';

				break;

			//Makes the Background for a selected Part in the List Grey
			case 'PrimaryTechsList':
				// setState({ PrimaryTechValue: e.target.id });
				setPrimaryTechValue(e.target.id);
				let primaryListArray = document.getElementsByClassName('PrimaryTechsList');
				for (let j = 0; j < primaryListArray.length; j++) {
					primaryListArray[j].style.backgroundColor = '#ffffff';
				}
				document.getElementById(e.target.id).style.backgroundColor = '#808080';
				break;

			//Makes the Background for a selected Part in the List Grey
			case 'BackupTechsList':
				// setState({ BackupTechValue: e.target.id });
				setBackupTechValue(e.target.id);
				let backupListArray = document.getElementsByClassName('BackupTechsList');
				for (let j = 0; j < backupListArray.length; j++) {
					backupListArray[j].style.backgroundColor = '#ffffff';
				}
				document.getElementById(e.target.id).style.backgroundColor = '#808080';

				break;

			//Makes the Background for a selected Part in the List Grey
			case 'PaperTechList':
				// setState({ PaperTechValue: e.target.id });
				setPaperTechValue(e.target.id);
				let paperListArray = document.getElementsByClassName('PaperTechList');
				for (let j = 0; j < paperListArray.length; j++) {
					paperListArray[j].style.backgroundColor = '#ffffff';
				}
				document.getElementById(e.target.id).style.backgroundColor = '#808080';

				break;
		}
	};

	//Reactive Table List that will only Display a list that matches string from the input
	/**
	 * Renders a list based on the passed value and array.
	 *
	 * @param {string} passedValue - The value to filter the array.
	 * @param {Array<string>} passedArray - The array to filter.
	 * @param {string} passedListClassName - The class name for the list items.
	 * @param {string} passedTableListClassName - The class name for the unordered list.
	 * @returns {React.Element} - The rendered unordered list.
	 */
	const RenderList = (passedValue, passedArray, passedListClassName, passedTableListClassName) => {
		//Filters the passedArray so the only values saved in filteredArray are the ones that partially match the passedValue
		let value = '',
			filteredArray = [];
		if (passedArray) {
			for (let j = 0; j < passedArray.length; ++j) {
				value = passedArray[j];
				if (value.toLowerCase().includes(passedValue.toLowerCase())) {
					filteredArray.push(value.replace(/"/g, ''));
				}
			}
		}

		//Saves the filteredArray into the array that will display as a list under the text inputs
		let returnArray = [];
		if (filteredArray) {
			for (let i = 0; i < filteredArray.length; i++) {
				returnArray.push(
					<li
						key={i}
						className={passedListClassName}
						id={filteredArray[i]}
						value={filteredArray[i]}
						onClick={DisplayList}
					>
						{filteredArray[i]}
					</li>
				);
			}
		}

		//Returns an Unordered List
		return (
			<ul className={passedTableListClassName} id={passedTableListClassName}>
				{returnArray}
			</ul>
		);
	};

	//Adds the Current Item in Select to the Parts List
	/**
	 * Function that handles the logic for adding a technician in the KioskModal.
	 *
	 * @param {Event} e - The event object.
	 * @returns {boolean} - Returns false if the selected value already exists in the corresponding array, otherwise returns undefined.
	 */
	const PlusTechButton = (e) => {
		document.getElementById('TablePrimaryTechnicianList').style.display = 'none';
		document.getElementById('TableBackupTechnicianList').style.display = 'none';
		document.getElementById('TablePaperTechnicianList').style.display = 'none';
		if (e.currentTarget.id === 'StoreHoursPlusButton' && document.getElementById('StoreHoursInput').value) {
			let StoreHours = storeHours.slice();
			let selectValue = document.getElementById('StoreHoursInput').value;
			if (StoreHours.indexOf(selectValue) > -1) {
				return false;
			}
			StoreHours.push(selectValue);
			setStoreHours(StoreHours);
			// setState({ StoreHours: StoreHours });
		} else if (e.currentTarget.id === 'PrimPlusTechButton' && document.getElementById('PrimaryTechNameInput').value) {
			let PrimaryTechs = primaryTechs.slice();
			let selectValue = document.getElementById('PrimaryTechNameInput').value;
			if (PrimaryTechs.indexOf(selectValue) > -1) {
				return false;
			}

			PrimaryTechs.push(selectValue);
			setPrimaryTechs(PrimaryTechs);
			// setState({ PrimaryTechs: PrimaryTechs });

			document.getElementById('PrimaryTechNameInput').value = '';
		} else if (e.currentTarget.id === 'BackPlusTechButton' && document.getElementById('BackupTechNameInput').value) {
			let BackupTechs = backupTechs.slice();
			let selectValue = document.getElementById('BackupTechNameInput').value;
			if (BackupTechs.indexOf(selectValue) > -1) {
				return false;
			}

			BackupTechs.push(selectValue);
			setBackupTechs(BackupTechs);

			// setState({ BackupTechs: BackupTechs });
			document.getElementById('BackupTechNameInput').value = '';
		} else if (e.currentTarget.id === 'PapPlusTechButton' && document.getElementById('PaperTechNameInput').value) {
			let selectValue = document.getElementById('PaperTechNameInput').value;

			setPaperTech(selectValue);

			// setState({ PaperTech: selectValue });
			document.getElementById('PaperTechNameInput').value = '';
		}
	};

	//When a Parts List Item is Selected (Grey) Clicking the Minus Button Removes it From the List
	/**
	 * Function that handles the minus button click event for the technician lists.
	 * @param {Event} e - The click event object.
	 */
	const MinusTechButton = (e) => {
		document.getElementById('TablePrimaryTechnicianList').style.display = 'none';
		document.getElementById('TableBackupTechnicianList').style.display = 'none';
		document.getElementById('TablePaperTechnicianList').style.display = 'none';
		if (e.currentTarget.id === 'StoreHoursMinusButton') {
			let StoreHours = storeHours.slice();
			let search_term = storeHoursValue;
			let partsListArray = document.getElementsByClassName('StoreHoursList');

			for (let i = 0; i < StoreHours.length; i++) {
				if (StoreHours[i] === search_term) {
					StoreHours.splice(i, 1);
					partsListArray[i].style.backgroundColor = '#ffffff';
				}
			}

			// setState({ StoreHours: StoreHours });
			setStoreHours(StoreHours);
		} else if (e.currentTarget.id === 'PrimMinusTechButton') {
			let PrimaryTechs = primaryTechs.slice();
			let search_term = primaryTechValue;
			let partsListArray = document.getElementsByClassName('PrimaryTechsList');
			for (let i = 0; i < PrimaryTechs.length; i++) {
				if (PrimaryTechs[i] === search_term) {
					PrimaryTechs.splice(i, 1);
					partsListArray[i].style.backgroundColor = '#ffffff';
				}
			}

			// setState({ PrimaryTechs: PrimaryTechs });
			setPrimaryTechs(PrimaryTechs);
		} else if (e.currentTarget.id === 'BackMinusTechButton') {
			let BackupTechs = backupTechs.slice();
			let search_term = backupTechValue;
			let partsListArray = document.getElementsByClassName('BackupTechsList');

			for (let i = 0; i < BackupTechs.length; i++) {
				if (BackupTechs[i] === search_term) {
					BackupTechs.splice(i, 1);
					partsListArray[i].style.backgroundColor = '#ffffff';
				}
			}

			// setState({ BackupTechs: BackupTechs });
			setBackupTechs(BackupTechs);
		} else if (e.currentTarget.id === 'PapMinusTechButton') {
			//(document.getElementsByClassName('PaperTechList')).style.backgroundColor = "#ffffff";
			// setState({ PaperTech: '' });
			setPaperTech('');
		}
	};

	/**
	 * Function that handles the deletion of a Kiosk.
	 * Prompts the user to confirm the deletion and emits a socket event to delete the Kiosk.
	 * If the deletion is successful, it refreshes the data and hides the modal.
	 * If the deletion fails, it displays an alert message.
	 */
	const KioskDeleteButton = () => {
		let deleteJobConfirm = prompt("Please enter 'DELETE' to confirm deletion of this Kiosk.", '');
		if (deleteJobConfirm) {
			if (deleteJobConfirm.toLowerCase() === 'delete') {
				let KioskID = KioskID;
				socket.emit('deleteKioskForTM', KioskID, (boolean) => {
					if (boolean) {
						props.refresh_data();
						document.getElementById('modal_kiosks').style.display = 'none';
						document.getElementById('myModal').style.display = 'none';
					} else {
						alert('Kiosk was not deleted.');
					}
				});
			}
		}
	};

	/**
	 * Function to handle the closing of the Kiosk modal.
	 */
	const KioskCloseButton = () => {
		document.getElementById('modal_kiosks').style.display = 'none';
		document.getElementById('myModal').style.display = 'none';
		props.close_modal();
	};

	// TODO: Make this function less repetative. This needs to update the values in the DB with the values being entered
	// Only update the DB if the values are different than the state values
	/**
	 * Saves the changes made to the kiosk information.
	 * If the kiosk information has changed or is being entered for the first time, it archives the original information and updates the kiosk.
	 * If the address has changed, it updates the tech drive times and kiosk coordinates.
	 *
	 * @async
	 * @function KioskSaveButton
	 * @returns {void}
	 */
	const KioskSaveButton = async () => {
		let oldStatus = kioskOld.ActiveOrInactive;
		let newStatus = activeOrInactive;

		let changed = false;
		const Constructed_KioskID_Loc = kioskID + '_' + serverID.trim().replace(/ /g, '').replace(/-/g, '_');
		// const Constructed_KioskID_Loc = props.KioskID + '_' + props.ServerID.trim().replace(/ /g, '').replace(/-/g, '_');
		let oldObj = {
			// old kiosk information to compare to
			KioskID: kioskOld.KioskID,
			KioskID_Loc: props.KioskID_Loc,
			KioskInstallDate: FormatDateFromYear2(kioskInstallDate ? kioskInstallDate : new Date()),
			StoreName: kioskOld.StoreName,
			StoreHours: kioskOld.StoreHours,
			StorePhone: kioskOld.StorePhone,
			StoreAddress: kioskOld.StoreAddress,
			StoreCity: kioskOld.StoreCity,
			StoreState: kioskOld.StoreState,
			StoreZip: kioskOld.StoreZip,
			StoreCounty: kioskOld.StoreCounty,
			ServerID: kioskOld.ServerID,
			ModemType: kioskOld.ModemType,
			Carrier: kioskOld.Carrier,
			CarrierNumber: kioskOld.CarrierNumber,
			Notes: CheckJSON(kioskOld.Notes),
			ActiveOrInactive: kioskOld.ActiveOrInactive,
			NumberOfStarRolls: kioskOld.NumberOfStarRolls,
			RawAddress: `${kioskOld.StoreAddress} ${kioskOld.StoreCity} ${kioskOld.StoreState} ${kioskOld.StoreZip}`,
		};
		let compareObj = {
			// copy of new kiosk information
			KioskID: kioskID,
			KioskID_Loc: Constructed_KioskID_Loc,
			KioskInstallDate: FormatDateFromYear2(kioskInstallDate ? kioskInstallDate : new Date()),
			StoreName: storeName,
			StoreHours: storeHours,
			StorePhone: storePhone,
			StoreAddress: storeAddress,
			StoreCity: storeCity,
			StoreState: storeState,
			StoreZip: storeZip,
			StoreCounty: storeCounty,
			ServerID: serverID,
			ModemType: modemType,
			Carrier: carrier,
			CarrierNumber: carrierNumber,
			PrimaryTechs: FormatTechs(primaryTechs),
			BackupTechs: FormatTechs(backupTechs),
			PaperChanger: paperTech,
			Notes: notes,
			RestartSwitch: document.getElementById('restart-switch').checked ? 1 : 0,
			PaperBridge: document.getElementById('paper-bridge').checked ? 1 : 0,
			Cleaned: document.getElementById('cleaned').value,
			StarFirmware: document.getElementById('star-firmware').value,
			SkiSlope: document.getElementById('ski-slope').value,
			OSInstall: document.getElementById('os-installed').value,
			//NotesTimestamp: NotesTimestamp,
			ActiveOrInactive: activeOrInactive,
			NumberOfStarRolls: numberOfStarRolls,
			RawAddress: `${JSON.stringify(storeAddress)} ${JSON.stringify(storeCity)} ${JSON.stringify(
				storeState
			)} ${JSON.stringify(storeZip)}`,
		};

		let obj = {
			// new information to push
			KioskID: kioskID ? kioskID : '',
			KioskID_Loc: Constructed_KioskID_Loc,
			KioskInstallDate: FormatDateFromYear2(kioskInstallDate ? kioskInstallDate : new Date()),
			StoreName: storeName,
			StoreHours: storeHours,
			StorePhone: storePhone,
			StoreAddress: storeAddress,
			StoreCity: storeCity,
			StoreState: storeState,
			StoreZip: storeZip,
			StoreCounty: storeCounty,
			ServerID: serverID,
			ModemType: modemType,
			Carrier: carrier,
			CarrierNumber: carrierNumber,
			PrimaryTechs: FormatTechs(primaryTechs),
			BackupTechs: FormatTechs(backupTechs),
			PaperChanger: paperTech,
			Notes: notes,
			RestartSwitch: document.getElementById('restart-switch').checked ? 1 : 0,
			PaperBridge: document.getElementById('paper-bridge').checked ? 1 : 0,
			Cleaned: document.getElementById('cleaned').value,
			StarFirmware: document.getElementById('star-firmware').value,
			SkiSlope: document.getElementById('ski-slope').value,
			OSInstall: document.getElementById('os-installed').value,
			//NotesTimestamp: NotesTimestamp,
			ActiveOrInactive: activeOrInactive,
			NumberOfStarRolls: numberOfStarRolls,
			RawAddress: `${JSON.stringify(storeAddress)} ${JSON.stringify(storeCity)} ${JSON.stringify(
				storeState
			)} ${JSON.stringify(storeZip)}`,
		};
		// get values from both objects
		let temp1 = Object.values(oldObj);
		let temp2 = Object.values(compareObj);
		const kiosk_address = `${obj.StoreAddress} ${obj.StoreCity} ${obj.StoreState} ${obj.StoreZip}`;

		for (let i = 0; i < temp1.length; i++) {
			if (temp1[i] !== temp2[i]) {
				// compare object values to see what has changed
				changed = true; // kiosk info has changed, create archive
			}
		}

		const valuesToInsert = !kioskID_Loc || changed ? obj : oldObj;
		if (changed || !kioskID_Loc) {
			// if kiosk info has changed, or is being entered for the first time, archive the original info
			socket.emit('archiveKioskForTM', valuesToInsert, async (boolean) => {
				if (!boolean) {
					alert('Couldnt archive this kiosk');
				} else {
					if (!kioskID_Loc.trim()) {
						KioskAddButton();
					} else {
						socket.emit('updateKioskForTM', valuesToInsert, async (boolean) => {
							try {
								if (boolean) {
									if (valuesToInsert.RawAddress.toUpperCase() !== compareObj.RawAddress.toUpperCase()) {
										// did the address change?  Update tech drive times and kiosk coordinates
										UpdateTechsAndCoordinates(kiosk_address, obj.KioskID, 'update');

										props.refresh_data();
										document.getElementById('modal_kiosks').style.display = 'none';
										document.getElementById('myModal').style.display = 'none';
									} else {
										props.refresh_data();
										document.getElementById('modal_kiosks').style.display = 'none';
										document.getElementById('myModal').style.display = 'none';
									}
								} else {
									//Notify user of Failure
									alert('You did not fill the form out correctly');
								}
							} catch (error) {
								console.error('ERROR: KioskModal.updateKioskForTM', error);
							}
						});
					}
				}
			});
		} else {
			alert('No Changes Detected! Please review your changes and try again.');
		}
	};

	/**
	 * Formats a list of strings by removing double quotes.
	 *
	 * @param {string[]} _list - The list of strings to be formatted.
	 * @returns {string[]} - The formatted list of strings.
	 */
	const FormatTechs = (_list) => {
		const final = _list.map((el) => el.replace(/"/g, ''));
		return final;
	};

	/**
	 * Updates the technicians and coordinates for a given kiosk.
	 *
	 * @param {string} kiosk_address - The address of the kiosk.
	 * @param {string} kiosk_id - The ID of the kiosk.
	 * @param {string} KioskID_Loc - The location of the kiosk ID.
	 * @param {string} type - The type of the kiosk.
	 */
	const UpdateTechsAndCoordinates = (kiosk_address, kiosk_id, KioskID_Loc, type) => {
		$.post(`/api/geocode_address`, { address: kiosk_address }, (coordinates) => {
			if (!coordinates) {
				console.error(`KioskModal.geocode_address:  Could not geocode address: ${kiosk_address}`);
			} else {
				const latLong = {
					ForTable: 'kiosks',
					ID: kiosk_id,
					Latitude: coordinates.latitude,
					Longitude: coordinates.longitude,
				};
				socket.emit('insertLatLong', latLong, (boolean) => {
					if (!boolean) {
						alert('You did not fill the form out correctly');
					} else {
						$.post(
							`/api/get_drive_times`,
							{
								address: kiosk_address,
								coordinates: coordinates,
								kiosk_id: kiosk_id,
								KioskID_Loc: KioskID_Loc,
								type: type,
							},
							(res) => {}
						);
					}
				});
			}
		});
	};

	/**
	 * Function to handle adding a kiosk.
	 *
	 * @async
	 * @function KioskAddButton
	 * @returns {void}
	 */
	const KioskAddButton = async () => {
		// remove this check when finished
		if (!kioskID) {
			console.error('Kiosk ID is undefined!');
			return;
		}
		let kiosk_address = `${storeAddress} ${storeCity} ${storeState} ${storeZip}`.trim();
		const Constructed_KioskID_Loc = kioskID + '_' + serverID.trim().replace(/ /g, '').replace(/-/g, '_');

		let starPaperObj = {
			KioskID: kioskID,
			KioskID_Loc: kioskID_Loc.length ? kioskID_Loc : Constructed_KioskID_Loc,
			StarPaperCount: 0,
			TodaysDate: new Date().toLocaleDateString(),
		};

		let updatedObj = {
			KioskID: kioskID,
			KioskID_Loc: kioskID_Loc.trim().length ? kioskID_Loc : Constructed_KioskID_Loc,
			KioskInstallDate: kioskInstallDate,
			StoreName: storeName,
			StoreHours: storeHours,
			StorePhone: storePhone,
			StoreAddress: storeAddress,
			StoreCity: storeCity,
			StoreState: storeState,
			StoreZip: storeZip,
			StoreCounty: storeCounty,
			ServerID: serverID,
			ModemType: modemType,
			Carrier: carrier,
			CarrierNumber: carrierNumber,
			PrimaryTechs: primaryTechs,
			BackupTechs: backupTechs,
			PaperChanger: paperTechInput,
			RestartSwitch: document.getElementById('restart-switch').checked ? 1 : 0,
			PaperBridge: document.getElementById('paper-bridge').checked ? 1 : 0,
			Cleaned: document.getElementById('cleaned').value,
			StarFirmware: document.getElementById('star-firmware').value,
			SkiSlope: document.getElementById('ski-slope').value,
			OSInstall: document.getElementById('os-installed').value,
			Notes: notes.replace(/['"`]/g, ''),
		};

		if (kiosk_address) {
			socket.emit('archiveKioskForTM', updatedObj, async (boolean) => {
				try {
					socket.emit('insertKioskForTM', updatedObj, async (boolean) => {
						try {
							if (boolean) {
								//Close CreateJobModal
								UpdateTechsAndCoordinates(kiosk_address, updatedObj.KioskID, updatedObj.KioskID_Loc, 'insert');

								socket.emit('insertNewStarPaper', starPaperObj, () => {
									alert(
										`Kiosk was added successfully.\n\nWe are working on updating the technician drive times for this kiosk and could take less than a minute to complete the process.\n\nYou can come back shortly to see the primary and backup techs that were assigned to this kiosk.`
									);
									props.refresh_data();
									document.getElementById('modal_kiosks').style.display = 'none';
									document.getElementById('myModal').style.display = 'none';
								});
							} else {
								//Notify user of Failure
								alert('You did not fill the form out correctly - insert issue/kioskModal');
							}
						} catch (error) {
							console.error(`ERROR: KioskModal.insertKioskForTM ${error} - ${new Date()}`);
						}
					});
				} catch (error) {
					console.error(`ERROR: KioskModal.archiveKioskForTM ${error} - ${new Date()}`);
				}
			});
		} else {
			alert('WARNING: Kiosk Address Cannot Be Blank');
		}
	};

	return (
		<div>
			{loading ? (
				<Spinner />
			) : (
				<div className='KioskFormContainer' onClick={DisplayList}>
					<div className='KioskForm' onClick={DisplayList}>
						{RenderList(primaryTechsInput, techDriveTimeList, 'PrimaryTechnicianList', 'TablePrimaryTechnicianList')}
						{RenderList(backupTechsInput, techDriveTimeList, 'BackupTechnicianList', 'TableBackupTechnicianList')}
						{RenderList(paperTechInput, techDriveTimeList, 'PaperTechnicianList', 'TablePaperTechnicianList')}

						<div className='flex-align-center flex-just-between flex-wrap' onClick={DisplayList}>
							<TextField
								id='kioskID'
								onClick={DisplayList}
								className='kioskID KioskModalField'
								label='Kiosk ID'
								defaultValue={kioskID}
								type='text'
								onChange={(e) => onChange(e, 'KioskID')}
								size='small'
							/>

							<TextField
								id='kioskInstallDate'
								className='kioskInstallDate KioskModalField'
								label='Install Date'
								InputLabelProps={{ shrink: true }}
								defaultValue={kioskInstallDate}
								type='date'
								size='small'
							/>
						</div>

						<div className='SecondLayer vert-margin' onClick={DisplayList}>
							<div className='FormLeft' onClick={DisplayList}>
								<div className='StoreNameInput' onClick={DisplayList}>
									<TextField
										id='kioskStoreName'
										className='kioskStoreName KioskModalField'
										label='Store Name'
										InputLabelProps={{ shrink: true }}
										defaultValue={storeName}
										onChange={(e) => onChange(e, 'StoreName')}
										maxLength='45'
										type='text'
										size='small'
									/>
								</div>
								<div className='StorePhoneInput' onClick={DisplayList}>
									<TextField
										id='kioskStorePhone'
										className='kioskStorePhone KioskModalField'
										label='Store Phone Number'
										InputLabelProps={{ shrink: true }}
										defaultValue={storePhone}
										onChange={(e) => onChange(e, 'StorePhone')}
										maxLength='10'
										type='text'
										size='small'
									/>
								</div>
							</div>

							<div className='StoreHoursTableContent' onClick={DisplayList}>
								<div className='StoreHoursHeader' onClick={DisplayList}>
									<IconButton
										variant='outlined'
										size='small'
										className='round-button'
										id='StoreHoursMinusButton'
										onClick={MinusTechButton}
									>
										<MdRemove />
									</IconButton>
									<IconButton
										variant='outlined'
										size='small'
										className='round-button'
										id='StoreHoursPlusButton'
										onClick={PlusTechButton}
									>
										<MdAdd />
									</IconButton>
									<TextField
										id='StoreHoursInput'
										className='StoreHoursInput KioskModalField'
										label='Store Hours'
										InputLabelProps={{ shrink: true }}
										type='text'
										size='small'
									/>
								</div>

								{RenderList('', storeHours, 'StoreHoursList', 'StoreHoursBodyTable')}
							</div>
						</div>

						<div className='flex-align-center flex-just-between flex-wrap vert-margin' onClick={DisplayList}>
							<TextField
								id='kioskServerID'
								label='Server ID'
								className='kioskServerID KioskModalField'
								maxLength='45'
								onChange={(e) => onChange(e, 'ServerID')}
								InputLabelProps={{ shrink: true }}
								defaultValue={serverID}
								type='text'
								size='small'
							/>

							<TextField
								id='kioskModem'
								label='Modem'
								className='kioskModem KioskModalField'
								maxLength='20'
								onChange={(e) => onChange(e, 'ModemType')}
								InputLabelProps={{ shrink: true }}
								defaultValue={modemType}
								type='text'
								size='small'
							/>
						</div>
						<div className='flex-align-center flex-just-between flex-wrap vert-margin' onClick={DisplayList}>
							<TextField
								id='kioskCarrier'
								label='Carrier'
								className='kioskCarrier KioskModalField'
								maxLength='20'
								onChange={(e) => onChange(e, 'Carrier')}
								InputLabelProps={{ shrink: true }}
								defaultValue={carrier}
								type='text'
								size='small'
							/>
							<TextField
								id='kioskCarrierPhone'
								label='Carrier Number'
								className='KioskModalField'
								onChange={(e) => onChange(e, 'CarrierNumber')}
								maxLength='10'
								InputLabelProps={{ shrink: true }}
								defaultValue={carrierNumber}
								type='text'
								size='small'
							/>
						</div>

						<div className='FifthLayer flex-wrap' id='FifthLayer'>
							<div className='vert-margin flex-row flex-just-between'>
								<div>
									<TextField
										id='cleaned'
										label='Last Cleaned'
										className='KioskModalField'
										InputLabelProps={{ shrink: true }}
										defaultValue={lastCleaned}
										type='date'
										size='small'
									/>
								</div>
								<div>
									<TextField
										id='star-firmware'
										label='Star Firmware'
										className='KioskModalField'
										InputLabelProps={{ shrink: true }}
										defaultValue={starFirmware}
										type='date'
										size='small'
									/>
								</div>
								<div>
									<TextField
										id='os-installed'
										label='OS Install'
										className='KioskModalField'
										InputLabelProps={{ shrink: true }}
										defaultValue={osInstall}
										type='date'
										size='small'
									/>
								</div>
							</div>
							<AddressField
								Address={storeAddress}
								City={storeCity}
								County={storeCounty}
								State={storeState}
								Zip={storeZip}
								onChange={onChange}
							/>

							<div className='vert-margin flex-row flex-just-between'>
								<div>
									<FormControlLabel
										label='Has Restart Switch'
										control={<Checkbox id='restart-switch' color='primary' defaultChecked={hasSwitch ? true : false} />}
									/>
								</div>
								<div>
									<FormControlLabel
										label='Has Paper Bridge'
										control={<Checkbox id='paper-bridge' color='primary' defaultChecked={paperBridge ? true : false} />}
									/>
								</div>
								<div>
									<FormControlLabel
										label='Has Ski Slope'
										control={<Checkbox id='ski-slope' color='primary' defaultChecked={skiSlope ? true : false} />}
									/>
								</div>
							</div>
							<textarea
								className='KioskNotesSection'
								id='KioskNotesSection'
								value={notes}
								onChange={(e) => onChange(e, 'KioskNotes')}
								onClick={DisplayList}
							></textarea>
						</div>

						<div>
							<button className='RTIButton' id='KIOSFormClose' onClick={KioskCloseButton}>
								CLOSE
							</button>
							{props.kioskKey && props.role === 'admin' ? (
								<button
									className='RTIButton'
									id='KIOSFormDelete'
									onClick={KioskDeleteButton}
									style={{ position: 'absolute', top: '0', right: '0' }}
								>
									DELETE
								</button>
							) : undefined}
							{props.kioskKey && props.role !== 'user' && props.role !== 'noAcc' ? (
								<button className='RTIButton' id='KIOSFormSave' onClick={KioskSaveButton} style={{ float: 'right' }}>
									SAVE
								</button>
							) : undefined}
							{(!props.kioskKey && props.role === 'admin') || 'master' ? (
								<button className='RTIButton' id='KIOSFormAdd' onClick={KioskAddButton} style={{ float: 'right' }}>
									ADD
								</button>
							) : undefined}
						</div>
					</div>

					<div className='TechniciansTableContent' onClick={DisplayList}>
						<select value={activeOrInactive} onChange={(e) => onChange(e, 'ActiveOrInactive')} onClick={DisplayList}>
							<option>Active</option>
							<option>InActive</option>
						</select>
						<div className='PrimaryTechsDiv' onClick={DisplayList}>
							<label className='PrimaryTechsLabel' onClick={DisplayList}>
								Primary Techs
							</label>
							<div className='PrimaryTechsHeader' onClick={DisplayList}>
								<input
									className='PrimaryTechNameInput'
									id='PrimaryTechNameInput'
									type='text'
									placeholder='Primary Techs'
									value={primaryTechsInput}
									onChange={(e) => onChange(e, 'PrimaryTechsInput')}
									onClick={() => {
										DisplayList;
										techAndDriveTime();
									}}
								/>

								<IconButton
									variant='outlined'
									size='small'
									className='round-button'
									id='PrimMinusTechButton'
									onClick={MinusTechButton}
								>
									<MdRemove />
								</IconButton>

								<IconButton
									variant='outlined'
									size='small'
									className='round-button'
									id='PrimPlusTechButton'
									onClick={PlusTechButton}
								>
									<MdAdd />
								</IconButton>
							</div>

							{RenderList('', primaryTechs, 'PrimaryTechsList', 'PrimaryTechsListTable')}
						</div>

						<div className='BackupTechsDiv' onClick={DisplayList}>
							<label className='BackupTechsLabel' onClick={DisplayList}>
								Backup Techs
							</label>
							<div className='BackupTechsHeader' onClick={DisplayList}>
								<input
									className='BackupTechNameInput'
									id='BackupTechNameInput'
									type='text'
									placeholder='Backup Techs'
									value={backupTechsInput}
									onChange={(e) => onChange(e, 'BackupTechsInput')}
									onClick={() => {
										DisplayList;
										techAndDriveTime();
									}}
								/>

								<IconButton
									variant='outlined'
									size='small'
									className='round-button'
									id='BackMinusTechButton'
									onClick={MinusTechButton}
								>
									<MdRemove />
								</IconButton>

								<IconButton
									variant='outlined'
									size='small'
									className='round-button'
									id='BackPlusTechButton'
									onClick={PlusTechButton}
								>
									<MdAdd />
								</IconButton>
							</div>
							{RenderList('', backupTechs, 'BackupTechsList', 'BackupTechsListTable')}
						</div>

						<div className='PaperTechDiv' onClick={DisplayList}>
							<label className='PaperTechLabel' onClick={DisplayList}>
								Paper Tech
							</label>
							<div className='PaperTechHeader' onClick={DisplayList}>
								<input
									className='PaperTechNameInput'
									id='PaperTechNameInput'
									type='text'
									placeholder='Paper Tech'
									value={paperTechInput}
									onChange={(e) => onChange(e, 'PaperTechInput')}
									onClick={() => {
										DisplayList;
										techAndDriveTime();
									}}
								/>
								<IconButton
									variant='outlined'
									size='small'
									className='round-button'
									id='PapMinusTechButton'
									onClick={MinusTechButton}
								>
									<MdRemove />
								</IconButton>

								<IconButton
									variant='outlined'
									size='small'
									className='round-button'
									id='PapPlusTechButton'
									onClick={PlusTechButton}
								>
									<MdAdd />
								</IconButton>
							</div>
							<ul className='PaperTechListTable' id='PaperTechListTable' style={{ flex: '0.3' }}>
								<li key={0} className='PaperTechList' id={paperTech} value={paperTech} onClick={DisplayList}>
									{paperTech}
								</li>
							</ul>

							<div
								className='NumberOfStarRolls'
								onClick={DisplayList}
								style={{ textAlign: 'center', position: 'relative', top: '15px' }}
							>
								<label onClick={DisplayList}>Star Rolls</label> <br />
								<input
									className='numberOfStarRolls'
									id='numberofstarrolls'
									type='text'
									maxLength='3'
									value={numberOfStarRolls}
									onChange={(e) => onChange(e, 'NumberOfStarRolls')}
									onClick={DisplayList}
									style={{ width: '52px' }}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
