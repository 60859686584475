import { CurrentTime } from '../imports/API/Moment.js';
import { DateToLocale, DateToYMD } from '../imports/API/TextFormatingFunctions.js';
import { logger } from './logging.js';
// import env from "../../env.config.json";
import env from '../../env.config.json' assert { type: 'json' };
import { getUserPTO, selectFromDispatchersForTM } from '../imports/API/tableManagementTB.js';
import axios from 'axios';

import $ from 'jquery';
// const _file = require("path").basename(__filename);
import url from 'url';
import path from 'path';
import fileUrl from 'file-url';

// const __filename = url.fileURLToPath(import.meta.url);
const __filename = fileUrl(import.meta.url);
const _file = path.basename(__filename);

import bwipjs from 'bwip-js';
/**
 * Method to calculate the available paid time off an employee has
 * @author Michael Miller
 * @param {number} _accrued - total number of accrued PTO for an employee
 * @param {array} _time_off - array of dates the employee has taken off
 * @returns {number} the number of available PTO hours for an employee
 */
export const calcAvailPTO = (_accrued = 0, _time_off = []) => {
  try {
    const pto_used = _time_off.reduce((total, entry) => {
      const start_date = new Date(entry.start_date); // convert ISO to local date
      const end_date = new Date(entry.end_date);

      const temp_date = new Date(start_date.getTime());
      const totalHours = Math.ceil(DateDiff(start_date, end_date, 'hour')); // get number of hours to loop through

      for (let i = 0; i < totalHours; i++) {
        if (temp_date.getTime() >= end_date.getTime()) break;

        // check if time off is within the bounds of working days/hours
        if (
          temp_date.getDay() > 0 &&
          temp_date.getDay() !== 6 &&
          temp_date.getHours() > env.WORK_HOURS[0] &&
          temp_date.getHours() <= env.WORK_HOURS[1]
        ) {
          // bleed into pto hours
          total += 1;
        }

        temp_date.setHours(temp_date.getHours() + 1);
      }

      return total;
    }, 0);

    const remaining_pto = _accrued - pto_used;

    return remaining_pto > 0 ? remaining_pto : 0; // remaining pto should not be under 0
  } catch (error) {
    logger.error(_file, calcAvailPTO.name, error);
    return 0;
  }
};

/**
 * Method to call PTO get HTTP request method as a promise
 * @author Michael Miller
 * @param {number} _uid - users identification number
 * @returns a promise with the users current PTO
 */
export const getPTO = (_uid = 0) => {
  return new Promise((resolve, reject) => {
    $.get('/api/users/pto', { uid: _uid }, (res) => {
      if (!res) {
        reject(res);
      } else {
        resolve(res);
      }
    });
  });
};

/**
 * Async method used to update all paid time off for all employees.  This method is used in a cron job.
 * TODO:
 * May 15th, 2023 :: ERROR request failed with status 400
 * @author Michael Miller
 */
export const updatePTOForAll = async () => {
  const users = await selectFromDispatchersForTM();
  const final_data = [];

  // make this a single call to the database instead
  for (let i = 0; i < users.length; i++) {
    try {
      const [accrued, time_off, actual] = await getUserPTO(users[i].UserID); // get single user pto
      const avail_pto = calcAvailPTO(accrued[0].accrued_pto, time_off); // calc single user avail pto

      final_data.push({
        uid: users[i].UserID,
        pto: avail_pto,
      });
    } catch (error) {
      console.error(`ERROR: util.updatePTOForAll: ${error} - ${new Date()}`);
    }
  }

  axios.put(`${env.LOCAL_URL}/api/users/pto`, final_data).catch((error) => {
    console.error(`ERROR: util.updatePTOForAll - axios call: ${error} - ${new Date()}`);
  });
};

/**
 * Method that formats messages for paper reminders.
 * CHORE: Could use a switch statement instead of nested ternary here
 * @author Michael Miller
 * @param {string} from - who the message is from
 * @param {object} job - object that holds the job details
 * @param {string} type - urgency of the txt/email
 * @returns a formatted message body to send to the paper technician
 */
export const getReminderMsg = (from = '', job = {}, type = 'standard') => {
  try {
    const msg =
      type == 'update'
        ? {
            body: `Click the link below to provide an updated ETA.\nhttps://console.ez-tab.net/confirm?jid=${
              job.ID
            }&kid=${job.KioskID.toLowerCase()}&tid=${job.TechnicianID}&type=UP\n\nHi ${
              job.TempTechnician
            },\nYour ETA - ${DateToLocale(new Date(job.ETA))} - for paper change #${job.PaperJobID} at Kiosk ${
              job.KioskID
            } (${job.ServerID}) has expired.\n\nThank You.`,
            to: job.TempTechnician,
            from: from,
            time: CurrentTime(),
            kiosk: job.KioskID,
            email: job.Email ? job.Email : null,
          }
        : type == 'urgent'
        ? {
            body: `Click the link below to confirm and provide an ETA.\nhttps://console.ez-tab.net/confirm?jid=${
              job.ID
            }&kid=${job.KioskID.toLowerCase()}&tid=${job.TechnicianID}&type=P\n\nHi ${
              job.Technician
            },\nYou have a paper change for Job#: ${job.PaperJobID} at Kiosk ${job.KioskID} (${
              job.ServerID
            }) that has reached a critical level and is due today!\n\nMake sure to return all test prints, stickers taken or removed from this kiosk.\n\nThank You.`,
            to: job.Technician,
            from: from,
            time: CurrentTime(),
            kiosk: job.KioskID,
            email: job.Email ? job.Email : null,
          }
        : type == 'standard'
        ? {
            body: `Click the link below to confirm and provide an ETA.\nhttps://console.ez-tab.net/confirm?jid=${
              job.ID
            }&kid=${job.KioskID.toLowerCase()}&tid=${job.TechnicianID}&type=P\n\nHi ${
              job.Technician
            },\nYou have a paper change for Job#: ${job.PaperJobID} at Kiosk ${job.KioskID} (${
              job.ServerID
            }) that is due.\n\nMake sure to return all test prints, stickers taken or removed from this kiosk.\n\nThank You.`,
            to: job.Technician,
            from: from,
            time: CurrentTime(),
            kiosk: job.KioskID,
            email: job.Email ? job.Email : null,
          }
        : {
            body: `Hi ${job.Technician},\nThis is a courtesy reminder that you have a paper change for Job#: ${
              job.PaperJobID
            } at Kiosk ${job.KioskID} (${job.ServerID}) @ ${DateToLocale(
              new Date(job.ETA)
            )}\n\nIf you have any questions, please call Tech Support.\n\nThank You.`,
            to: job.Technician,
            from: from,
            time: CurrentTime(),
            kiosk: job.KioskID,
            email: job.Email ? job.Email : null,
          };

    return msg;
  } catch (error) {
    console.error(`util.getReminderMsg: ${error}`);
    return null;
  }
};

/**
 * Method to format email signatures.
 * @author Michael Miller
 * @param {string} name - name of the employee the signature belongs to
 * @param {string} occupation - occupation of the employee the signature belongs to
 * @param {string} extension - employee's phone extension
 * @returns personalized employee signature used when sending emails
 */
export const getEmailSignature = (name = '', occupation = '', extension = '101') => {
  return `
    <span style="text-align:left;">
    <p><o:p>&nbsp;</o:p></p>
    <p ><o:p>&nbsp;</o:p></p>
    <p >
      <span style="font-family:sans-serif;color:#2F5496">Sincerely,
        <o:p></o:p>
      </span>
    </p>
    <p >
      <b><span style="font-size:12.0pt;font-family:sans-serif;color:rgb(255,0,0)">${
        name ? name : ''
      }<o:p></o:p></span></b>
    </p>
    <p >
      <span sty=le="font-size:10.0pt;font-family:sans-serif;color:rgb(31,56,100)">${
        occupation ? occupation : ''
      }<o:p></o:p></span>
    </p>
    <p ><o:p>&nbsp;</o:p></p>
    <p >
      <b><span style="font-size:12.0pt;font-family:sans-serif;color:rgb(47,84,150)">Registration Technology, Inc.<o:p></o:p></span></b>
    </p>
    <p >
      <span style="font-size:10.0pt;font-family:sans-serif;color:rgb(31,56,100)">Office: 763-270-0772 EXT. ${extension}<o:p></o:p></span>
    </p>
    <p >
      <span style="font-size:10.0pt;font-family:sans-serif;color:rgb(31,56,100)">Website:
        <a href=3D"http://www.ez-tab.com/"><span style="color:rgb(5,99,193)">www.ez-tab.com</span></a>
        <o:p></o:p>
      </span>
    </p>
    <p >
      <span style="font-size:10.0pt;font-family:sans-serif;color:rgb(47,84,150)">
      <o:p>&nbsp;</o:p></span>
    </p>
    <p>
      <span style="font-size:10.0pt;font-family:sans-serif;color:#767171">This communication is the property of Registration Technology, Inc. and may contain or has attached confidential or privileged information generally protected by non disclosure agreements. Unauthorized use of this communication is strictly prohibited and may be unlawful. If you have received this communication in error, please immediately notify the sender by reply e-mail and destroy all copies of the communication and any attachments.<o:p></o:p>
      </span>
    </p>
    </span>`;
};

/**
 * Method that converts HH:mm:ss 24hr time into 12hr local time
 * @author Michael Miller
 * @param {string} time - HH:mm:ss time as a string
 * @returns 24hr time into 12hr local time (ie. 23:59:00 -> 11:59:00 PM)
 */
export const FormatTimeLocale = (time = '') => {
  try {
    // time in = 08:00:00
    const [hour, minute] = time.split(':');
    return `${
      parseInt(hour) === 0 ? 12 : parseInt(hour) > 12 && parseInt(hour) < 24 ? parseInt(hour) - 12 : parseInt(hour)
    }:${minute} ${GetMeridian(hour)}`;
  } catch (error) {
    console.error(`util.FormatTimeLocale: ${error}`);
    return '';
  }
};

/**
 * Method to return AM | PM based on the time entered.
 * @author Michael Miller
 * @param {*} hour - string | number representing the hours
 * @returns AM if hour is between 00:00 - 11:00 | PM if hour is between 12:00 - 23:00
 */
export const GetMeridian = (hour) => {
  try {
    // expect hour to be 24 representation
    return parseInt(hour) >= 12 && parseInt(hour) < 24 ? 'PM' : 'AM';
  } catch (error) {
    console.error(`util.GetMeridian: ${error}`);
    return 'NA';
  }
};

/**
 * Method used to display a welcome message based on time of day
 * @author Michael Miller
 * @param {number} _time - number that represents the current hours
 * @returns Morning | Afternoon | Evening depending on the time of day
 */
export const getTimeOfDay = (_time = 0) => {
  try {
    if (typeof _time != 'number') {
      throw new Error(`Parameter must be of type number`);
    }

    return _time >= env.TIME_OF_DAY.MORNING.RANGE[0] && _time <= env.TIME_OF_DAY.MORNING.RANGE[1]
      ? env.TIME_OF_DAY.MORNING.DISPLAY
      : _time >= env.TIME_OF_DAY.AFTERNOON.RANGE[0] && _time <= env.TIME_OF_DAY.AFTERNOON.RANGE[1]
      ? env.TIME_OF_DAY.AFTERNOON.DISPLAY
      : _time >= env.TIME_OF_DAY.EVENING.RANGE[0] && _time <= env.TIME_OF_DAY.EVENING.RANGE[1]
      ? env.TIME_OF_DAY.EVENING.DISPLAY
      : env.TIME_OF_DAY.MORNING.DISPLAY;
  } catch (error) {
    logger.error(_file, getTimeOfDay.name, error);
    return env.TIME_OF_DAY.MORNING.DISPLAY;
  }
};

// must only accept toLocaleTimeString()
export const formatTimeToHHMM = (_time = '00:00:00') => {
  try {
    if (typeof _time != 'string') {
      throw new Error(`Parameter must be of type string`);
    } else if (_time.split(':').length != 3) {
      throw new Error(`Parameter must be in the format HH:mm:ss`);
    }

    const temp = _time.split(' '); // remove meridian from timestamp
    const [h, m, s] = temp[0].split(':'); // separate by :
    const meridian = temp[1].trim(); // AM/PM
    const hour = hours12To24(parseInt(h), meridian).toString();
    const time = `${hour.trim().padStart(2, '0')}:${m.trim().padStart(2, '0')}:${s.trim().padStart(2, '0')}`;
    return time;
  } catch (error) {
    logger.error(_file, formatTimeToHHMM.name, error);
    return _time;
  }
};

export const generateBarcodes = (_opts) => {
  // _opts
  return new Promise(async (resolve, reject) => {
    const _data = [];

    for (let i = 0; i < _opts.length; i++) {
      // _opts
      try {
        const buffer = await bwipjs.toBuffer(_opts[i]); // _opts
        const barcodeBase64 = `data:image/png;base64,${buffer.toString('base64')}`;
        _data.push(barcodeBase64);
      } catch (error) {
        console.error(`util.generateBarcodes: ${error}`);
        reject([]);
      }
    }

    resolve(_data);
  });
};

export const generateBarcodeData = (labels, options) => {
  return new Promise(async (resolve, reject) => {
    try {
      const barcodeData = [];

      for (let i = 0; i < labels.length; i++) {
        const data = await getBarcodeData(labels[i], options);
        barcodeData.push(data);
      }

      resolve(barcodeData.flatMap((el) => el));
    } catch (error) {
      console.error(`util.generateBarcodeData: ${error}`);
      reject({ msg: error });
    }
  });
};

const getBarcodeData = (labels, options) => {
  return new Promise(async (resolve, reject) => {
    try {
      const barcodeData = [];
      for (let i = 0; i < labels.length; i++) {
        if (labels[i]) {
          const buffer = await bwipjs.toBuffer({
            ...options,
            text:
              options.bcid === 'code128' && options.size && options.size == 'small'
                ? `%%${labels[i].type}%${labels[i].qty}$`
                : options.bcid === 'code128' && labels[i].job_id
                ? `${labels[i].job_id}%${labels[i].kiosk.substring(0, 6)}%${labels[i].paper_type}%${labels[i].qty}$`
                : options.bcid === 'qrcode' && options && options.use === 'serviceTag'
                ? `${labels}`
                : options.bcid === 'qrcode'
                ? `pc${labels[i]}`
                : '',
            alttext:
              options.bcid === 'qrcode' && options.use !== 'serviceTag' && options.use !== 'paper_cores'
                ? labels[i]
                : !options.altxt
                ? ''
                : `${labels[i].job_id}|${labels[i].kiosk.substring(0, 6)}|${labels[i].paper_type}|${labels[i].qty}`,
          });
          const barcodeBase64 = `data:image/png;base64,${buffer.toString('base64')}`;
          barcodeData.push(barcodeBase64);
        } else {
          barcodeData.push(null);
        }
      }

      resolve(barcodeData);
    } catch (error) {
      console.error(`util.getBarcodeData: ${error}`);
      reject({ msg: error });
    }
  });
};

export const _hashCode = (str = '') => {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const DateToLocaleShort = (_date = new Date()) => {
  try {
    const _day = _date.getDate();
    const _month = _date.getMonth() + 1;
    const _year = _date.getFullYear();
    const _hours = _date.getHours();
    const _minutes = _date.getMinutes();
    const _meridian = GetMeridian(_hours);

    const _curDate = new Date();
    const _diff = DateDiff(_date, _curDate, 'day');
    const _time = `${Hours24To12(_hours)}:${_minutes.toString().padStart(2, '0')} ${_meridian}`;

    if (_diff < 1) {
      return _time;
    } else {
      return `${_month.toString().padStart(2, '0')}/${_day.toString().padStart(2, '0')}/${_year}`;
    }
  } catch (error) {
    console.error(`util.DateToLocaleShort: ${error}`);
    return 'N/A';
  }
};

export const Hours24To12 = (_hours) => {
  try {
    return parseInt(_hours) === 0
      ? 12
      : parseInt(_hours) > 12 && parseInt(_hours) < 24
      ? parseInt(_hours) - 12
      : parseInt(_hours);
  } catch (error) {
    console.error(`util.Hours24To12: ${error}`);
    return _hours;
  }
};

export const hours12To24 = (_hours, _meridian) => {
  try {
    return _meridian.toUpperCase() == 'PM' && _hours < 12
      ? parseInt(_hours) + 12
      : _meridian.toUpperCase() == 'AM' && _hours == 12
      ? 0
      : _hours;
  } catch (error) {
    logger.error(_file, hours12To24.name, error);
    return _hours;
  }
};

/**
 * Method used to get the difference between two Date objects.
 * @author Michael Miller
 * @param {Date} _t0 - start time
 * @param {Date} _t1 - end time
 * @param {string} _result - string value used to format the results (ie. day, hour, minute)
 * @returns {number} the difference between two dates in the format specified by the _result param
 */
export const DateDiff = (_t0 = new Date(), _t1 = new Date(), _result = 'day') => {
  try {
    const _diff = Math.abs(_t1.getTime() - _t0.getTime());

    switch (_result) {
      case 'minute':
        return Math.ceil(_diff / (1000 * 60));
      case 'hour':
        return Math.ceil(_diff / (1000 * 60 * 60));
      case 'day':
        return Math.ceil(_diff / (1000 * 60 * 60 * 24));
      default:
        return 0;
    }
  } catch (error) {
    console.error(`util.DateDiff: ${error}`);
    return 0;
  }
};

export const FormatStickerYear = (_item) => {
  if (_item) {
    return _item.endsWith('(Old)')
      ? `${_item.replace('(Old)', new Date().getFullYear())}`
      : _item.endsWith('(Current)')
      ? `${_item.replace('(Current)', new Date().getFullYear() + 1)}`
      : _item.endsWith('(New)')
      ? `${_item.replace('(New)', new Date().getFullYear() + 2)}`
      : _item;
  }

  return _item;
};

export const validateBinLocation = async (_bin) => {
  try {
    return await new Promise((resolve, reject) => {
      socket.emit('getBinLocation', _bin, (res) => {
        if (!res) {
          console.error(`InventoryBin.getBinLocation: There was an issue calling this method`);
          resolve(false);
        } else {
          if (res.length) {
            resolve(true);
          } else {
            resolve(false);
          }
        }
      });
    });
  } catch (error) {
    console.error(
      `ERROR: util.validateBinLocation is having an issue, cannot resolve bin location : ${error} - ${new Date()}`
    );
  }
};

export const FormatYear = (_interval = 0) => {
  const _date = new Date();
  _date.setFullYear(_date.getFullYear() + _interval);

  return _date.getFullYear();
};
