import React, { useEffect, useState } from 'react';
import { KioskModal } from './KioskModal.js';
import CustomTable from '../../components/Table';
import Button from '@mui/material/Button';
import { AiOutlinePlus as AddIcon } from 'react-icons/ai';
import { AiOutlineDownload as GetAppIcon } from 'react-icons/ai';
import { CSVLink } from 'react-csv';
import { FormatMultiLineText, FormatPhoneNumber } from '../../imports/API/TextFormatingFunctions.js';
import { useCustomContext } from '../../hoc/Context.js';
import axios from 'axios';

const DEFAULT_DATE = '0000-00-00 00:00:00';

const TableHeaders = [
	{ id: 'kiosk', name: 'Kiosk', sortable: true, numeric: false },
	{ id: 'server', name: 'Server', sortable: true, numeric: false },
	{ id: 'star', name: 'Star', sortable: true, numeric: true },
	{ id: 'dm1', name: 'DM1', sortable: true, numeric: true },
	{ id: 'dm2', name: 'DM2', sortable: true, numeric: true },
	{ id: 'phone', name: 'Phone #' },
	{ id: 'store', name: 'Store' },
	{ id: 'hours', name: 'Store Hours' },
];

const SearchOptions = [
	{ id: 'kiosk', name: 'Kiosk', pidx: 0 },
	{ id: 'server', name: 'Server', pidx: 1 },
	{ id: 'store', name: 'Store', pidx: 6 },
];

//Formats the KioskTable Information for each row
const KioskTable = () => {
	const [kiosksArchived, setKiosksArchived] = useState([]);
	const [kiosksClicked, setKiosksClicked] = useState('');
	const [KiosksDB, setKiosksDB] = useState([]);
	const [TechnicianList, setTechnicianList] = useState('');
	const [DriveTimes, setDriveTimes] = useState([]);
	const [ModalOpen, setModalOpen] = useState(false);
	const [Loading, setLoading] = useState(true);
	const [ReportData, setReportData] = useState([]);
	const [SearchVal, setSearchVal] = useState('');
	const [searchParam, setSearchParam] = useState(0);
	const [transactions, setTransactions] = useState([]);
	const { userState, userDispatch } = useCustomContext();

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			$.post(`/api/table_data`, { table: 'kiosk_table' }, (res) => {
				if (res.status === 'OK') {
					const [kiosks, archives, techs, txns] = res.data;
					setTransactions(txns);
					let list = techs.map((tech) => tech.TechnicianName);
					let kiosks_data = FormatKioskInfo(kiosks, transactions);
					setKiosksDB(kiosks);
					setReportData(kiosks_data);
					setKiosksArchived(archives);
					setTechnicianList(list);
					setLoading(false);
				} else {
					setLoading(false);
					alert(res.error);
				}
			});
		}
		return () => {
			mounted = false;
		};
	}, [kiosksArchived]);

	const RefreshTableData = () => {
		socket.emit('selectFromKiosksForTM', (result) => {
			let kiosks_data = FormatKioskInfo(result);
			setKiosksDB(result);
			setReportData(kiosks_data);
		});

		socket.emit('selectFromArchiveKiosksForTM', (result) => {
			setKiosksArchived(result);
		});
	};

	function FormatKioskInfo(kiosks, txns) {
		let kiosk_info = [];

		kiosks.forEach((e) => {
			const txn = transactions.find((kiosk) => kiosk.kiosk_id === e.KioskID);
			kiosk_info.push({
				KioskID: e.KioskID,
				KioskInstallDate: e.KioskInstallDate,
				StoreName: e.StoreName,
				StoreHours: JSON.stringify(e.StoreHours).replace(/[\"\\\]\[]/g, ''),
				StorePhone: e.StorePhone,
				Latitude: e.Latitude,
				Longitude: e.Longitude,
				StoreAddress: e.StoreAddress,
				StoreCity: e.StoreCity,
				StoreState: e.StoreState,
				StoreZip: e.StoreZip,
				StoreCounty: e.StoreCounty,
				ServerID: e.ServerID,
				ModemType: e.ModemType,
				Carrier: e.Carrier,
				CarrierNumber: e.CarrierNumber,
				PrimaryTechs: JSON.stringify(e.PrimaryTechs).replace(/[\"\\\]\[]/g, ''),
				BackupTechs: JSON.stringify(e.BackupTechs).replace(/[\"\\\]\[]/g, ''),
				PaperChanger: e.PaperChanger,
				PrintCountCOVR: e.PrintCountCOVR,
				NumberOfStarRolls: e.NumberOfStarRolls,
				PrintCountOne: e.PrintCountOne,
				PrinterOneYear: e.PrinterOneYear,
				PrintCountTwo: e.PrintCountTwo,
				PrinterTwoYear: e.PrinterTwoYear,
				'Monthly Average Transactions': txn ? txn.txn_avg : '',
				'Restart Switch': e.has_switch ? 'YES' : '',
				'Paper Bridge': e.paper_bridge ? 'YES' : '',
				Cleaned: e.cleaned && e.cleaned != DEFAULT_DATE ? e.cleaned.split('T')[0] : '',
			});
		});
		return kiosk_info;
	}

	const AddNewItemBtn = () => {
		if (userState.user.role) {
			setKiosksClicked('');
			setModalOpen(true);
			document.getElementById('myModal').style.display = 'block';
			document.getElementById('modal_kiosks').style.display = 'block';
		}
	};

	const RowClick = (e) => {
		setDriveTimes([]);
		const kiosk_id = e.target.id;
		socket.emit('getDriveTimesForKiosk', kiosk_id, (drive_times) => {
			if (!drive_times) {
				console.error('There was an issue getting the drive times for this kiosk');
			} else {
				setKiosksClicked(kiosk_id);
				setDriveTimes(drive_times);
				setModalOpen(true);
				document.getElementById('myModal').style.display = 'block';
				document.getElementById('modal_kiosks').style.display = 'block';
			}
		});
	};

	function ClearSearch() {
		setSearchVal('');
	}

	function Search(param, searchVal) {
		setSearchVal(searchVal);
		setSearchParam(param);
	}

	const handleKioskDownload = () => {
		document.getElementById('kiosk-download').click();
	};

	const handleArchiveDownload = () => {
		document.getElementById('archive-download').click();
	};

	function RenderTableButtons() {
		return userState.user.role ? (
			<div style={{ display: 'flex' }}>
				<div>
					<Button
						className="rti-blue-round"
						variant="contained"
						startIcon={<GetAppIcon />}
						onClick={handleKioskDownload}
					>
						Kiosks
					</Button>
				</div>
				<CSVLink
					id="kiosk-download"
					style={{ background: 'none', color: 'none', border: 'none' }}
					target="_blank"
					className="hidden"
					data={ReportData}
					filename={'Kiosk.csv'}
				></CSVLink>
				<div>
					<Button
						className="rti-blue-round"
						variant="contained"
						startIcon={<GetAppIcon />}
						onClick={handleArchiveDownload}
					>
						Archives
					</Button>
				</div>
				<CSVLink
					id="archive-download"
					style={{ background: 'none', color: 'none', border: 'none' }}
					target="_blank"
					className="hidden"
					data={kiosksArchived}
					filename={'KioskArchives.csv'}
				></CSVLink>
				<div>
					<Button className="rti-blue-round" variant="contained" startIcon={<AddIcon />} onClick={AddNewItemBtn}>
						Kiosk
					</Button>
				</div>
			</div>
		) : undefined;
	}

	const getTableData = () => {
		return KiosksDB.map((kiosk) => ({
			key: kiosk.KioskID,
			color: kiosk.ActiveOrInactive === 'InActive' ? 'pink' : null,
			cells: [
				{ data: kiosk.KioskID },
				{ data: kiosk.ServerID },
				{
					data: `${kiosk.PrintCountCOVR} (${kiosk.NumberOfStarRolls})`,
					color: kiosk.PrintCountCOVR <= 40 ? 'red' : null,
				},
				{ data: `${kiosk.PrintCountOne} (${kiosk.PrinterOneYear})`, color: kiosk.PrintCountOne <= 40 ? 'red' : null },
				{ data: `${kiosk.PrintCountTwo} (${kiosk.PrinterTwoYear})`, color: kiosk.PrintCountTwo <= 40 ? 'red' : null },
				{ data: FormatPhoneNumber(kiosk.StorePhone) },
				{
					data: `${kiosk.StoreName}\n${kiosk.StoreAddress}\n${kiosk.StoreCity}, ${kiosk.StoreState} ${kiosk.StoreZip}`,
				},
				{ data: FormatMultiLineText(kiosk.StoreHours) },
			],
		})).filter((row) =>
			SearchVal && searchParam
				? row.cells[SearchOptions[searchParam - 1].pidx].data.toLowerCase().includes(SearchVal.toLowerCase())
				: row
		);
	};

	return (
		<div className="ViewedContentContainer" id="OpenContainer">
			<div style={{ height: '100%' }}>
				<div className="modal" id="myModal">
					<div className="modal-content" id="modal_kiosks">
						{ModalOpen ? (
							<KioskModal
								role={userState.user.permissions}
								key={parseInt(kiosksClicked.replace(/[^\d.]/g, '')) || ''}
								kioskKey={kiosksClicked || ''}
								technicianList={TechnicianList || []}
								refresh_data={RefreshTableData || ''}
								kioskModal={
									KiosksDB.filter(
										// filters the selected kiosk
										function (id) {
											return id.KioskID == kiosksClicked;
										}
									)[0]
								}
								driveTimes={DriveTimes}
								close_modal={() => setModalOpen(false)}
							/>
						) : null}
					</div>
				</div>
				<div className="TitleBarContainer">
					<h1>Kiosks</h1>
				</div>
				<CustomTable
					headers={TableHeaders}
					rows={getTableData()}
					paginate
					loading_data={Loading}
					searchable
					search={Search}
					clear_search={ClearSearch}
					search_options={SearchOptions}
					table_buttons={RenderTableButtons()}
					onClick={RowClick}
				/>
			</div>
		</div>
	);
};

export default KioskTable;
