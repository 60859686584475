import React, { useState, useEffect } from "react";
import CustomTable from "../../components/Table";
import AlertBar from "../../components/AlertBar";
import { logger } from "../../utilities/logging";
import Chip from "@mui/material/Chip";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import { AiOutlineSave } from "react-icons/ai";
import "../../StyleSheets/Forms.css";

const _file = require("path").basename(__filename);

const TableHeaders = [
  { id: "email", name: "email subject" },
  { id: "recipients", name: "recipients" },
];

const EmailList = () => {
  const [list, setList] = useState([]);
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    visible: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    retrieveData(true);
  }, []);

  const retrieveData = (_load) => {
    if (_load) setLoading(true);

    $.post("/api/table_data", { table: "email_table" }, (res) => {
      if (res.status != "OK") {
        setLoading(false);
        logger.error(_file, retrieveData.name, res.error);
      } else {
        const [_emails, _users] = res.data;
        setUsers(
          _users.sort((a, b) =>
            a.FullName < b.FullName ? -1 : a.FullName > b.FullName ? 1 : 0
          )
        );
        setList(_emails);
        setLoading(false);
      }
    });
  };

  const handleCloseSnack = () => {
    setSnackbar((prevState) => ({
      ...prevState,
      visible: false,
    }));
  };

  const handleRecipSelect = (e, _idx, subject) => {
    const { value } = e.target;
    const copy = JSON.parse(JSON.stringify(list)); // create deep copy
    const foundSubject = copy.find((el) => el.subject === subject);
    foundSubject.recipients = value.join(",");
    setList(copy);
  };

  const getTableData = () => {
    return list.map((email) => ({
      key: email.id,
      cells: [
        { data: email.subject },
        {
          data: (
            <div key={email.id}>
              <FormControl>
                <Select
                  style={{ width: 260 }}
                  id={`emailSelect-${email.id}`}
                  multiple
                  value={email.recipients ? email.recipients.split(",") : []}
                  onChange={(e) =>
                    handleRecipSelect(e, email.id, email.subject)
                  }
                  input={<OutlinedInput id={`emailInput-${email.id}`} />}
                  renderValue={(selectedVal) =>
                    selectedVal.map((el) => (
                      <Chip
                        id={`emailSelect-${email.id}`}
                        className="chip-select"
                        key={el}
                        label={el}
                      />
                    ))
                  }
                >
                  {users.map((user) => (
                    <MenuItem
                      id={`emailSelect-${email.id}`}
                      key={user.UserID}
                      value={user.FullName}
                      className={`multi-select ${
                        email.recipients &&
                        email.recipients.split(",").includes(user.FullName)
                          ? "active-select"
                          : ""
                      }`}
                    >
                      {user.FullName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ),
        },
      ],
    }));
  };

  const handleSaveEmailList = () => {
    const finalList = list.map((email) => {
      const _recipients = email.recipients ? email.recipients.split(",") : [];

      const recip = _recipients.map((recips) => {
        const uid = users.find(
          (user) => user.FullName.toUpperCase() == recips.toUpperCase()
        ).UserID;
        return { id: uid };
      });

      return {
        id: email.id,
        recipients: recip,
      };
    });

    socket.emit("updateEmailList", finalList, (res) => {
      if (!res) {
        console.error(
          `EmailList.updateEmailList: There was an issue calling this method`
        );
        setSnackbar({
          visible: true,
          message: `List has been updated successfully!`,
          severity: "success",
        });
      } else {
        setSnackbar({
          visible: true,
          message: `List has been updated successfully!`,
          severity: "success",
        });
      }
    });
  };

  const renderTableButtons = () => {
    return (
      <div>
        <Button
          className="rti-blue-round"
          variant="contained"
          endIcon={<AiOutlineSave />} // Updated SaveIcon to AiOutlineSave
          onClick={handleSaveEmailList}
        >
          save
        </Button>
      </div>
    );
  };

  return (
    <div className="ViewedContentContainer">
      <div style={{ height: "100%" }}>
        <div className="TitleBarContainer">
          <h1>Email List</h1>
        </div>
        <AlertBar
          visible={snackbar.visible}
          onClose={handleCloseSnack}
          message={snackbar.message}
          severity={snackbar.severity}
        />
        <CustomTable
          paginate
          loading_data={loading}
          headers={TableHeaders}
          rows={getTableData()}
          table_buttons={renderTableButtons()}
        />
      </div>
    </div>
  );
};

export default EmailList;
