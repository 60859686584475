export const userReducer = (state, action) => {
    switch(action.type){
        case 'SET_USER':
            return {
                ...state,
                user: action.user
            }
        case 'UPDATE_USER_LOGS':
            return {
                ...state,
                user: {
                    ...state.user,
                    user_logs: action.user_logs
                }
            }
        default:
            return state
    }
}