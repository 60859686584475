import React from 'react';
import '../StyleSheets/Spinner.css';
import PropTypes from 'prop-types';

/**
 * @description component that renders a spinner
 * @param props
 * @param {string} [props.size] - defines the size of the spinner [small | ''] (defaults to regular size if not used)
 * @param {string} [props.margin] - defines the css classname to use (defaults to no margin) 
 * @returns {React.ReactElement} Spinner component
 */
const Spinner = ({margin = 'margin-none',size = ""}) => {
    return(
        <div className={margin}>
            <div className={size ? `Spinner-${size}` : 'Spinner'}></div>
        </div>
    )
}

export default Spinner

Spinner.propTypes = {
    margin: PropTypes.string,
    size: PropTypes.string
}