import React, { useEffect, useState } from 'react';
import Logo from '../../../public/Images/regitechLogo.png';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DateToLocale, DateToYMD } from '../API/TextFormatingFunctions.js';
import Spinner from '../../components/Spinner.js';
import AlertBar from '../../components/AlertBar.js';

const Confirmation = (props) => {
    const today = new Date();

    const [job,setJob] = useState({
        job_id: null,
        kiosk_id: null,
        technician: null,
        issue: null,
        paper: null,
        phone: null
    })
    const [loading,setLoading] = useState(true);
    const [success,setSuccess] = useState(false);
    const [confirmed,setConfirmed] = useState(false);
    const [pending,setPending] = useState(false);
    const [eta,setEta] = useState({
        date: DateToYMD(today),
        time: `${today.getHours().toString().padStart(2, '0')}:${today.getMinutes().toString().padStart(2, '0')}`
    })
    const [snackbar,setSnackbar] = useState({
        visible: false,
        message: '',
        severity: 'success'
    })

    useEffect(() => {
        const jtype = new URLSearchParams(window.location.search).get('type')
        const job_id = new URLSearchParams(window.location.search).get('jid');
        const kiosk_id = new URLSearchParams(window.location.search).get('kid');
        const tech_id = new URLSearchParams(window.location.search).get('tid');
        const job_type = jtype === 'S' ? 'standard' : jtype === 'P' ? 'paper' : 'update-paper';

        socket.emit('retrieveJobDetails', job_id, kiosk_id, job_type,(res) => {
            if(!res){
                console.error(`Confirmation.retrieveJobDetails: There was an issue calling this method`)
                setLoading(false);
            }else{
                const jobs = JSON.parse(res[0].Kiosks);
                const job = jobs.find(e => e.KioskID == kiosk_id.toUpperCase());
                const paper = job.Type.map(type => type.PaperType).join(', ');

                if(job.ETA && job.Confirmed && job_type === 'paper'){
                    setConfirmed(true);
                    setLoading(false);
                }else{
                    setJob(prevState => ({
                        ...prevState,
                        job_id: res[0].ID,
                        kiosk_id: `${kiosk_id.toUpperCase()} (${res[0].ServerID.replace(/_/g,"'")})`,
                        technician: res[0].tech.split(' ')[0],
                        issue: 'Paper Change',
                        paper,
                        phone: res[0].phone
                    }))
                    setLoading(false);
                }
            }
        });
    },[])

    const handleETA = async () => {
        if(eta.date && eta.time){
            const combined_eta = `${eta.date} ${eta.time}:00`

            setPending(true);
            socket.emit('confirmPaperChangeRequest',combined_eta, job.job_id, job.kiosk_id.split(' ')[0],(res) => {
                if(!res){
                    console.error(`Confirmation.confirmPaperChangeRequest: There was an issue calling this method`)
                    setSnackbar(prevState => ({
                        ...prevState,
                        visible: true,
                        message: 'ERROR: Sorry, we were unable to complete this request at this time.  Please contact tech support.',
                        severity: 'error'
                    }));
                }else{   
                    $.post('/api/send_confirmation',{eta: combined_eta, phone: job.phone, paper: job.paper},(res) => {
                        if(!res){
                            console.error(`Confirmation.SendMessageAsync: There was an issue calling this method`)
                            setSnackbar(prevState => ({
                                ...prevState,
                                visible: true,
                                message: 'ERROR: Sorry, we were unable to complete this request at this time.  Please contact tech support.',
                                severity: 'error'
                            }));
                        }else{
                            setSuccess(true);
                        }
                    })
                }
            });
        }else{
            setSnackbar(prevState => ({
                ...prevState,
                visible: true,
                message: 'WARNING: Please make sure that you have selected a date & time for your ETA.',
                severity: 'warning'
            }));
        }
    }

    const handleETADate = (event) => {
        const value = event.target.value;
        setEta(prevState => ({
            ...prevState,
            date: value
        }))
    }

    const handleETATime = (event) => {
        const value = event.target.value;
        setEta(prevState => ({
            ...prevState,
            time: value
        }))
    }

    const handleCloseSnack = () => {
        setSnackbar(prevState => ({
            ...prevState,
            visible: false
        }))
    }

    return (
        <div className="tech-app-container" style={{height: '100%'}}>            
            {loading ? <Spinner margin="center" /> : !loading && success ?
            <div style={{height: '100%', padding: 20, display: 'flex', alignItems: 'center', fontSize: 20, flexDirection: 'column', justifyContent: 'center'}}>
                <h1>Thank You!</h1>
                <p style={{marginBottom: 80}}>We have received your confirmation. Please make sure to change the following paper at this kiosk: <b>{job.paper}</b>.</p>
                <div style={{border: '1px solid #efefef', width: '100%'}} id="divider"></div>
                <p>Please contact tech support if you have any questions <b style={{color: 'darkblue'}}>(763) 225-1171</b></p>
            </div> :
            !loading && confirmed ?
            <div style={{height: '100%', padding: 20, display: 'flex', alignItems: 'center', fontSize: 20, flexDirection: 'column', justifyContent: 'center'}}>
                <h1>Thank You!</h1>
                <p style={{marginBottom: 80}}>We have received your confirmation.</p>
                <div style={{border: '1px solid #efefef', width: '100%'}} id="divider"></div>
                <p>Please contact tech support if you have any questions <b style={{color: 'darkblue'}}>(763) 225-1171</b></p>
            </div> :
            <div style={{height: '100%', padding: 20, display: 'flex', alignItems: 'center', fontSize: 20, flexDirection: 'column', justifyContent: 'center'}}>
                <img style={{marginBottom: 40, marginTop: 212}} src={`/${Logo}`} width="300px"></img>
                <h1>Hi {job.technician}</h1>
                <p>Please input your ETA and click the <b>CONFIRM</b> button below to respond to this job.</p>
                <div style={{color: 'gray'}}>                    
                    <p><b>Job# </b> {job.job_id}</p>
                    <p><b>Kiosk: </b> {job.kiosk_id}</p>
                    <p><b>Issue: </b> {job.issue}</p>
                    <p><b>Paper: </b> {job.paper}</p>
                </div> 
                <div style={{border: '1px solid #efefef', width: '100%'}} id="divider"></div>
                <h4>ETA</h4>
                <div style={{marginBottom: 46}}>
                    <TextField style={{minWidth: 200}} id="date-eta" className="table-date-input space-below" label="Date" type="date" value={eta.date} onChange={handleETADate}/><br></br>
                    <TextField style={{minWidth: 200}} id="time-eta" className="table-date-input space-below" label="Time" type="time" value={eta.time || ""} onChange={handleETATime}/>
                </div> 
                <span style={{paddingBottom: 40}}>
                    <Button disabled={pending} style={{fontSize: 16, width: 200}} variant="outlined" color="primary" onClick={handleETA}>Confirm</Button>
                </span>              
            </div>}
            <AlertBar
                visible={snackbar.visible}
                onClose={handleCloseSnack}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </div>
    )
}

export default Confirmation;