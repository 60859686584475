// personalized logger to make logging more streamlined within the codebase.
export const logger = () => ({
    error: (_file, _func, _msg) => {
        console.error(`${_file}/${_func}: ${_msg}`);
    },
    warn: (_file, _func, _msg) => {
        console.warn(`${_file}/${_func}: ${_msg}`);
    },
    log: (_msg, _func) => {    // can add file name here, but not necessary
        // if function name is not available, then log message.
        console.log(_func ? `${_func}: ${_msg}` : _msg);
    }
});