/**
 * Backend file for connecting to the database and retrieving information about kiosk alerts.
 * @component
 * 
 * @module KioskAlertsTB
 * @category Database API
 */
import pool from './pool.js';

//Gets a List of information about each Cleared or Pending Alert
/**
 * Retrieves kiosk alerts from the database.
 * @method selectFromKioskAlert
 * @returns {Promise<Array>} A promise that resolves to an array of kiosk alerts.
 * @throws {Error} If there is an error retrieving the kiosk alerts.
 * @memberof module:KioskAlertsTB
 */
export const selectFromKioskAlert = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const action = `CALL getKioskAlerts();`;
      const response = await pool.query(action);
      resolve(response[0]);
    } catch (error) {
      console.error(`ERROR: kioskAlertsTB.selectFromKioskAlert: ${error} - ${new Date()}`);
      reject(error);
    }
  });
};

/**
 * Retrieves call logs from the dispatch console table based on a given date range and kiosk ID.
 * @method getDaysPrior
 * @param {Object} obj - The object containing the query parameters.
 * @param {string} obj.Prior - The start date of the date range.
 * @param {string} obj.Today - The end date of the date range.
 * @param {string} obj.KioskID - The ID of the kiosk.
 * @returns {Promise} A promise that resolves with the response from the database query.
 * @throws {Error} If there is an error executing the database query.
 * @memberof module:KioskAlertsTB
 */
export const getDaysPrior = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const statement = `SELECT * FROM dispatch_console.call_logs
            WHERE DateOfIssue BETWEEN '${obj.Prior}' AND '${obj.Today}' AND KioskID = "${obj.KioskID.replace(
        /'/g,
        '-'
      )}";`;
      const response = await pool.query(statement);
      resolve(response);
    } catch (error) {
      console.error(`ERROR: kioskAlertsTB.getDaysPrior: ${error} - ${new Date()}`);
      reject(error);
    }
  });
};

/**
 * Retrieves kiosk analytics table data for a specified number of days.
 * @method kioskTableAnalytics
 * @param {number} days - The number of days to retrieve data for.
 * @returns {Promise<Array>} - A promise that resolves with an array of kiosk analytics table data.
 * @throws {Error} - If there is an error retrieving the data.
 * @memberof module:KioskAlertsTB
 */
export const kioskTableAnalytics = (days) => {
  return new Promise(async (resolve, reject) => {
    try {
      const action = `CALL dispatch_console.GetKioskAnalyticsTable(${days});`;
      const response = await pool.query(action);
      resolve(response[0]);
    } catch (error) {
      console.error(`ERROR: kioskAlertsTB.kioskTableAnalytics: ${error} - ${new Date()}`);
      reject(error);
    }
  });
};

/**
 * Retrieves kiosk events based on the provided kiosk ID and number of days.
 * @method getKioskEvents
 * @param {string} kiosk_id - The ID of the kiosk.
 * @param {number} days - The number of days to retrieve events for.
 * @returns {Promise<any>} - A promise that resolves with the response from the API call.
 * @throws {Error} - If an error occurs during the API call.
 * @memberof module:KioskAlertsTB
 */
export const getKioskEvents = (kiosk_id, days) => {
  return new Promise(async (resolve, reject) => {
    try {
      const action = `CALL dispatch_console.GetAnalyticsByKioskID_CombinedLogs('${kiosk_id}',${days});`;
      const response = await pool.query(action);
      resolve(response);
    } catch (error) {
      console.error(`ERROR: kioskAlertsTB.getKioskEvents: ${error} - ${new Date()}`);
      reject(error);
    }
  });
};


/**
 * Retrieves the event counts for a specific kiosk ID within a given number of days.
 * @method getKioskEventCountsByID
 * @param {string} kiosk_id - The ID of the kiosk.
 * @param {number} days - The number of days to retrieve event counts for.
 * @returns {Promise<any>} - A promise that resolves with the response containing the event counts.
 * @throws {Error} - If an error occurs while retrieving the event counts.
 * @memberof module:KioskAlertsTB
 */
export const getKioskEventCountsByID = (kiosk_id, days) => {
  return new Promise(async (resolve, reject) => {
    try {
      const action = `CALL dispatch_console.GetAnalyticStatsByKioskID('${kiosk_id}',${days});`;
      const response = await pool.query(action);
      resolve(response);
    } catch (error) {
      console.error(`ERROR: kioskAlertsTB.getKioskEventCountsByID: ${error} - ${new Date()}`);
      reject(error);
    }
  });
};

/**
 * Retrieves call logs from the dispatch console based on a specified date range.
 * @method getDaysPriorScript
 * @param {Object} obj - The object containing the date range.
 * @param {string} obj.Prior - The start date of the range.
 * @param {string} obj.Today - The end date of the range.
 * @returns {Promise<Array>} - A promise that resolves to an array of call logs.
 * @throws {Error} - If there is an error retrieving the call logs.

  * @memberof module:KioskAlertsTB
 */
export const getDaysPriorScript = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const action = `SELECT * FROM dispatch_console.call_logs WHERE
      DateOfIssue BETWEEN ? AND ?;`;
      // DateOfIssue BETWEEN '${obj.Prior}' AND '${obj.Today}';`;
      const response = await pool.query(action, [obj.Prior, obj.Today]);
      resolve(response);
    } catch (error) {
      console.error(`ERROR: kioskAlertsTB.getDaysPriorScript: ${error} - ${new Date()}`);
      reject(error);
    }
  });
};

/**
 * Retrieves alerts to update.
 * @method getAlertsToUpdate
 * @returns {Promise<any>} A promise that resolves with the response from the database query.
 * @throws {Error} If an error occurs while retrieving the alerts.

  * @memberof module:KioskAlertsTB
 */
export const getAlertsToUpdate = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const action = `SELECT
      a.AlertID,
      a.KioskID,
      a.KioskIssueID,
      a.StepAlertIsAt,
      a.KioskStatus,
      a.WaitTime,
      i.ToDoNext
  FROM
      dispatch_console.kiosk_alerts a
          JOIN
      dispatch_console.kiosk_issues i ON (a.KioskIssueID = i.KioskIssueID)
  WHERE
      a.KioskStatus != 'Fixed'
          AND a.KioskStatus IS NOT NULL
          AND a.WaitTime IS NOT NULL
          AND a.WaitTime >= date(now()) - interval 7 day;`;
      const response = await pool.query(action);
      resolve(response);
    } catch (error) {
      console.error(`ERROR: kioskAlertsTB.getAlertsToUpdate: ${error} - ${new Date()}`);
      reject(error);
    }
  });
};

/**
 * Updates the downtime for a kiosk alert.
 * @method updateDowntime
 * @param {string} type - The type of action ('insert' or 'update').
 * @param {object} alert - The alert object containing kiosk and status information.
 * @returns {Promise} A promise that resolves with the response from the database query.
 * @throws {Error} If an error occurs during the database query.

  * @memberof module:KioskAlertsTB
 */
export const updateDowntime = (type, alert) => {
  return new Promise(async (resolve, reject) => {
    try {
      const kiosk_id = alert.kiosk.trim().toUpperCase();
      const status = alert.status.trim();

      if (type == 'insert') {
        const action = `CALL insertKioskDowntime('${kiosk_id}', '${status}');`;
        const response = await pool.query(action);
        resolve(response);
      } else {
        const action = `UPDATE dispatch_console.kiosk_downtime SET end_time = NOW() WHERE kiosk_id = '${kiosk_id}' AND end_time IS NULL;`;
        const response = await pool.query(action);
        resolve(response);
      }
    } catch (error) {
      console.error(`ERROR: kioskAlertsTB.updateDowntime: ${error} - ${new Date()}`);
      reject(error);
    }
  });
};

//Insert a New Alert into the Database
/**
 * Inserts an alert to the database.
 * @method insertAlertToDatabase
 * @param {Object} details - The details of the alert.
 * @param {string} details.kiosk - The kiosk ID.
 * @param {string} details.message - The alert message.
 * @param {string} details.status - The status of the alert.
 * @param {string} [details.origin='kiosk'] - The origin of the alert.
 * @returns {Promise} A promise that resolves with the response from the database.
 * @throws {Error} If an error occurs while inserting the alert.

  * @memberof module:KioskAlertsTB
 */
export const insertAlertToDatabase = (details) => {
  return new Promise(async (resolve, reject) => {
    // TODO: Add KioskID_Loc to this
    const kiosk = details.kiosk.trim().toUpperCase();
    const message = details.message.trim();
    const status = details.status.trim();
    const origin = details.origin ? details.origin : 'kiosk';
    const sql = `CALL insertNewAlert('${kiosk}', '${status}', '${message}', '${origin}')`;

    try {
      const response = await pool.query(sql, JSON.stringify(details));
      if (response[0]) {
        resolve(response[0]);
      } else {
        reject(false);
      }
    } catch (error) {
      console.error(`ERROR: kioskAlertsTB.insertAlertToDatabase: ${error} - ${new Date()}`);
      reject(error);
    }
  });
};

//Update what step an alert is at
/**
 * Updates the step alert is at in the kiosk_alerts table.
 * @method updateStepToAlert
 * @param {Object} alertObject - The alert object containing the properties to update.
 * @param {string} alertObject.StepAlertIsAt - The step alert is at value to update.
 * @param {number} alertObject.AlertID - The ID of the alert to update.
 * @returns {Promise} A promise that resolves with the response from the database query.
 * @throws {Error} If an error occurs while updating the step alert.
  * @memberof module:KioskAlertsTB
 */
export const updateStepToAlert = async (alertObject) => {
  return new Promise(async (resolve, reject) => {
    try {
      const statement = `UPDATE kiosk_alerts SET StepAlertIsAt = '${alertObject.StepAlertIsAt}', last_updated = now() WHERE AlertID = ${alertObject.AlertID}`;

      const response = await pool.query(statement);

      resolve(response);
    } catch (error) {
      console.error(`ERROR: kioskAlertsTB.updateStepToAlert: ${error} - ${new Date()}`);
      reject(error);
    }
  });
};

//Update what step an alert is at
/**
 * Updates the job ID associated with an alert.
 * @method updateJobIDWithAlert
 * @param {number} alertId - The ID of the alert.
 * @param {string} jobID - The ID of the job to be associated with the alert.
 * @returns {Promise<any>} - A promise that resolves with the response from the database.
 * @throws {Error} - If an error occurs while updating the job ID.
 * @memberof module:KioskAlertsTB
 */
export const updateJobIDWithAlert = async (alertId, jobID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const statement = `CALL updateJobIDWithAlert(${alertId}, '${jobID}');`;
      const response = await pool.query(statement);
      resolve(response);
    } catch (error) {
      console.error(`ERROR: kioskAlertsTB.updateJobIDWithAlert: ${error} - ${new Date()}`);
      reject(error);
    }
  });
};

//Insert the Wait time for an Alert to go off at and go to the next step.
/**
 * Inserts the wait time into the kiosk_alerts table.
 * @method insertWaitTime
 * @param {Object} details - The details of the wait time to be inserted.
 * @param {string} details.WaitTime - The wait time value.
 * @param {string} details.AlertID - The ID of the alert.
 * @returns {Promise<boolean>} - A promise that resolves to true if the wait time is successfully inserted, or rejects with an error if it fails.
  * @memberof module:KioskAlertsTB
 */
export const insertWaitTime = (details) => {
  return new Promise(async (resolve, reject) => {
    try {
      let WaitTime;
      if (!details.WaitTime) {
        WaitTime = `null`;
      } else {
        WaitTime = `'${details.WaitTime}'`;
      }
      const statement = `UPDATE kiosk_alerts SET WaitTime = ${WaitTime} WHERE AlertID = '${details.AlertID}';`;
      let response = await pool.query(statement);
      if (response.affectedRows === 1) {
        resolve(true);
      } else {
        reject(false);
      }
    } catch (error) {
      console.error(`ERROR: kioskAlertsTB.insertWaitTime: ${error} - ${new Date()}`);
      reject(error);
    }
  });
};

//I modified this a little. You need to also send either the Issue Description or ErrorCode into "issue" along with the "kiosk"
//Example: issue = DATAMAX STATUS NOT OK    ||   issue = Datamax Printer Issue
//Removes specific alerts for each kiosk versus removing them all now.
/**
 * Clears an alert for a specific kiosk.
 * @method clearAlert
 * @param {Object} details - The details of the alert to clear.
 * @param {string} details.kiosk - The name of the kiosk to clear the alert for.
 * @param {string} [details.issue] - The issue associated with the alert (optional).
 * @param {string} [details.origin='kiosk'] - The origin of the alert (optional, default is 'kiosk').
 * @returns {Promise} A promise that resolves with the response if the alert is cleared successfully, or rejects with an error.
  * @memberof module:KioskAlertsTB
 */
export const clearAlert = (details) => {
  return new Promise(async (resolve, reject) => {
    // we only care about the messages if the status is an "Incomplete"
    const kiosk = details.kiosk.trim().toUpperCase();
    const issue = details.issue ? details.issue : null;
    const origin = details.origin ? details.origin : 'kiosk';
    const statement = `CALL clearAlert('${kiosk}', '${issue}', @success, '${origin}');`;
    try {
      const response = await pool.query(statement);
      // if the affected rows is 1, then resolve promise
      if (response[0]) {
        resolve(response[0]);
      }
      reject(false);
    } catch (error) {
      console.error(`ERROR: kioskAlertsTB.clearAlert: ${error} - ${new Date()}`);
      reject(error);
    }
  });
};



