import mysql from 'promise-mysql';
// import env from '../../../env.config.json';
import env from '../../../env.config.json' assert { type: "json" };
// const dotenv = require('dotenv').config();
import dotenv from 'dotenv'
dotenv.config()
// console.log('process.env pool', process.env)
// creates an initial pool of 10 connections to use throughout the application
// connections get recycled after they have completed
// if more than 10 connections are needed, pool will create new connections
// multiple queries will run parellel to each other
// pool.query() Gets a connection from the pool, runs a query and then releases it back into the pool

async function createPool() {
    try {
        const pool = await mysql.createPool({
            connectionLimit: 10,
            host: env.DB.HOST,
            user: process.env.DB_USER,
            password: process.env.DB_PASSWORD,
            database: env.DB.NAME,
            port: env.DB.PORT
        });
        return pool;
    } catch (error) {
        console.error('Error creating database pool:', error);
        throw error;
    }
}

const pool = await createPool();

export default pool;
