/**
 * EmployeeCalendar component.
 * 
 * @returns {JSX.Element} The EmployeeCalendar component.
 * @category Components
 */
import React, { useEffect, useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import AlertBar from '../../components/AlertBar.js';
import { FaCalendarCheck } from 'react-icons/fa';
import { FaCalendarTimes } from 'react-icons/fa';
import LinearProgress from '@mui/material/LinearProgress';
import { FaForward } from 'react-icons/fa';
import {
	DateToYMD,
	Format24ToLocale,
	FormatDateToMDY,
	FormatLocaleTo24,
	GetLastDayOfMonth,
	GetTimeDiffInDays,
} from '../API/TextFormatingFunctions.js';
import '../../StyleSheets/Calendar.css';
import Spinner from '../../components/Spinner.js';
import Modal from '../../components/Modal.js';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useCustomContext } from '../../hoc/Context.js';
import { FormatTimeLocale, getPTO } from '../../utilities/util.js';
import env from '../../../env.config.json' assert { type: 'json' };

const TableHeaders = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const COLORS = [
	'rgb(221,160,221)',
	'rgb(176,196,222)',
	'rgb(152,251,152)',
	'rgb(219,112,147)',
	'rgb(64,224,208)',
	'rgb(255,255,0)',
	'rgb(175,238,238)',
	'rgb(176,224,230)',
	'rgb(255,192,203)',
	'rgb(250,128,114)',
	'rgb(255,218,185)',
];


const EmployeeCalendar = () => {
	const [month, date, year] = new Date()
		.toLocaleString('en-us', { month: 'long', year: 'numeric', day: 'numeric' })
		.split(' ');
	const today = new Date();
	today.setDate(1);
	const [loading, setLoading] = useState(true);
	const [pto, setPto] = useState(0);
	const [calendarMonth, setCalendarMonth] = useState([]);
	const temp_end_date = new Date();
	const [sending, setSending] = useState(false);``
	const [requestData, setRequestData] = useState([]);
	const [requestModal, setRequestModal] = useState({
		visible: false,
		data: {},
		updating: false,
		remove: {
			visible: false,
			type: 'delete',
		},
	});
	const { userState, userDispatch } = useCustomContext();
	const [selected, setSelected] = useState({
		start: DateToYMD(new Date()),
		end: DateToYMD(new Date(temp_end_date.setDate(temp_end_date.getDate() + 1))),
	});

	const [snackbar, setSnackbar] = useState({
		visible: false,
		message: '',
		severity: 'success',
	});

	const [curDate, setCurDate] = useState({
		month_num: 0,
		month: month,
		year: year,
		startIndex: today.getDay(),
		date: new Date(),
		today: new Date(),
	});

    
	useEffect(() => {
		let mounted = true;

		if (mounted) {
			onCalendarRefresh(true);
		}

		return () => {
			mounted = false;
		};
	}, [curDate.month_num, sending]);

	useEffect(() => {
		$('#request-modal').toggleClass('flex-box');
	}, [requestModal.visible]);

	useEffect(() => {
		$('#request-delete').toggleClass('flex-box');
	}, [requestModal.remove.visible]);


    /**
     * Closes the snack bar.
     */
	const handleCloseSnack = () => {
		setSnackbar((prevState) => ({
			...prevState,
			visible: false,
		}));
	};

    /**
     * Handles opening the snack bar with a given message and severity.
     * 
     * @param {Object} options - The options for opening the snack bar.
     * @param {string} options.message - The message to display in the snack bar.
     * @param {string} options.severity - The severity of the snack bar (e.g., 'success', 'error', 'warning').
     */
	const handleOpenSnack = ({ message = '', severity = 'success' }) => {
		setSnackbar((prevState) => ({
			...prevState,
			visible: true,
			message: message,
			severity: severity,
		}));
	};

    /**
     * Refreshes the employee calendar.
     * 
     * @param {boolean} load - Indicates whether to show loading state.
     * @returns {void}
     */
	const onCalendarRefresh = (load) => {
		if (load) setLoading(true);
		setRequestData([]);

		socket.emit('selectEmpCalendar', async (res) => {
			if (!res) {
				console.error(`ERROR: EmployeeCalendar.selectEmpCalendar: There was an issue calling this method`);
				setLoading(false);
			} else {
				const { accrued, time_off, actual } = await getPTO(userState.user.uid);

				socket.emit('employeeBirthdays', (birthdays) => {
					if (!res) {
						console.error(`ERROR: EmployeeCalendar.employeeBirthdays: There was an issue calling this method`);
						setLoading(false);
					} else {
						let reqs = res
							.map((req) => ({
								id: req.TimeOffID,
								uid: req.uid,
								name: req.FullName,
								start: FormatLocaleTo24(req.StartDate),
								end: FormatLocaleTo24(req.ReturnDate),
								color: null,
								status: req.Status,
								snippet: `${req.Notes.substring(0, 40)}...`,
								notes: req.Notes,
								email: req.Email,
								days: GetTimeDiffInDays(FormatLocaleTo24(req.StartDate), FormatLocaleTo24(req.ReturnDate)),
							}))
							.filter(
								(req) =>
									parseInt(req.start.split('-')[1]) == curDate.date.getMonth() + 1 &&
									parseInt(req.start.split('-')[0]) == curDate.date.getFullYear()
							);

						let bdays = birthdays
							.map((user) => ({
								id: user.UserID,
								uid: user.UserID,
								name: 'EVENT',
								start: user.Birthday ? `${formatBday(user.Birthday.split('T')[0])} 8:00:00` : '0000-00-00 00:00:00',
								end: user.Birthday ? `${formatBday(user.Birthday.split('T')[0])} 21:00:00` : '0000-00-00 00:00:00',
								color: null,
								status: 'Approved',
								snippet: `Birthday! ${user.FullName}`,
								notes: `Birthday! ${user.FullName}`,
								email: '',
								days: 1,
							}))
							.filter((req) => parseInt(req.start.split('-')[1]) == curDate.date.getMonth() + 1);

						const colors = reqs.reduce((requests, req) => {
							if (!requests[req.name]) {
								requests[req.name] = COLORS[Object.keys(requests).length];
							}
							return requests;
						}, {});

						const allReqs = [...reqs, ...bdays];
						setRequestData(allReqs);
						setPto(actual[0].actual_pto.toFixed(1));
						setCalendarMonth(generateCalendarDays(curDate.month_num + 1, allReqs, colors));
						setLoading(false);
					}
				});
			}
		});
	};

    /**
     * Formats the given date string into a specific format.
     * 
     * @param {string} dateIn - The date string to be formatted (in the format 'YYYY-MM-DD').
     * @returns {string} The formatted date string.
     */
	const formatBday = (dateIn) => {
		const [year, month, day] = dateIn.split('-');

		return `${curDate.date.getFullYear()}-${month}-${day}`;
	};

    /**
     * Generates an array of calendar days based on the current month, time off requests, and colors.
     * 
     * @param {number} cur_month - The current month.
     * @param {Array} tof - The time off requests.
     * @param {Object} colors - The colors for different types of requests.
     * @returns {Array} - The array of calendar days.
     */
	const generateCalendarDays = (cur_month, tof, colors) => {
		const days = GetLastDayOfMonth(cur_month);
		const days_prior = GetLastDayOfMonth(cur_month - 1);
		const todays_date = new Date();

		const calendarDays = [];
		let week = [];

		for (let i = 0; i < 42; i++) {
			const day_of_week = i % TableHeaders.length;

			if (i < curDate.startIndex) {
				week.push({
					day: i + 1 + days_prior - curDate.startIndex,
					type: 'prior',
				});
			} else if (i >= days + curDate.startIndex) {
				if (day_of_week !== 0) {
					week.push({
						day: i + 1 - days - curDate.startIndex,
						type: 'future',
					});
				} else {
					calendarDays.push(week);
					week = [];
					week.push({
						day: i + 1 - days - curDate.startIndex,
						type: 'future',
					});
				}
			} else if (day_of_week !== 0) {
				const date_idx = i - curDate.startIndex + 1;
				week.push({
					day: date_idx,
					type: 'current',
					requests: tof.reduce((reqs, req, index) => {
						const start_date = parseInt(req.start.split('-')[2].split(' ')[0]);
						const end_date = parseInt(req.end.split('-')[2].split(' ')[0]);
						const days_left = GetTimeDiffInDays(req.start, req.end);

						if (start_date == date_idx) {
							reqs.push({
								id: req.id,
								title:
									req.name === 'EVENT'
										? `${req.name} - ${req.notes}`
										: `${req.name} - ${Format24ToLocale(req.start)} to ${Format24ToLocale(req.end)}`,
								color: colors[req.name],
								notes:
									req.name === 'EVENT'
										? `${req.name} - ${req.notes}`
										: `${req.name} - ${Format24ToLocale(req.start)} to ${Format24ToLocale(req.end)}`,
								type: req.name !== 'EVENT' ? 'start' : null,
								slot: reqs.length,
								status: req.status,
								user: req.name,
								days: days_left,
							});
						} else if (date_idx > start_date && date_idx <= end_date) {
							reqs.push({
								id: req.id,
								title: ``,
								user: req.name,
								color: 'transparent',
								type: req.name !== 'EVENT' ? 'mid' : null,
								slot: reqs.length,
								status: req.status,
								days: parseFloat(req.days_left),
							});
						}
						return reqs;
					}, []),
					today:
						`${curDate.year}-${curDate.date.getMonth() + 1}-${date_idx}` ==
						`${todays_date.getFullYear()}-${todays_date.getMonth() + 1}-${todays_date.getDate()}`
							? true
							: false,
				});
			} else {
				// start of new week Sunday
				calendarDays.push(week);
				week = [];
				const date_idx = i - curDate.startIndex + 1;
				const days_in_week = Array(7)
					.fill(date_idx)
					.map((day, index) => day + index);

				week.push({
					day: date_idx,
					type: 'current',
					requests: tof.reduce((reqs, req, index) => {
						const start_date = parseInt(req.start.split('-')[2].split(' ')[0]);
						const end_date = parseInt(req.end.split('-')[2].split(' ')[0]);
						const days_left = GetTimeDiffInDays(req.start, req.end);

						if (start_date == date_idx) {
							reqs.push({
								id: req.id,
								title:
									req.name === 'EVENT'
										? `${req.name} - ${req.notes}`
										: `${req.name} - ${Format24ToLocale(req.start)} to ${Format24ToLocale(req.end)}`,
								notes:
									req.name === 'EVENT'
										? `${req.name} - ${req.notes}`
										: `${req.name} - ${Format24ToLocale(req.start)} to ${Format24ToLocale(req.end)}`,
								color: colors[req.name],
								type: req.name !== 'EVENT' ? 'start' : null,
								slot: reqs.length,
								status: req.status,
								user: req.name,
								days: days_left,
							});
						} else if (days_in_week.includes(end_date) && !days_in_week.includes(start_date) && end_date > start_date) {
							const days_left = GetTimeDiffInDays(
								`${curDate.year}-${curDate.date.getMonth() + 1}-${date_idx} 08:00:00`,
								req.end
							);

							reqs.push({
								id: req.id,
								title: ``,
								color: colors[req.name],
								type: req.name !== 'EVENT' ? 'end' : null,
								notes:
									req.name === 'EVENT'
										? `${req.name} - ${req.notes}`
										: `${req.name} - ${Format24ToLocale(req.start)} to ${Format24ToLocale(req.end)}`,
								slot: reqs.length,
								status: req.status,
								user: req.name,
								days: days_left,
							});
						}
						return reqs;
					}, []),
					today:
						`${curDate.year}-${curDate.date.getMonth() + 1}-${date_idx}` ==
						`${todays_date.getFullYear()}-${todays_date.getMonth() + 1}-${todays_date.getDate()}`
							? true
							: false,
				});
			}
		}

		if (week.length) {
			calendarDays.push(week);
		}

		week = null;

		return calendarDays;
	};

    /**
     * Handles the change of the month in the EmployeeCalendar component.
     * 
     * @param {Event} event - The event object triggered by the month change.
     */
	const handleMonthChange = (event) => {
		const id = event.target.id;
		const direction = id == 'pos-month' ? 1 : -1;
		let cur_date = curDate.date;
		let index = new Date(curDate.date);
		cur_date.setMonth(cur_date.getMonth() + direction);
		index.setMonth(index.getMonth() + direction);
		index.setDate(1);

		setLoading(true);
		setCurDate((prevState) => ({
			...prevState,
			startIndex: index.getDay(),
			month_num: prevState.month_num + direction,
			month: cur_date.toLocaleString('en-us', { month: 'long' }),
			year: cur_date.toLocaleString('en-us', { year: 'numeric' }),
			date: cur_date,
		}));
	};

    /**
     * Handles the change of the year in the calendar.
     * 
     * @param {Event} event - The event object triggered by the year change.
     */
	const handleYearChange = (event) => {
		const id = event.target.id;
		const direction = id == 'pos-year' ? 1 : -1;
		let cur_date = curDate.date;
		let index = new Date(curDate.date);
		cur_date.setFullYear(cur_date.getFullYear() + direction);
		index.setFullYear(index.getFullYear() + direction);
		index.setDate(1);

		setLoading(true);
		setCurDate((prevState) => ({
			...prevState,
			startIndex: index.getDay(),
			month_num: prevState.month_num + direction,
			month: cur_date.toLocaleString('en-us', { month: 'long' }),
			year: cur_date.toLocaleString('en-us', { year: 'numeric' }),
			date: cur_date,
		}));
	};

    /**
     * Renders an event based on the given request and index.
     * 
     * @param {Object} request - The request object containing event details.
     * @param {number} index - The index of the event.
     * @returns {Object|undefined} - The CSS properties for rendering the event, or undefined if the request type is neither 'end' nor 'start'.
     */
	const renderEvent = (request, index) => {
		if (request.type === 'end' || request.type === 'start') {
			return {
				width: request.days < 1 ? '100%' : `${100 * request.days}%`,
				background: request.status != 'Approved' ? 'red' : request.color,
				color: request.status != 'Approved' ? 'white' : 'black',
				top: `${request.slot * 10}px`,
			};
		}
	};

    /**
     * Handles the click event on the calendar.
     * 
     * @param {Event} event - The click event.
     * @returns {void}
     */
	const handleCalendarClick = (event) => {
		const date = event.target.id;
		const selected_date = `${curDate.date.getFullYear()}-${(curDate.date.getMonth() + 1)
			.toString()
			.padStart(2, '0')}-${date.toString().padStart(2, '0')}`;
		const date_after = new Date(selected_date);
		date_after.setDate(date_after.getDate() + 1);
		setSelected((prevState) => ({ ...prevState, start: selected_date, end: DateToYMD(date_after) }));
		userState.user.role
			? $('#admin-event-modal').toggleClass('flex-box')
			: $('#calendar-modal').toggleClass('flex-box');
	};

    /**
     * Handles the submission of a time off request.
     * 
     * @returns {void}
     */
	const handleRequestSubmission = () => {
		const start_date = document.getElementById('req-start-date').value;
		const start_time = document.getElementById('req-start-time').value;
		const end_date = document.getElementById('req-end-date').value;
		const end_time = document.getElementById('req-end-time').value;
		const reason = document.getElementById('request-notes').value;

		if (start_date && start_time && end_date && end_time && reason && pto > 0) {
			const request_start = `${FormatDateToMDY(start_date)} ${FormatTimeLocale(start_time)}`;
			const request_end = `${FormatDateToMDY(end_date)} ${FormatTimeLocale(end_time)}`;

			const req_obj = {
				FullName: userState.user.fullName,
				StartDate: request_start,
				ReturnDate: request_end,
				Notes: reason,
				PTO: true,
				Status: 'Pending',
			};

			setSending(true);
			socket.emit('insertEmpTimeOffRequest', req_obj, async (res) => {
				if (!res) {
					setSending(false);
					handleOpenSnack({
						message:
							'ERROR: The server encountered an issue submitting this request.  Please notify the developer of this issue.',
						severity: 'error',
					});
				} else {
					const status = await sendEmail(
						[userState.user.email, 'brian@registration-technology.com', 'accounting@registration-technology.com'],
						req_obj
					);

					if (status === 'Mail sent successfully') {
						setSending(false);
						handleOpenSnack({
							message: 'Success! Request submitted successfully.',
							severity: 'success',
						});

						document.getElementById('request-notes').value = '';
						$('#calendar-modal').toggleClass('flex-box');
					} else {
						setSending(false);
						handleOpenSnack({
							message:
								'ERROR: There was an issue notifying the admins about this request. Please notify the developer for this issue.',
							severity: 'error',
						});
					}
				}
			});
		} else if (pto <= 0 && document.getElementById('non-pto').checked) {
			const request_start = `${FormatDateToMDY(start_date)} ${FormatTimeLocale(start_time)}`;
			const request_end = `${FormatDateToMDY(end_date)} ${FormatTimeLocale(end_time)}`;

			const req_obj = {
				FullName: userState.user.fullName,
				StartDate: request_start,
				ReturnDate: request_end,
				Notes: reason,
				PTO: false,
				Status: 'Pending',
			};

			setSending(true);
			socket.emit('insertEmpTimeOffRequest', req_obj, async (res) => {
				if (!res) {
					setSending(false);
					handleOpenSnack({
						message:
							'ERROR: The server encountered an issue submitting this request.  Please notify the developer of this issue.',
						severity: 'error',
					});
				} else {
					const status = await sendEmail(
						[userState.user.email, 'brian@registration-technology.com', 'accounting@registration-technology.com'],
						req_obj
					);
                    // Changed from status === 200
					if (status === 'Mail sent successfully') {
						setSending(false);
						handleOpenSnack({
							message: 'Success! Request submitted successfully.',
							severity: 'success',
						});

						document.getElementById('request-notes').value = '';
						$('#calendar-modal').toggleClass('flex-box');
					} else {
						setSending(false);
						handleOpenSnack({
							message:
								'ERROR: There was an issue notifying the admins about this request. Please notify the developer for this issue.',
							severity: 'error',
						});
					}
				}
			});
		} else if (pto <= 0 && !document.getElementById('non-pto').checked) {
			handleOpenSnack({
				message:
					'WARNING: Looks like you are out of PTO. To make a time off request, you must select the "Use Non-PTO Hours" option.',
				severity: 'warning',
			});
		} else {
			handleOpenSnack({
				message: 'ERROR: Please make sure the form is filled out correctly before submitting',
				severity: 'error',
			});
		}
	};

    /**
     * Handles the creation of an event.
     * 
     * @returns {void}
     */
	const handleEventCreation = () => {
		const start_date = document.getElementById('event-start-date').value;
		const start_time = document.getElementById('event-start-time').value;
		const end_date = document.getElementById('event-end-date').value;
		const end_time = document.getElementById('event-end-time').value;
		const reason = document.getElementById('event-notes').value;

		if (start_date && start_time && end_date && end_time && reason) {
			const request_start = `${FormatDateToMDY(start_date)} ${start_time}`;
			const request_end = `${FormatDateToMDY(end_date)} ${end_time}`;

			const req_obj = {
				FullName: 'EVENT',
				StartDate: request_start,
				ReturnDate: request_end,
				Notes: reason,
				Status: 'Approved',
			};

			setSending(true);
			socket.emit('insertEmpTimeOffRequest', req_obj, async (res) => {
				if (!res) {
					setSending(false);
					handleOpenSnack({
						message:
							'ERROR: The server encountered an issue submitting this request.  Please notify the developer of this issue.',
						severity: 'error',
					});
				} else {
					onCalendarRefresh();
					handleOpenSnack({
						message: 'Success! Event created successfully.',
						severity: 'success',
					});

					document.getElementById('event-notes').value = '';
					$('#event-modal').toggleClass('flex-box');
				}
			});
		} else {
			handleOpenSnack({
				message: 'ERROR: Please make sure the form is filled out correctly before submitting',
				severity: 'error',
			});
		}
	};

    /**
     * Handles the date input event.
     * 
     * @param {Event} event - The input event object.
     * @returns {void}
     */
	const handleDateInput = (event) => {
		const val = event.target.value;
		const id = event.target.id;

		switch (id) {
			case 'req-start-date':
				setSelected((prevState) => ({ ...prevState, start: val }));
				break;
			case 'req-end-date':
				setSelected((prevState) => ({ ...prevState, end: val }));
				break;
		}
	};

	const sendEmail = async (email_chain, req_obj) => {
		return new Promise(async (resolve, reject) => {
			const to = email_chain;
			const text =
				`<div width="100%",style="text-align:center;">` +
				`<h1 style="text-align:center;">Time Off Request</h1>` +
				`${!req_obj.PTO ? `<h3>***USER IS REQUESTING TIME OFF, PLEASE CONFIRM PTO ON PAYSTUB***</h3>` : ''}` +
				`<table style="background-color:black; width: 400px;">` +
				`<tbody>` +
				`<tr><td style="background-color:bisque; width: 100px;">Employee</td><td style="background-color:white;">${req_obj.FullName}</td></tr>` +
				`<tr><td style="background-color:bisque;">Start Date</td><td style="background-color:white;">${req_obj.StartDate}</td></tr>` +
				`<tr><td style="background-color:bisque;">Return Date</td><td style="background-color:white;">${req_obj.ReturnDate}</td></tr>` +
				`<tr><td style="background-color:bisque;">Notes</td><td style="background-color:white;">${req_obj.Notes}</td></tr>` +
				`<tr><td style="background-color:bisque;">Status</td><td style="background-color:white;">${req_obj.Status}</td></tr>` +
				`</tbody>` +
				`</table >` +
				`<p><b>Please go to the console to review this request</b></p>` +
				`</div >`;

			const text_hidden =
				`<div width="100%",style="text-align:center;">` +
				`<h1 style="text-align:center;">Time Off Request</h1>` +
				`<table style="background-color:black; width: 400px;">` +
				`<tbody>` +
				`<tr><td style="background-color:bisque; width: 100px;">Employee</td><td style="background-color:white;">${req_obj.FullName}</td></tr>` +
				`<tr><td style="background-color:bisque;">Start Date</td><td style="background-color:white;">${req_obj.StartDate}</td></tr>` +
				`<tr><td style="background-color:bisque;">Return Date</td><td style="background-color:white;">${req_obj.ReturnDate}</td></tr>` +
				`<tr><td style="background-color:bisque;">Status</td><td style="background-color:white;">${req_obj.Status}</td></tr>` +
				`</tbody>` +
				`</table >` +
				`<p><b>Please go to the console to review this request</b></p>` +
				`</div >`;

			// sends primary to user and brian
			$.get('/send', { to: to, subject: `RTI Time Off Request - ${req_obj.FullName}`, text: text }, (res) => {
				if (res.includes('Ok')) {
					resolve(200);
				} else {
					resolve(res);
				}
			});
		});
	};

	const handleTimeOffEvent = () => {
		$('#admin-event-modal').toggleClass('flex-box');
		$('#calendar-modal').toggleClass('flex-box');
	};

	const handleCreateEvent = () => {
		$('#admin-event-modal').toggleClass('flex-box');
		$('#event-modal').toggleClass('flex-box');
	};

    /**
     * Handles the event when a request is opened.
     * 
     * @param {Event} event - The event object.
     * @returns {Promise<void>} - A promise that resolves when the request is opened.
     */
	const onOpenRequest = async (event) => {
		const id = event.target.id;
		const employeeTimeOffRequest = requestData.find((req) => req.id == id);
		const { accrued, time_off, actual } = await getPTO(employeeTimeOffRequest.uid);

		setPto(actual[0].actual_pto.toFixed(1));
		setRequestModal((prevState) => ({
			...prevState,
			visible: true,
			data: employeeTimeOffRequest,
		}));
		$('#admin-event-modal').toggleClass('flex-box'); // need to hide this since it is triggered from the parent div
	};

    /**
     * Closes the request modal.
     */
	const onCloseRequest = () => {
		setRequestModal((prevState) => ({
			...prevState,
			visible: false,
			data: {},
		}));
	};

    /**
     * Handles the delete request based on the given event.
     *
     * @param {string} event - The event type ('delete' or 'cancel').
     * @returns {void}
     */
	const handleDeleteRequest = (event) => {
		switch (event) {
			case 'delete':
				setRequestModal((prevState) => ({
					...prevState,
					remove: {
						...prevState.remove,
						visible: true,
						type: 'delete',
					},
				}));
				break;

			case 'cancel':
				setRequestModal((prevState) => ({
					...prevState,
					remove: {
						...prevState.remove,
						visible: true,
						type: 'cancel',
					},
				}));
				break;
		}
	};

    /**
     * Closes the delete request modal and toggles the visibility of the 'request-delete' element.
     */
	const onCloseDeleteRequest = () => {
		setRequestModal((prevState) => ({
			...prevState,
			remove: {
				...prevState.remove,
				visible: false,
			},
		}));
		$('#request-delete').toggleClass('flex-box');
	};

    /**
     * Handles the removal of a time off request.
     * 
     * @returns {void}
     */
	const onRemoveRequest = () => {
		const type = requestModal.remove.type;
		const response = document.getElementById('response-notes').value;

		const employeeTimeOffRequest = {
			StartDate: requestModal.data.start,
			ReturnDate: requestModal.data.end,
			PrevNotes: requestModal.data.notes,
			Status: type == 'delete' ? 'Deleted' : 'Cancelled',
			Response: response,
			TimeOffID: requestModal.data.id,
		};

		setRequestModal((prevState) => ({
			...prevState,
			updating: true,
		}));

		socket.emit('updateEmpTimeOffRequest', employeeTimeOffRequest, (res) => {
			if (!res) {
				console.error('ERROR: EmployeeCalendar.updateEmpTimeOffRequest: There was an error updating this request.');
				handleOpenSnack({
					message:
						'SERVER ERROR: There was an issue attempting to update this request. Please notify the developer of this issue.',
					severity: 'error',
				});
				setRequestModal((prevState) => ({
					...prevState,
					updating: false,
				}));
			} else {
				if (type == 'cancel') {
					onCloseDeleteRequest();

					const msg =
						`<div width="100%",style="text-align:center;">` +
						`<h1 style="text-align:center;">Time Off Request Cancelled</h1>` +
						`<table style="background-color:black; width: 400px;">` +
						`<tbody>` +
						`<tr><td style="background-color:bisque; width: 100px;">Employee</td><td style="background-color:white;">${requestModal.data.name}</td></tr>` +
						`<tr><td style="background-color:bisque;">Requested Start Date</td><td style="background-color:white;">${requestModal.data.start}</td></tr>` +
						`<tr><td style="background-color:bisque;">Requested Return Date</td><td style="background-color:white;">${requestModal.data.end}</td></tr>` +
						`<tr><td style="background-color:bisque;">Request Notes</td><td style="background-color:white;">${requestModal.data.notes}</td></tr>` +
						`<tr><td style="background-color:bisque;">Request Status</td><td style="background-color:white;">Cancelled</td></tr>` +
						`<tr><td style="background-color:bisque;">Response</td><td style="background-color:white;">${response}</td></tr>` +
						`</tbody>` +
						`</table >` +
						`</div >`;

					const mailOpts = {
						to: [
							requestModal.data.email,
							'brian@registration-technology.com',
							'accounting@registration-technology.com',
						],
						bcc: 'self',
						subject: `RTI Time Off Request Cancelled - ${requestModal.data.name}`,
						msg,
					};

					$.post('/api/send_email_async', { email: mailOpts }, (res) => {
						if (res.status == 'success') {
							handleOpenSnack({
								message: `Success! Request has been cancelled.`,
								severity: 'success',
							});
							setRequestModal((prevState) => ({
								...prevState,
								updating: false,
							}));

							onCloseRequest();
							onCalendarRefresh(false);
						} else {
							console.error(res.status.msg);
							handleOpenSnack({
								message: 'SERVER ERROR: There was an issue attempting to send the request notification email.',
								severity: 'error',
							});
							setRequestModal((prevState) => ({
								...prevState,
								updating: false,
							}));
						}
					});
				} else {
					handleOpenSnack({
						message: `Success! Request has been deleted.`,
						severity: 'success',
					});
					setRequestModal((prevState) => ({
						...prevState,
						updating: false,
					}));
					onCloseDeleteRequest();
					onCloseRequest();
					onCalendarRefresh(false);
				}
			}
		});
	};

    /**
     * Handles the save request for the employee time off.
     * 
     * @returns {void}
     */
	const handleSaveRequest = () => {
		const approve = document.getElementById('approve-req').checked;
		const reject = document.getElementById('reject-req').checked;
		const response = document.getElementById('response-notes').value;

		if (approve && reject) {
			handleOpenSnack({
				message: 'WARNING: You cannot select both options. Please select either approve or reject and try again.',
				severity: 'warning',
			});
		} else if (!approve && !reject) {
			handleOpenSnack({
				message: 'WARNING: You must select whether to approve or reject this request before saving.',
				severity: 'warning',
			});
		} else {
			const employeeTimeOffRequest = {
				StartDate: requestModal.data.start,
				ReturnDate: requestModal.data.end,
				PrevNotes: requestModal.data.notes,
				Status: approve ? 'Approved' : 'Rejected',
				Response: response,
				TimeOffID: requestModal.data.id,
			};

			setRequestModal((prevState) => ({
				...prevState,
				updating: true,
			}));

			socket.emit('updateEmpTimeOffRequest', employeeTimeOffRequest, (res) => {
				if (!res) {
					console.error('EmployeeCalendar.updateEmpTimeOffRequest: There was an error updating this request.');
					handleOpenSnack({
						message:
							'SERVER ERROR: There was an issue attempting to update this request. Please notify the developer of this issue.',
						severity: 'error',
					});
					setRequestModal((prevState) => ({
						...prevState,
						updating: false,
					}));
				} else {
					const msg =
						`<div width="100%",style="text-align:center;">` +
						`<h1 style="text-align:center;">Time Off Request Response</h1>` +
						`<table style="background-color:black; width: 400px;">` +
						`<tbody>` +
						`<tr><td style="background-color:bisque; width: 100px;">Employee</td><td style="background-color:white;">${requestModal.data.name}</td></tr>` +
						`<tr><td style="background-color:bisque;">Requested Start Date</td><td style="background-color:white;">${requestModal.data.start}</td></tr>` +
						`<tr><td style="background-color:bisque;">Requested Return Date</td><td style="background-color:white;">${requestModal.data.end}</td></tr>` +
						`<tr><td style="background-color:bisque;">Request Notes</td><td style="background-color:white;">${requestModal.data.notes}</td></tr>` +
						`<tr><td style="background-color:bisque;">Request Status</td><td style="background-color:white;">${
							approve ? 'Approved' : 'Rejected'
						}</td></tr>` +
						`<tr><td style="background-color:bisque;">Response</td><td style="background-color:white;">${response}</td></tr>` +
						`</tbody>` +
						`</table >` +
						`</div >`;

					const mailOpts = {
						to: [
							requestModal.data.email,
							'brian@registration-technology.com',
							'accounting@registration-technology.com',
						],
						bcc: 'self',
						subject: `RTI Time Off Request Response - ${requestModal.data.name}`,
						msg,
					};

					$.post('/api/send_email_async', { email: mailOpts }, (res) => {
						if (res.status == 'success') {
							handleOpenSnack({
								message: `Success! Request has been ${approve ? 'approved' : 'rejected'}.`,
								severity: 'success',
							});
							setRequestModal((prevState) => ({
								...prevState,
								updating: false,
							}));
							onCalendarRefresh(false);
							onCloseRequest();
						} else {
							console.error(res.status.msg);
							handleOpenSnack({
								message: 'SERVER ERROR: There was an issue attempting to send the request notification email.',
								severity: 'error',
							});
							setRequestModal((prevState) => ({
								...prevState,
								updating: false,
							}));
						}
					});
				}
			});
		}
	};

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'column',
				alignItems: 'center',
				marginBottom: 100,
			}}
		>
			<Modal modal_id="calendar-modal" dimension={{ width: 434 }} modal_header="Time Off Request">
				<div style={{ textAlign: 'center' }}>
					<p>
						Please enter the required information about your time off request. You will receive an email once your
						request is approved.
					</p>
					<h3>Available Paid Time Off: {pto} Hours</h3>
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<div style={{ display: 'flex', alignItems: 'baseline', margin: '20px 0px' }}>
							<TextField
								id="req-start-date"
								className="table-date-input"
								label="Start"
								type="date"
								value={selected.start}
								onChange={handleDateInput}
							/>
							<TextField id="req-start-time" className="table-date-input" defaultValue={`08:00:00`} type="time" />
						</div>
						<div style={{ display: 'flex', alignItems: 'baseline', margin: '20px 0px' }}>
							<TextField
								id="req-end-date"
								className="table-date-input"
								label="End"
								value={selected.end}
								type="date"
								onChange={handleDateInput}
							/>
							<TextField id="req-end-time" className="table-date-input" defaultValue={`08:00:00`} type="time" />
						</div>
						{pto <= 0 ? (
							<div>
								<FormControlLabel control={<Checkbox id="non-pto" color="primary" />} label="Use Non-PTO Hours" />
							</div>
						) : null}
					</div>
					<div className="log-inner-container" style={{ textAlign: 'left', marginTop: 26, display: 'block' }}>
						<label>Reason</label>
						<br></br>
						<textarea id="request-notes"></textarea>
					</div>
					{!sending ? (
						<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
							<Button className="primary" variant="text" onClick={handleRequestSubmission}>
								Submit
							</Button>
						</div>
					) : (
						<div>
							<label>Sending...</label>
							<br></br>
							<LinearProgress />
						</div>
					)}
				</div>
			</Modal>

			{userState.user.role ? (
				<Modal modal_id="event-modal" dimension={{ width: 434 }} modal_header="Create Event/Holiday">
					<div style={{ textAlign: 'center' }}>
						<p>
							Please fill out the required information about this holiday/event. Once submitted, this event will appear
							on the calendar.
						</p>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							<div style={{ display: 'flex', alignItems: 'baseline', margin: '20px 0px' }}>
								<TextField
									id="event-start-date"
									className="table-date-input"
									label="Start"
									type="date"
									value={selected.start}
									onChange={handleDateInput}
								/>
								<TextField id="event-start-time" className="table-date-input" defaultValue={`08:00:00`} type="time" />
							</div>
							<div style={{ display: 'flex', alignItems: 'baseline', margin: '20px 0px' }}>
								<TextField
									id="event-end-date"
									className="table-date-input"
									label="End"
									value={selected.end}
									type="date"
									onChange={handleDateInput}
								/>
								<TextField id="event-end-time" className="table-date-input" defaultValue={`08:00:00`} type="time" />
							</div>
						</div>
						<div className="log-inner-container" style={{ textAlign: 'left', marginTop: 26, display: 'block' }}>
							<label>Event Details</label>
							<br></br>
							<textarea id="event-notes" placeholder="ie. Christmas or Company Lunch"></textarea>
						</div>
						{!sending ? (
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Button className="primary" variant="text" onClick={handleEventCreation}>
									Create
								</Button>
							</div>
						) : (
							<div>
								<label>Sending...</label>
								<br></br>
								<LinearProgress />
							</div>
						)}
					</div>
				</Modal>
			) : null}

			{userState.user.role ? (
				<Modal modal_id="admin-event-modal" dimension={{ width: 434 }} modal_header="Choose an Event">
					<div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
						<div style={{ margin: 'auto' }}>
							<h3>Event/Holiday</h3>
							<IconButton onClick={handleCreateEvent} size="large">
								<FaCalendarCheck
 className="large-icon-button" fontSize="large" />
							</IconButton>
						</div>
						<div style={{ margin: 'auto' }}>
							<h3>Time Off</h3>
							<IconButton onClick={handleTimeOffEvent} size="large">
								<FaCalendarTimes className="large-icon-button" fontSize="large" />
							</IconButton>
						</div>
					</div>
				</Modal>
			) : null}

			{requestModal.visible ? (
				<Modal
					modal_id="request-modal"
					dimension={{ width: 434 }}
					modal_header="Time Off Request"
					onClose={onCloseRequest}
				>
					<div>
						<div className="flex-just-between">
							<div>
								<h4>Employee</h4>
								<p>{requestModal.data.name}</p>
							</div>
						</div>
						<div className="flex-just-between">
							<div>
								<h4>Start Date</h4>
								<p>{requestModal.data.start}</p>
							</div>
							<div>
								<h4>Return Date</h4>
								<p>{requestModal.data.end}</p>
							</div>
						</div>
						<div className="flex-just-between">
							<div>
								<h4>Available Time Off</h4>
								<p>{pto} Hours</p>
							</div>
						</div>
						<div>
							<h4>Reason</h4>
							<p>{requestModal.data.notes}</p>
						</div>
						<Divider />
						<div className="vert-margin">
							<FormControlLabel
								control={
									<Checkbox
										disabled={requestModal.data.status == 'Approved' ? true : false}
										defaultChecked={requestModal.data.status == 'Approved' ? true : false}
										id="approve-req"
										color="primary"
									/>
								}
								label="Approve"
							/>
							<FormControlLabel
								control={
									<Checkbox
										disabled={requestModal.data.status == 'Approved' ? true : false}
										defaultChecked={requestModal.data.status == 'Rejected' ? true : false}
										id="reject-req"
										color="primary"
									/>
								}
								label="Reject"
							/>
						</div>
						<div className="vert-margin">
							<label>Your Notes</label>
							<br></br>
							<textarea
								style={{ width: '100%', height: 80 }}
								disabled={requestModal.data.status == 'Approved' ? true : false}
								id="response-notes"
								defaultValue={requestModal.data.ResponseNotes}
							></textarea>
						</div>
						{!requestModal.updating ? (
							<div className="flex-end vert-margin">
								<Button id="delete-req" variant="text" color="secondary" onClick={() => handleDeleteRequest('delete')}>
									Delete Request
								</Button>
								<Divider orientation="vertical" flexItem />
								<Button id="cancel-req" variant="text" color="secondary" onClick={() => handleDeleteRequest('cancel')}>
									Cancel Request
								</Button>
								<Divider orientation="vertical" flexItem />
								<Button variant="text" color="primary" onClick={handleSaveRequest}>
									Save
								</Button>
							</div>
						) : (
							<div>
								<label>Updating request...</label>
								<br></br>
								<LinearProgress />
							</div>
						)}
					</div>
				</Modal>
			) : null}

			{requestModal.remove.visible ? (
				<Modal
					modal_id="request-delete"
					dimension={{ width: 434 }}
					modal_header={`${requestModal.remove.type == 'delete' ? 'Delete' : 'Cancel'} Request`}
					onClose={onCloseDeleteRequest}
				>
					<div>
						<h2>Are you sure you want to {requestModal.remove.type == 'delete' ? 'delete' : 'cancel'} this request?</h2>
						<div className="flex-just-even vert-margin-med">
							<Button className="rti-blue-round" variant="contained" onClick={onCloseDeleteRequest}>
								No
							</Button>
							<Button className="rti-blue-round" variant="contained" onClick={onRemoveRequest}>
								Yes
							</Button>
						</div>
					</div>
				</Modal>
			) : null}

			<AlertBar
				visible={snackbar.visible}
				message={snackbar.message}
				severity={snackbar.severity}
				onClose={handleCloseSnack}
			/>

			<div className="TitleBarContainer">
				<h1 title="Calendar">Calendar</h1>
			</div>
			<Paper style={{ width: '98%' }} elevation={4}>
				<div
					id="calendar-header"
					style={{
						height: 70,
						color: 'white',
						backgroundColor: 'royalblue',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<span style={{ display: 'flex', justifyContent: 'space-between', width: '36%' }}>
						<IconButton id="minus-year" onClick={handleYearChange} size="large">
							<FaForward id="minus-year" style={{ color: 'white', transform: 'rotate(180deg)' }} />
						</IconButton>
						<IconButton id="minus-month" onClick={handleMonthChange} size="large">
							<FaArrowAltCircleLeft id="minus-month" style={{ color: 'white' }} />
						</IconButton>
						<h1 style={{ textAlign: 'center', margin: 0 }}>{`${curDate.month} ${curDate.year}`}</h1>
						<IconButton id="pos-month" onClick={handleMonthChange} size="large">
							<FaArrowAltCircleRight id="pos-month" style={{ color: 'white' }} />
						</IconButton>
						<IconButton id="pos-year" onClick={handleYearChange} size="large">
							<FaForward id="pos-year" style={{ color: 'white' }} />
						</IconButton>
					</span>
				</div>
				<div id="calendar-body">
					<TableContainer style={{ maxHeight: 700 }} className="table-data-container no-overflow">
						<Table>
							<TableHead>
								<TableRow style={{ backgroundColor: '#4a77ff' }}>
									{TableHeaders.map((header) => (
										<TableCell key={header} style={{ color: 'white' }} align="center">
											{header}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{!loading ? (
									calendarMonth.map((week, idx) => (
										<TableRow key={idx}>
											{week.map((day, index) => (
												<TableCell
													id={day.day}
													onClick={day.type === 'current' ? handleCalendarClick : null}
													className={`calendar-cell ${day.today ? 'today' : ''} ${
														day.type !== 'current' ? 'faded-cell' : ''
													}`}
													key={`${day.day}-${index}`}
													align="center"
												>
													<div
														key={`${day.day}-${index}`}
														id={day.day}
														className={`calendar-block ${
															day.requests && day.requests.length && day.requests[0].title.includes('EVENT')
																? 'block-event'
																: ''
														}`}
													>
														<div
															key={`${day.day}-${index}`}
															id={day.day}
															className={`inner-block-container ${
																day.requests && day.requests.length && day.requests[0].title.includes('EVENT')
																	? ''
																	: 'align-start'
															}`}
														>
															<div key={`${day.day}-${index}`} id={day.day} style={{ width: '100%' }}>
																<label
																	key={`${day.day}-${index}`}
																	className={`calendar-date ${day.type !== 'current' ? 'faded' : ''}`}
																>
																	{day.day}
																</label>
															</div>
															{day.requests && day.requests.length
																? day.requests.map((req, req_idx) => {
																		return (
																			<Tooltip
																				key={`${req.title}-${req_idx}`}
																				title={<p style={{ fontSize: 12 }}>{req.notes}</p>}
																			>
																				<div
																					key={`${req.title}-${req_idx}`}
																					id={req.id}
																					style={renderEvent(req, index)}
																					className={
																						req.type === 'start'
																							? 'block-start'
																							: req.type === 'end'
																							? 'block-end'
																							: null
																					}
																					onClick={
																						userState.user.permissions == env.USER_PERMS[0] ? onOpenRequest : null
																					}
																				>
																					<div id={req.id} key={`${req.title}-${req_idx}`}>
																						<label
																							id={req.id}
																							key={`${req.title}-${req_idx}`}
																							style={{ padding: 6 }}
																						>{`${
																							req.title && req.status != 'Approved' ? req.status.toUpperCase() : ''
																						} ${req.title}`}</label>
																					</div>
																				</div>
																			</Tooltip>
																		);
																  })
																: null}
														</div>
													</div>
												</TableCell>
											))}
										</TableRow>
									))
								) : (
									<TableRow key={0}>
										<TableCell key={1} align="center" colSpan={TableHeaders.length}>
											<div key={1}>
												<Spinner />
											</div>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			</Paper>
		</div>
	);
};

export default EmployeeCalendar;
