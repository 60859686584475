import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SearchBar from './SearchBar';
import TableActions from './TableActions';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import Spinner from './Spinner';
import '../StyleSheets/Table.css';
import '../StyleSheets/Button.css';

const CustomTable = ({
  headers = [],
  secondary_headers = [],
  rows = [],
  tabs = [],
  maxSize = '',
  rpp = 100,
  dates = null,
  tab_style = 'select',
  loading_data = false,
  active_tab = '',
  table_buttons,
  paginate = false,
  searchable = false,
  select_only = false,
  search,
  clear_search,
  search_options = [],
  filter_data,
  noMargin,
  onClick,
  onLimitDate,
  onRefresh,
}) => {
  const rowsPer = paginate && rpp ? rpp : paginate && !rpp ? 100 : rows.length;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPer);
  const [orderBy, setOrderBy] = useState({ id: 0, idx: 0, numeric: null });
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };

  const handleSearch = (param, searchVal) => {
    setPage(0);
    search(param, searchVal);
  };

  const handleTableFilter = (filter) => {
    setPage(0);
    filter_data(filter);
  };

  const handleLimitDate = () => {
    setPage(0);
    const after = document.getElementById('date-input-after').value;
    const before = document.getElementById('date-input-before').value;
    onLimitDate(after, before);
  };

  const handleSortOrder = (id, idx, numeric, time) => {
    setOrderBy((prevState) => ({ ...prevState, id, idx, numeric, time }));
    setOrder((prevState) => (prevState === 'asc' && orderBy.id === id ? 'desc' : 'asc'));
  };

  function sortRows(rows) {
    if (orderBy.id) {
      if (orderBy.numeric) {
        return rows.sort((a, b) =>
          order === 'desc'
            ? b.cells[orderBy.idx].data - a.cells[orderBy.idx].data
            : a.cells[orderBy.idx].data - b.cells[orderBy.idx].data
        );
      } else if (orderBy.time) {
        return rows.sort((a, b) =>
          order === 'desc'
            ? new Date(b.cells[orderBy.idx].data).getTime() - new Date(a.cells[orderBy.idx].data).getTime()
            : new Date(a.cells[orderBy.idx].data).getTime() - new Date(b.cells[orderBy.idx].data).getTime()
        );
      } else {
        return rows.sort((a, b) => {
          if (order === 'desc') {
            return b.cells[orderBy.idx].data < a.cells[orderBy.idx].data
              ? -1
              : b.cells[orderBy.idx].data > a.cells[orderBy.idx].data
              ? 1
              : 0;
          } else {
            return a.cells[orderBy.idx].data < b.cells[orderBy.idx].data
              ? -1
              : a.cells[orderBy.idx].data > b.cells[orderBy.idx].data
              ? 1
              : 0;
          }
        });
      }
    }
    return rows;
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelected = (name) => {
    if (isSelected(name)) {
      const copySelected = [...selected];
      copySelected.splice(selected.indexOf(name), 1);
      setSelected(copySelected);
    } else {
      setSelected((prevState) => [...prevState, name]);
    }
  };

  const handleCheckAll = () => {
    const checked = !checkAll;
    if (checked) {
      setCheckAll(checked);
      setSelected(rows.map((row) => row.cells[0].data));
    } else {
      setCheckAll(checked);
      setSelected([]);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      {searchable || dates ? (
        <SearchBar
          clear_search={clear_search}
          search={handleSearch}
          dates={dates}
          options={search_options}
          tabs={tabs}
          tab_style={tab_style}
          active_tab={active_tab}
          onLimitDate={handleLimitDate}
          filter_table={handleTableFilter}
          select_only={select_only}
        />
      ) : null}
      <Paper
        elevation={2}
        className={`table-main-container ${!searchable && !dates ? 'no-search' : ''} ${noMargin ? 'no-margin' : ''}`}
      >
        <TableContainer className={`table-data-container ${maxSize}`}>
          <Table>
            <TableHead className="table-header-sticky">
              <TableRow className="table-headers">
                {headers.map((header, idx) =>
                  header.sortable ? (
                    <TableCell
                      key={header.id}
                      className={`sortable-header ${header.size ? header.size : ''}`}
                      sortDirection={orderBy.id === header.id ? order : false}
                      colSpan={secondary_headers.length ? 2 : 1}
                      align="center"
                    >
                      <TableSortLabel
                        active={orderBy.id === header.id}
                        direction={orderBy.id === header.id ? order : 'asc'}
                        onClick={() => handleSortOrder(header.id, idx, header.numeric, header.time)}
                      >
                        {header.name}
                      </TableSortLabel>
                    </TableCell>
                  ) : (
                    <TableCell
                      key={header.id}
                      className={header.size ? header.size : null}
                      colSpan={secondary_headers.length ? 2 : 1}
                      align="center"
                    >
                      {header.type != 'checkbox' ? (
                        header.name
                      ) : (
                        <Checkbox
                          color="primary"
                          name="table-all-check"
                          id="table-all-check"
                          checked={checkAll}
                          onChange={handleCheckAll}
                        />
                      )}
                    </TableCell>
                  )
                )}
              </TableRow>
              {secondary_headers.length ? (
                <TableRow className="table-headers">
                  {secondary_headers.map((header) => (
                    <TableCell key={header.id} align="center">
                      {header.name}
                    </TableCell>
                  ))}
                </TableRow>
              ) : null}
            </TableHead>
            <TableBody className="table-body-container">
              {!loading_data ? (
                sortRows(rows)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, rowIndex) => (
                    <TableRow className={row.color} key={row.key || `row-${rowIndex}`} id={row.key} onClick={onClick}>
                      {row.cells.map((cell, cellIndex) =>
                        !Array.isArray(cell.data) && cell.type !== 'checkbox' ? (
                          <TableCell
                            key={`${row.key || `row-${rowIndex}`}-${cellIndex}`}
                            colSpan={cell.span ? cell.span : null}
                            id={cell.id ? cell.id : row.key}
                            align="center"
                            className={`table-cell ${cell.color}`}
                          >
                            {cell.data}
                          </TableCell>
                        ) : !Array.isArray(cell.data) && cell.type === 'checkbox' ? (
                          <TableCell
                            key={`${row.key || `row-${rowIndex}`}-${cellIndex}`}
                            colSpan={cell.span ? cell.span : null}
                            id={cell.id ? cell.id : row.key}
                            align="center"
                            className={cell.color}
                          >
                            <Checkbox
                              id={cell.data}
                              name="selected-table-checkbox"
                              color="primary"
                              checked={isSelected(cell.data)}
                              onChange={() => handleSelected(cell.data)}
                            />
                          </TableCell>
                        ) : (
                          cell.data.map((subcell, subcellIndex) => (
                            <TableCell
                              key={`${subcell.id || `subcell-${subcellIndex}`}`}
                              id={subcell.id}
                              align="center"
                            >
                              <input
                                id={subcell.id}
                                className={subcell.name ? subcell.name : 'table-box'}
                                type="checkbox"
                                defaultChecked={subcell.checked}
                              ></input>
                            </TableCell>
                          ))
                        )
                      )}
                    </TableRow>
                  ))
              ) : (
                <TableRow key={0}>
                  <TableCell key={1} align="center" colSpan={headers.length}>
                    <div>
                      <Spinner />
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TableActions
          table_buttons={table_buttons}
          paginate={paginate}
          length={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPage}
          onRefresh={onRefresh}
        />
      </Paper>
    </div>
  );
};

export default CustomTable;
