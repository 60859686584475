import React from "react";
import { Navigate } from "react-router-dom";

const Auth = (props) => {
  const Authenticated = () => {
    let data = window.sessionStorage.getItem("techName");

    if (data !== null) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      {Authenticated() ? props.children : <Navigate to="/tech-portal/login" />}
    </div>
  );
};

export default Auth;
