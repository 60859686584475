import React, { useEffect, useState } from 'react';
import RTILogo from '../public/Images/regitechLogo.png';
import { CreateJob } from './imports/CreateJobUI/CreateJob.js';
import TimeOffRequest from './imports/TechTimeOffUI/TimeOffRequestDialog.js';
import KioskAlerts from './imports/KioskAlertsUI/KioskAlerts.js';
import TimeEntry from './imports/TimeEntryUI/TimeEntry.js';
import ChatModal from './imports/Chat/ChatModal';
import ReportModal from './components/ReportModal';
import AlertBar from './components/AlertBar';
import { useCustomContext } from './hoc/Context';
import { FormatLocalDateTimeFromMonth, GetFriday, DateToYMD } from './imports/API/TextFormatingFunctions.js';
import './StyleSheets/App.css';
import { version } from '../package.json';
import { useNavigate } from 'react-router-dom';
import DatabaseErrorLogger from './components/DatabaseErrorLogger.js';
import dotenv from 'dotenv';
dotenv.config();

const App = (props) => {
  const navigate = useNavigate();

  const [kioskIDIssueDes, setKioskIDIssueDes] = useState(null);
  const [IssueDescription, setIssueDescription] = useState([]);
  const [AlertLength, setAlertLength] = useState(null);
  const [TechnicianList, setTechnicianList] = useState([]);
  const [kiosks, setKiosks] = useState([]);
  const [KioskID, setKioskID] = useState([]);
  const [KioskServerID, setKioskServerID] = useState([]);
  const [IssuesDB, setIssuesDB] = useState([]);
  const [KiosksDB, setKiosksDB] = useState([]);
  const [MobileEmailListsDB, setMobileEmailListsDB] = useState([]);
  const [TimeEntryDB, setTimeEntryDB] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [snackbar, setSnackbar] = useState({
    visible: false,
    message: '',
    severity: 'success',
  });
  const [allAlerts, setAllAlerts] = useState([]);
  const [MapDriveTimes, setMapDriveTimes] = useState([]);
  const [CallLogHistory, setCallLogHistory] = useState([]);
  const [RepurposeStickers, setRepurposeStickers] = useState([]);

  const { userState, userDispatch } = useCustomContext();

  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      PullDataFromDatabase();
    }

    return () => {
      mounted = false;
    };
  }, []);

  const setPage = (e) => {
    const path = e.target.name;
    const current_path = window.location.pathname.split('/')[1];

    if (path !== '') {
      // unsubscribe from job socket.
      socket.off('refreshJobPage');
    }

    if (path === current_path) {
      // window.location.reload();
      navigate(0);
    } else {
      // props.history.push(`/${path}`);
      navigate(`/${path}`);

      OpenNavigationBar('close');
      HideDropDown();
    }
  };

  const SetKioskIDIssueForCreateJob = (kioskIDissue, alertLength) => {
    setKioskIDIssueDes(kioskIDissue);
    setAlertLength(alertLength);
  };

  //Pulls A bunch of data from the database and passes each to pages where they are needed
  function PullDataFromDatabase() {
    const today = DateToYMD(new Date());
    let prior = new Date();
    prior.setFullYear(prior.getFullYear() - 1);
    prior = DateToYMD(prior);

    //Get the Kiosk ID and Server ID
    socket.emit('selectFromKiosksForCreateJob', (result) => {
      const kiosks = result.map(function (obj, index) {
        const rObj = obj.KioskID + ' ' + obj.ServerID;
        return rObj;
      });

      const KioskID = result.map(function (obj, index) {
        const rObj = obj.KioskID;
        return rObj;
      });

      const KioskServerID = result.reduce(function (newArray, currentArray) {
        newArray[currentArray.KioskID] = currentArray.ServerID;
        return newArray;
      }, {});

      setKioskID(KioskID);
      setKioskServerID(KioskServerID);
      setKiosks(kiosks);
    });

    //Get the list of possible kiosk issues
    socket.emit('selectFromIssuesForCreateJob', (result) => {
      setIssueDescription(result);
    });

    //Get a list of Technicians
    socket.emit('selectFromTechnicianNamesList', (result) => {
      setTechnicianList(
        JSON.parse('[' + JSON.stringify(result).replace(/[\:\(\)\[\]\{\}]|("TechnicianName")/g, '') + ']')
      );
    });

    socket.emit('selectFromKiosksIssuesForTM', (result) => {
      setIssuesDB(result);
    });

    socket.emit('selectFromKiosksForTM', (result) => {
      try {
        setKiosksDB(result);
      } catch (error) {
        console.error(`ERROR: App.PullDataFromDatabase: ${error} - ${new Date()}`);
      }
    });

    socket.emit('selectMobileEmailLists', (result) => {
      setMobileEmailListsDB(result);
    });

    socket.emit('selectUserEmail', (result) => {
      setAllUsers(result);
    });

    socket.emit('selectAllAlerts', prior, today, (result) => {
      setAllAlerts(result);
    });

    // could be used on modal load
    socket.emit('selectAllMapDriveTimes', (result) => {
      setMapDriveTimes(result);
    });

    //Since the dates can be changed, this is a different function but
    // it pulls the Time Entrys between the requested dates.
    // Limits it to the current pay week by defualt
    PullTimeEntry(GetFriday('previous'), GetFriday('next'));
    GetLogsBetweenDates();
  }

  function GetLogsBetweenDates() {
    const daysPrior = 30;
    let today = new Date();
    // get date 30 days prior to today for db procedure
    const present = new Date().setDate(today.getDate() - daysPrior);
    let prior = new Date(present);

    today = today.toString();
    prior = prior.toString();

    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    today = today.split(' ');
    prior = prior.split(' ');

    let monthToday = month.indexOf(today[1]) + 1;

    let monthPrior = month.indexOf(prior[1]) + 1;

    if (month.indexOf(prior[1]) + 1 < 10) {
      monthPrior = '0' + monthPrior;
    }

    if (month.indexOf(today[1]) + 1 < 10) {
      monthToday = '0' + monthToday;
    }

    today = today[3] + '-' + monthToday + '-' + today[2]; // converts to yyyy-mm-dd format

    prior = prior[3] + '-' + monthPrior + '-' + prior[2];

    const obj = {
      Prior: prior,
      Today: today,
    };

    socket.emit('getDaysPriorScript', obj, (result) => {
      setCallLogHistory(result);
    });
  }

  function PullTimeEntry(afterDate, beforeDate) {
    socket.emit('selectDatesFromTimeEntry', afterDate, beforeDate, (result) => {
      // + " 05:00:00"
      let results = [];
      for (let i = 0; i < result.length; i++) {
        results.push({
          FullName: result[i].FullName,
          Notes: result[i].Notes,
          PunchIn: FormatLocalDateTimeFromMonth(result[i].PunchIn),
          PunchOut: FormatLocalDateTimeFromMonth(result[i].PunchOut),
          TimeEntryID: result[i].TimeEntryID,
          WorkTimeTotal: result[i].WorkTimeTotal,
        });
      }
      setTimeEntryDB(results);
    });
  }

  const OpenNavigationBar = (step) => {
    let navi = document.getElementById('SmallNavigationBarDiv');
    if (navi.style.width == '200px' || step === 'close') {
      document.getElementById('SmallNavigationBarDiv').style.width = '0';
    } else {
      document.getElementById('SmallNavigationBarDiv').style.width = '200px';
    }
  };

  //Open the letious Dialog Windows
  const DialogOpen = (e) => {
    switch (e.target.name) {
      case 'AllChat':
        $('#AllChatDialog').show();
        $('#AllChatDialog').draggable();
        break;
      case 'BugReport':
        $('#BugReportDialog').show();
        $('#BugReportDialog').draggable();
        document.getElementById('BugReportSendBtn').disabled = false;
        OpenNavigationBar('close');
        break;
      case 'CustomerCallLog':
        $('#CustomerCallLogDialog').show();
        $('#CustomerCallLogDialog').draggable();
        document.getElementById('CustomerCallLogSendBtn').disabled = false;
        OpenNavigationBar('close');
        break;
      case 'TechnicianCallLog':
        $('#TechnicianCallLogDialog').show();
        $('#TechnicianCallLogDialog').draggable();
        document.getElementById('TechnicianCallLogSendBtn').disabled = false;
        OpenNavigationBar('close');
        break;
      case 'StoreCallLog':
        $('#StoreCallLogDialog').show();
        $('#StoreCallLogDialog').draggable();
        document.getElementById('StoreCallLogSendBtn').disabled = false;
        OpenNavigationBar('close');
        break;
      case 'CreateJob':
        $('#CreateJobDialog').show();
        $('#CreateJobDialog').draggable();
        document.getElementById('CreateButton').disabled = false;
        OpenNavigationBar('close');
        break;
      case 'TimeEntry':
        $('#TimeEntryDialog').show();
        $('#TimeEntryDialog').draggable();
        document.getElementById('TimeEntrySendBtn').disabled = false;
        OpenNavigationBar('close');
        break;
      case 'LogOut':
        $('#EndOfShiftReportDialog').show();
        $('#EndOfShiftReportDialog').draggable();
        document.getElementById('SubmitReportBtn').disabled = false;
        OpenNavigationBar('close');
        break;
      case 'EmpTimeOffRequest':
        $('#EmpTimeOffRequestDialog').show();
        $('#EmpTimeOffRequestDialog').draggable();
        document.getElementById('AddNewItemBtn').disabled = false;
        OpenNavigationBar('close');
        break;
    }

    HideDropDown();
  };

  function HideDropDown() {
    let dropdowns = document.getElementsByClassName('NaviMenuDropdownDiv');

    for (let i = 0; i < dropdowns.length; i++) {
      let openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('show')) {
        openDropdown.classList.remove('show');
      }
    }
  }

  const DropDownMenu = (e, id) => {
    let dropdowns = document.getElementsByClassName('NaviMenuDropdownDiv');
    let i;
    for (i = 0; i < dropdowns.length; i++) {
      let openDropdown = dropdowns[i];
      if (dropdowns[i] != document.getElementById(id)) {
        openDropdown.classList.remove('show');
      }
    }
    document.getElementById(id).classList.toggle('show');
  };

  const handleCloseSnack = () => {
    setSnackbar((prevState) => ({
      ...prevState,
      visible: false,
    }));
  };

  const handleOpenSnack = ({ message = '', severity = 'success' }) => {
    setSnackbar((prevState) => ({
      ...prevState,
      visible: true,
      message: message,
      severity: severity,
    }));
  };

  const EndSession = () => {
    localStorage.removeItem('FullName');
    localStorage.removeItem('user');
    localStorage.removeItem('local-dispatch-console-user');
    window.location.reload();
  };

  return (
    <div className="MasterWrapper">
      <div className="RTIImageDiv" id="non-printable">
        <a href="/" className="home-logo">
          <img className="RTILogoImage" name="RTILogo" src={RTILogo} alt="" />
        </a>
      </div>
      <button className="NavigationMenuButton" onClick={OpenNavigationBar}>
        <div className="MenuButtonDecor"></div>
        <div className="MenuButtonDecor"></div>
        <div className="MenuButtonDecor"></div>
      </button>

      <div className="NavigationBarDiv" id="SmallNavigationBarDiv">
        <button className="NavigationBarButtons" name="" onClick={setPage}>
          JOBS
        </button>
        {userState.user.access ? (
          <button className="NavigationBarButtons" name="CreateJob" onClick={DialogOpen}>
            DISPATCH A JOB
          </button>
        ) : null}
        {userState.user.role ? (
          <button className="NavigationBarButtons" name="paper" onClick={setPage}>
            PAPER
          </button>
        ) : undefined}
        {userState.user.access ? (
          <button
            className="NavigationBarButtons"
            id="submitReports"
            onClick={() => {
              OpenNavigationBar('close');
              $('#report-modal-container').toggle();
            }}
          >
            LOGS
          </button>
        ) : null}
        {userState.user.access ? (
          <button className="NavigationBarButtons" name="login" onClick={setPage}>
            INVENTORY
          </button>
        ) : null}
        {userState.user.access ? (
          <button className="NavigationBarButtons" name="service" onClick={setPage}>
            SERVICE REPORT
          </button>
        ) : null}
        {userState.user.access ? (
          <button className="NavigationBarButtons" name="map" onClick={setPage}>
            MAP
          </button>
        ) : null}
        {userState.user.access ? (
          <button
            className="NavigationBarButtons"
            id="tableManagement"
            onClick={(e) => DropDownMenu(e, 'dropdown-tables-small')}
          >
            TABLES
          </button>
        ) : null}

        <div className="NaviMenuDropdownDiv" id="dropdown-tables-small">
          <button className="NaviMenuDropdownButtons" name="alert_analytics" onClick={setPage}>
            ALERT ANALYTICS
          </button>
          <button className="NaviMenuDropdownButtons" name="alerts_table" onClick={setPage}>
            ALERTS
          </button>
          <button className="NaviMenuDropdownButtons" name="call_logs" onClick={setPage}>
            CALL LOGS
          </button>
          <button className="NaviMenuDropdownButtons" name="drive_times" onClick={setPage}>
            DRIVE TIMES
          </button>
          <button className="NaviMenuDropdownButtons" name="issue_tracking" onClick={setPage}>
            ISSUE TRACKING
          </button>
          {userState.user.role ? (
            <button className="NaviMenuDropdownButtons" name="kiosk_issues" onClick={setPage}>
              ISSUES
            </button>
          ) : undefined}
          {userState.user.role ? (
            <button className="NaviMenuDropdownButtons" name="job_responses" onClick={setPage}>
              JOB RESPONSES
            </button>
          ) : undefined}
          <button className="NaviMenuDropdownButtons" name="kiosk_analytics" onClick={setPage}>
            KIOSK ANALYTICS
          </button>
          <button className="NaviMenuDropdownButtons" name="kiosks" onClick={setPage}>
            KIOSKS
          </button>
          <button className="NaviMenuDropdownButtons" name="messaging" onClick={setPage}>
            MESSAGE LISTS
          </button>
          <button className="NaviMenuDropdownButtons" name="rti-solutions" onClick={setPage}>
            RTI SOLUTIONS
          </button>
          <button className="NaviMenuDropdownButtons" name="tech_support_schedule" onClick={setPage}>
            SCHEDULE
          </button>
          <button className="NaviMenuDropdownButtons" name="sr_analytics" onClick={setPage}>
            SERVICE REPORT ANALYTICS
          </button>
          <button className="NaviMenuDropdownButtons" name="service_reports" onClick={setPage}>
            SERVICE REPORTS
          </button>
          <button className="NaviMenuDropdownButtons" name="tech_analytics" onClick={setPage}>
            TECH ANALYTICS
          </button>
          <button className="NaviMenuDropdownButtons" name="tech_call_logs" onClick={setPage}>
            TECH CALL LOGS
          </button>
          <button className="NaviMenuDropdownButtons" name="tech-solutions" onClick={setPage}>
            TECH SOLUTIONS
          </button>
          <button className="NaviMenuDropdownButtons" name="technicians" onClick={setPage}>
            TECHNICIANS
          </button>
          {userState.user.role ? (
            <button className="NaviMenuDropdownButtons" name="time_entries" onClick={setPage}>
              TIME ENTRY
            </button>
          ) : undefined}
          <button className="NaviMenuDropdownButtons" name="users" onClick={setPage}>
            USERS
          </button>
        </div>

        <div style={{ display: 'inline-block' }}>
          <button
            className="NavigationBarButtons"
            id="timeOff"
            onClick={(e) => DropDownMenu(e, 'dropdown-timeoff-small')}
          >
            TIME OFF
          </button>

          <div className="NaviMenuDropdownDiv" id="dropdown-timeoff-small">
            <button className="NaviMenuDropdownButtons" name="calendar" onClick={setPage}>
              CALENDAR
            </button>
            {userState.user.role ? (
              <button className="NaviMenuDropdownButtons" name="time_off" onClick={setPage}>
                TIME OFF REQUESTS
              </button>
            ) : null}
            {userState.user.access ? (
              <button className="NaviMenuDropdownButtons" name="tech_time_off" onClick={setPage}>
                TECH TIME OFF
              </button>
            ) : null}
            {/* <button className="NaviMenuDropdownButtons" name='EmpTimeOffRequest' onClick={DialogOpen}>TIME OFF REQUEST</button> */}
            <button className="NaviMenuDropdownButtons" name="my_time_sheet" onClick={setPage}>
              YOUR TIME SHEET
            </button>
          </div>
        </div>
        <div>
          <button
            className="NavigationBarButtons user-button"
            onClick={(e) => DropDownMenu(e, 'dropdown-user-actions')}
          >
            {userState.user.fullName}
          </button>
          <div id="dropdown-user-actions" className="NaviMenuDropdownDiv">
            <button className="NaviMenuDropdownButtons" name="TimeEntry" onClick={DialogOpen}>
              TIME ENTRY
            </button>
            <button className="NaviMenuDropdownButtons" name="LogOut" onClick={EndSession}>
              LOG OUT
            </button>
            <button className="NaviMenuDropdownButtons" name="password-reset" onClick={setPage}>
              RESET PASSWORD
            </button>
          </div>
        </div>
      </div>

      <div className="NavigationBarDiv" id="LargeNavigationBarDiv">
        <button className="NavigationBarButtons" name="" onClick={setPage}>
          JOBS
        </button>
        {userState.user.access ? (
          <button className="NavigationBarButtons" name="CreateJob" onClick={DialogOpen}>
            DISPATCH A JOB
          </button>
        ) : null}
        {userState.user.role ? (
          <button className="NavigationBarButtons" name="paper" onClick={setPage}>
            PAPER
          </button>
        ) : undefined}
        {userState.user.access ? (
          <div style={{ display: 'inline-block' }}>
            <button
              className="NavigationBarButtons"
              id="submitReports"
              onClick={() => $('#report-modal-container').toggle()}
            >
              LOGS
            </button>
          </div>
        ) : null}
        {userState.user.access ? (
          <button className="NavigationBarButtons" name="map" onClick={setPage}>
            MAP
          </button>
        ) : null}
        {userState.user.access ? (
          <button className="NavigationBarButtons" name="inventory" onClick={setPage}>
            INVENTORY
          </button>
        ) : null}
        {userState.user.access ? (
          <button className="NavigationBarButtons" name="service" onClick={setPage}>
            SERVICE REPORT
          </button>
        ) : null}
        {userState.user.access ? (
          <div style={{ display: 'inline-block' }}>
            <button className="NavigationBarButtons" id="tableManagement" onClick={(e) => DropDownMenu(e, 'dropdown2')}>
              TABLES
            </button>

            <div id="dropdown2" className="NaviMenuDropdownDiv">
              <button className="NaviMenuDropdownButtons" name="alert_analytics" onClick={setPage}>
                ALERT ANALYTICS
              </button>
              <button className="NaviMenuDropdownButtons" name="alerts_table" onClick={setPage}>
                ALERTS
              </button>
              <button className="NaviMenuDropdownButtons" name="call_logs" onClick={setPage}>
                CALL LOGS
              </button>
              <button className="NaviMenuDropdownButtons" name="drive_times" onClick={setPage}>
                DRIVE TIMES
              </button>
              <button className="NaviMenuDropdownButtons" name="email-list" onClick={setPage}>
                EMAIL LIST
              </button>
              <button className="NaviMenuDropdownButtons" name="issue_tracking" onClick={setPage}>
                ISSUE TRACKING
              </button>
              {userState.user.role ? (
                <button className="NaviMenuDropdownButtons" name="kiosk_issues" onClick={setPage}>
                  ISSUES
                </button>
              ) : undefined}
              {userState.user.role ? (
                <button className="NaviMenuDropdownButtons" name="job_responses" onClick={setPage}>
                  JOB RESPONSES
                </button>
              ) : undefined}
              <button className="NaviMenuDropdownButtons" name="kiosk_analytics" onClick={setPage}>
                KIOSK ANALYTICS
              </button>
              <button className="NaviMenuDropdownButtons" name="kiosks" onClick={setPage}>
                KIOSKS
              </button>
              <button className="NaviMenuDropdownButtons" name="messaging" onClick={setPage}>
                MESSAGE LISTS
              </button>
              <button className="NaviMenuDropdownButtons" name="rti-solutions" onClick={setPage}>
                RTI SOLUTIONS
              </button>
              <button className="NaviMenuDropdownButtons" name="tech_support_schedule" onClick={setPage}>
                SCHEDULE
              </button>
              <button className="NaviMenuDropdownButtons" name="sr_analytics" onClick={setPage}>
                SERVICE REPORT ANALYTICS
              </button>
              <button className="NaviMenuDropdownButtons" name="service_reports" onClick={setPage}>
                SERVICE REPORTS
              </button>
              <button className="NaviMenuDropdownButtons" name="tech_analytics" onClick={setPage}>
                TECH ANALYTICS
              </button>
              <button className="NaviMenuDropdownButtons" name="tech_call_logs" onClick={setPage}>
                TECH CALL LOGS
              </button>
              <button className="NaviMenuDropdownButtons" name="tech-solutions" onClick={setPage}>
                TECH SOLUTIONS
              </button>
              <button className="NaviMenuDropdownButtons" name="technicians" onClick={setPage}>
                TECHNICIANS
              </button>
              {userState.user.role ? (
                <button className="NaviMenuDropdownButtons" name="time_entries" onClick={setPage}>
                  TIME ENTRY
                </button>
              ) : undefined}
              <button className="NaviMenuDropdownButtons" name="users" onClick={setPage}>
                USERS
              </button>
            </div>
          </div>
        ) : null}
        <div style={{ display: 'inline-block' }}>
          <button className="NavigationBarButtons" id="timeOff" onClick={(e) => DropDownMenu(e, 'dropdown3')}>
            TIME OFF
          </button>

          <div id="dropdown3" className="NaviMenuDropdownDiv">
            <button className="NaviMenuDropdownButtons" name="calendar" onClick={setPage}>
              CALENDAR
            </button>
            {userState.user.role ? (
              <button className="NaviMenuDropdownButtons" name="time_off" onClick={setPage}>
                TIME OFF REQUESTS
              </button>
            ) : null}
            {userState.user.access ? (
              <button className="NaviMenuDropdownButtons" name="tech_time_off" onClick={setPage}>
                TECH TIME OFF
              </button>
            ) : null}
            {/* <button className="NaviMenuDropdownButtons" name='EmpTimeOffRequest' onClick={DialogOpen}>TIME OFF REQUEST</button> */}
            <button className="NaviMenuDropdownButtons" name="my_time_sheet" onClick={setPage}>
              YOUR TIME SHEET
            </button>
          </div>
        </div>
        <div style={{ display: 'inline-block' }}>
          <button className="NavigationBarButtons user-button" onClick={(e) => DropDownMenu(e, 'dropdown4')}>
            {userState.user.fullName}
          </button>
          <div id="dropdown4" className="NaviMenuDropdownDiv">
            <button className="NaviMenuDropdownButtons" name="TimeEntry" onClick={DialogOpen}>
              TIME ENTRY
            </button>
            <button className="NaviMenuDropdownButtons" name="LogOut" onClick={EndSession}>
              LOG OUT
            </button>
            <button className="NaviMenuDropdownButtons" name="password-reset" onClick={setPage}>
              RESET PASSWORD
            </button>
          </div>
        </div>
      </div>

      <div className="ViewingContentGoesHere" id="ViewingContent-render-target">
        {props.children}
      </div>
      <div className="app-footer" id="app-tag">
        &copy; {new Date().getFullYear()} Registration Technology Inc. {`v${version}`}
      </div>

      <KioskAlerts
        SetKioskIDIssueForCreateJob={SetKioskIDIssueForCreateJob}
        alertInfo={allAlerts}
        issues={IssuesDB}
        CallLogHistory={CallLogHistory}
      />
      <AlertBar
        visible={snackbar.visible}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleCloseSnack}
      />
      {userState.user.access ? (
        <CreateJob
          user={userState.user}
          lists={MobileEmailListsDB.filter((lists) => lists.ListName == 'Job Created')[0]}
          kioskIDIssueDes={kioskIDIssueDes}
          alertLength={AlertLength}
          kioskID={KioskID}
          kiosks={kiosks}
          kioskServerID={KioskServerID}
          issueDescription={IssueDescription}
          driveTimes={MapDriveTimes}
          technicianList={TechnicianList}
          kiosksInfo={KiosksDB}
          onAlert={handleOpenSnack}
        />
      ) : null}
      <TimeEntry
        user={userState.user}
        lists={MobileEmailListsDB.filter((lists) => lists.ListName == 'Time Entry')[0]}
      />
      <TimeOffRequest
        userID={userState.user.uid}
        allUsers={allUsers}
        fullName={userState.user.fullName}
        onAlert={handleOpenSnack}
      />
      {userState.user.access ? <ChatModal onAlert={handleOpenSnack} allChatPerms={userState.user.role} /> : null}
      {userState.user.access ? <ReportModal onAlert={handleOpenSnack} /> : null}
    </div>
  );
};

// export default withRouter(App);
export default App;
