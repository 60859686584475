import React, { useState, useEffect, useReducer, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import env from '../../env.config.json';
import Context from '../hoc/Context';
import { userReducer } from '../hoc/Reducers';
import * as jose from "jose";
import { jwtVerify } from 'jose';


const initialState = {
  user: null,
};

const MainAuth = (props) => {
  const [Auth, setAuth] = useState(null);
  const [userState, userDispatch] = useReducer(userReducer, initialState);

  // cache user state
  const contextValue = useMemo(() => {
    return { userState, userDispatch };
  }, [userState, userDispatch]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      verify_user();
    }

    return () => {
      mounted = false;
    }

  }, []);

  async function verify_user() {
    const username = window.localStorage.getItem('user');
    const token = window.localStorage.getItem('local-dispatch-console-user');
    const jwtSecret = env.SERVER.JWT_SECRET;

    if (username && token) {
      try {
        // Decode and verify the JWT
        const { payload } = await jwtVerify(token, new TextEncoder().encode(jwtSecret), {
          algorithms: ['HS256'],
        });

        // Now you can use the payload for further processing

        // Assuming socket is already connected and available
        const auth = payload; // Assuming payload contains the decoded JWT
        socket.emit('VerifyUserCreds', username, auth.id, (response) => {
          if (!response) {
            console.error('ERROR: There was an issue validating this user');
            setAuth(false);
          } else if (response.length > 0) {
            socket.emit('getUserByUsername', username, (user) => {
              if (!user) {
                console.error('ERROR: There was an issue getting this user\'s information');
                setAuth(false);
              } else {
                const user_obj = {
                  uid: user[0].UserID,
                  username: username,
                  fullName: window.localStorage.getItem('FullName'),
                  phoneNumber: user[0].PhoneNumber,
                  role: user[0].UserType === env.USER_PERMS[0] || user[0].UserType === env.USER_PERMS[2],
                  access: user[0].UserType !== env.USER_PERMS[4],
                  permissions: user[0].UserType,
                  user_logs: user[0].UserLogs,
                  email: user[0].Email,
                  roles: user[0].Role || null,
                };
                userDispatch({ type: 'SET_USER', user: user_obj });
                setAuth(true);
              }
            });
          } else {
            setAuth(false);
          }
        });
      } catch (error) {
        console.error(`ERROR: There was an issue verifying this user: ${error} on ${new Date()}`);
        setAuth(false);
      }
    } else {
      setAuth(false);
    }
  }
  return (
    <div className="app-main-body">
      <Context.Provider value={contextValue}>
        {Auth === true ? props.children : Auth === false ? <Navigate to={'inventory'} /> : null}
      </Context.Provider>
    </div>
  );
};

export default MainAuth;