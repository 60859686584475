/**
 * DatabaseErrorLogger component.
 *
 * This component logs errors to the database when a component error occurs.
 *
 * @returns {null} Returns null.
 */
import React, { useState, useEffect } from "react";
import axios from "axios";

const DatabaseErrorLogger = () => {
  // this will use the stack to grab the file name generating the error
  // const [error, setError] = useState(null);

  useEffect(() => {
    const handleComponentError = (error) => {
      // error logging here
      // setError(error);
      logErrorToDatabase(error);
    };

    /**
     * Logs an error to the database.
     * 
     * @param {Error} error - The error object to be logged.
     * @returns {Promise<void>} - A promise that resolves when the error is logged successfully.
     */
    const logErrorToDatabase = async (error) => {
      // api endpoint
      const apiUrl = "/api/database_error_logging";
      const DATETIME = new Date(Date.now()).toISOString().split("T").join(" ");
      const fileName = extractFileNameFromStackTrace(error.stack);

      // payload with error information
      const payload = {
        fileNameID: fileName,
        timestampID: DATETIME,
        errorMessage: error.message,
      };

      try {
        const response = await axios.post(apiUrl, payload);
        console.info(`Error log SUCCESS ${response.data}`);
      } catch (error) {
        console.error(`Error logging into database: ${error}`);
      }
    };

    // function to extract the file name for the stack trace
    /**
     * Extracts the file name from a stack trace.
     *
     * @param {string} stackTrace - The stack trace string.
     * @returns {string} The extracted file name.
     */
    const extractFileNameFromStackTrace = (stackTrace) => {
      const lines = stackTrace.split("\n");
      const fileNameLine = lines[1].trim();
      const fileName = fileNameLine.match(/([^/\\]+)\.([a-z]+):/i)[1];
      return fileName;
    };

    // attach error handler to catch component errors
    window.addEventListener("error", handleComponentError);

    return () => {
      // detach error handler when component unmounts
      window.removeEventListener("error", handleComponentError);
    };
  }, []);

  return null;
};
export default DatabaseErrorLogger;
