import React, { useState, useEffect, useRef } from 'react';
import Job from './Job';
import JobModal from './JobModal';
import Modal from '../../components/Modal';
import CustomTable from '../../components/Table';
import Button from '@mui/material/Button';
import AlertBar from '../../components/AlertBar';
import { FaPaperPlane as SendIcon, FaPlus as AddIcon } from 'react-icons/fa';
import {
  RenderPaperJobsForPDF,
  FormatLocalDateToUTC,
  FormatDate,
  FormatDate2,
  FormatTime,
  SeparateJobs,
  DateToLocale,
} from '../API/TextFormatingFunctions';
import JobResponse from '../../../public/Sounds/JobResponse.mp3';
import '../../StyleSheets/Jobs.css';
import { useCustomContext } from '../../hoc/Context';
import axios from 'axios';

const TableHeaders = [
  { id: 'kiosk', name: 'Kiosk', sortable: true, numeric: false },
  { id: 'job', name: 'Job #', sortable: true, numeric: false },
  { id: 'dispatcher', name: 'Dispatcher', sortable: true, numeric: false },
  { id: 'created', name: 'Created' },
  { id: 'issue', name: 'Issue', sortable: true, numeric: false },
  { id: 'reason', name: 'Reason', sortable: true, numeric: false },
  { id: 'paper', name: 'Paper Count'},
  { id: 'status', name: 'Status', sortable: true, numeric: false },
  { id: 'assigned', name: 'Assigned' },
  { id: 'tech', name: 'Technician', sortable: true, numeric: false },
  { id: 'eta', name: 'ETA' },
];


const TableTabs = [
  { id: 'Open', name: 'Open', filter: 'open' },
  { id: 'Pending', name: 'Pending', filter: 'pending' },
  { id: 'Completed', name: 'Completed', filter: 'complete' },
];

const SearchOptions = [
  { id: 'kiosk', name: 'Kiosk', pidx: 0 },
  { id: 'tech', name: 'Technician', pidx: 9 },
];

const Jobs = () => {
  const [jobID, setJobID] = useState('');
  const [PaperKioskIDKey, setPaperKioskIDKey] = useState(0);
  const [AllTracking, setAllTracking] = useState([]);
  const [TechnicianList, setTechnicianList] = useState([]);
  const [Jobs, setJobs] = useState([]);
  const [PaperJobs, setPaperJobs] = useState([]);
  const [KiosksDB, setKiosksDB] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [MobileEmailListsDB, setMobileEmailListsDB] = useState([]);
  const [JobDriveTimes, setJobDriveTimes] = useState([]);
  const [ActiveTab, setActiveTab] = useState(TableTabs[0].name);
  const [JobModalOpen, setJobModalOpen] = useState(false);
  const [techSupport, setTechSupport] = useState(null);
  const [SendingLetters, setSendingLetters] = useState(false);
  const [retrievingData, setRetrievingData] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [searchParam, setSearchParam] = useState(0);
  const [alertOpen, setAlertOpen] = useState({
    visible: false,
    message: '',
    severity: 'success',
  });
  const { userState, userDispatch } = useCustomContext();

  const stateRef = useRef();
  stateRef.current = ActiveTab;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (retrievingData) {
        socket.on('refreshJobPage', (bool) => {
          if (bool && ActiveTab === 'Open') {
            socket.emit('selectFromJobsWhereOpenAwaitingETAsAssigned', (result) => {
              setJobs(result);
              setLoading(false);
            });
          }
        });

        axios
          .post(`/api/table_data`, { table: 'job_table' })
          .then((res) => {
            if (res.data.status === 'OK') {
              const [
                tracking,
                email_list,
                tech_support,
                kiosks,
                techs,
                open_jobs,
              ] = res.data.data;

              setAllTracking(tracking);
              setMobileEmailListsDB(email_list);
              setTechSupport(tech_support[0].PhoneNumber);
              setKiosksDB(kiosks);
              setTechnicianList(
                JSON.parse(
                  '[' +
                    JSON.stringify(techs).replace(
                      /[\:\(\)\[\]\{\}]|("TechnicianName")/g,
                      ''
                    ) +
                    ']'
                )
              );
              setJobs(open_jobs);
              setLoading(false);
              setRetrievingData(false);
            } else {
              setLoading(false);
              setRetrievingData(false);
              setAlertOpen((prevState) => ({
                ...prevState,
                visible: true,
                message: res.data.error,
                severity: 'error',
              }));
            }
          })
          .catch((error) => {
            setLoading(false);
            setRetrievingData(false);
            setAlertOpen((prevState) => ({
              ...prevState,
              visible: true,
              message: error.message,
              severity: 'error',
            }));
          });
      }
    }

    if (JobModalOpen) {
      document.getElementById('JobModal').style.display = 'block';
      document.getElementById('JobMaintenanceContainer').style.display = 'block';
      document.getElementById('AssignBtn').disabled = false;
    }

    return () => {
      mounted = false;
    };
  }, [JobModalOpen]);

  const RefreshTableData = () => {
    const filter = ActiveTab === 'Completed' ? 'complete' : ActiveTab.toLowerCase();
    filterTable(filter);
  };
  const RenderJobModal = () => {
    if (ActiveTab === 'Open' && JobModalOpen) {
      return (
        <JobModal
          key={parseInt(jobID.replace(/[^\d.]/g, ''))}
          jobKey={jobID}
          technicianList={TechnicianList}
          fullName={userState.user.fullName}
          jobModal={
            Jobs.filter(function (id) {
              return id.JobID === jobID;
            })[0]
          }
          lists={
            MobileEmailListsDB.filter((lists) => lists.ListName == 'Job Assigned')[0]
          }
          PaperKioskIDKey={PaperKioskIDKey}
          kiosksInfo={KiosksDB}
          paperJobs={PaperJobs}
          allTracking={AllTracking}
          drive_times={JobDriveTimes}
          support={techSupport}
          close_modal={CloseModal}
          email_letter={EmailLetter}
          print_letter={PrintLetter}
          assign_tech={AssignTech}
        />
      );
    } else if (ActiveTab === 'Pending' && JobModalOpen) {
      return (
        <JobModal
          key={parseInt(jobID.replace(/[^\d.]/g, ''))}
          jobKey={jobID}
          technicianList={TechnicianList}
          fullName={userState.user.fullName}
          jobModal={
            Jobs.filter(function (id) {
              return id.JobID === jobID.split('-')[0];
            })[0]
          }
          lists={
            MobileEmailListsDB.filter((lists) => lists.ListName == 'Job Assigned')[0]
          }
          PaperKioskIDKey={PaperKioskIDKey}
          kiosksInfo={KiosksDB}
          allTracking={AllTracking}
          drive_times={JobDriveTimes}
          support={techSupport}
          close_modal={CloseModal}
          refresh_data={refreshData}
          email_letter={EmailLetter}
          print_letter={PrintLetter}
        />
      );
    }
  };

  const refreshData = () => {
    SwitchTab(null, false);
  };

  const CloseModal = () => {
    document.getElementById('JobModal').style.display = 'none';
    document.getElementById('JobMaintenanceContainer').style.display = 'none';
    setJobModalOpen(false);
  };

  const AssignTech = (e) => {
    let job_id = e.target.id;
    let name = e.target.name;

    if (ActiveTab == 'Open') {
      let kiosk_info = Jobs.filter((id) => {
        return id.JobID === job_id;
      })[0];
      let response_list =
        Jobs.filter((id) => {
          return id.JobID === job_id;
        }).length > 0
          ? JSON.parse(
              Jobs.filter((id) => {
                return id.JobID === job_id;
              })[0].MessagesSentTo
            )
          : [];
      const resp = response_list ? response_list.map((e) => `'${e.Name}'`).join() : null;
      if (job_id.charAt(0) === 'P') {
        if (resp) {
          socket.emit('getDriveTimesForJob', resp, kiosk_info.KioskID, (drive_times) => {
            if (!drive_times) {
              console.error('There was an issue getting drive times for this job.');
            } else {
              setJobID(job_id);
              setPaperKioskIDKey(name);
              setPaperJobs([]);
              setJobDriveTimes(drive_times);
              setJobModalOpen(true);
            }
          });
        } else {
          setJobID(job_id);
          setPaperKioskIDKey(name);
          setPaperJobs([]);
          setJobDriveTimes([]);
          setJobModalOpen(true);
        }
      } else {
        if (resp) {
          socket.emit('getDriveTimesForJob', resp, kiosk_info.KioskID, (drive_times) => {
            if (!drive_times) {
              console.error('There was an issue getting drive times for this job.');
            } else {
              socket.emit('selectFromJobsWherePending', kiosk_info.KioskID, (paper_jobs) => {
                if (!paper_jobs) {
                  console.error('There was an issue getting the paper jobs for this kiosk');
                } else {
                  setJobID(job_id);
                  setPaperKioskIDKey(0);
                  setJobDriveTimes(drive_times);
                  setPaperJobs(paper_jobs);
                  setJobModalOpen(true);
                }
              });
            }
          });
        } else {
          socket.emit('selectFromJobsWherePending', kiosk_info.KioskID, (paper_jobs) => {
            if (!paper_jobs) {
              console.error('There was an issue getting the paper jobs for this kiosk');
            } else {
              setJobID(job_id);
              setPaperKioskIDKey(0);
              setJobDriveTimes([]);
              setPaperJobs(paper_jobs);
              setJobModalOpen(true);
            }
          });
        }
      }
    } else if (ActiveTab == 'Pending') {
      if (job_id.charAt(0) === 'P') {
        const id = job_id.split('-')[0];
        let kiosk_info = Jobs.filter((id) => {
          return id.JobID === id;
        })[0];
        let response_list =
          Jobs.filter((id) => {
            return id.JobID === id;
          }).length > 0
            ? JSON.parse(
                Jobs.filter((id) => {
                  return id.JobID === id;
                })[0].Responses
              )
            : [];
        const resp = response_list
          ? response_list
              .filter((obj) => obj.Name)
              .map((e) => `'${e.Name}'`)
              .join()
          : null;
        if (resp) {
          socket.emit('getDriveTimesForJob', resp, kiosk_info.KioskID, (drive_times) => {
            if (!drive_times) {
              console.error('There was an issue getting drive times for this job.');
            } else {
              setJobID(id);
              setPaperKioskIDKey(name);
              setJobDriveTimes(drive_times);
              setJobModalOpen(true);
            }
          });
        } else {
          setJobID(id);
          setPaperKioskIDKey(name);
          setJobDriveTimes([]);
          setJobModalOpen(true);
        }
      }
    }
  };

  const PrintLetter = () => {
    const paper_job = Jobs.filter(function (id) {
      return id.JobID === jobID;
    })[0];

    const pdf_obj = {
      technician: paper_job.Technician,
      shipping: paper_job.Shipping ? paper_job.Shipping : 'Any',
      job_id: paper_job.JobID,
      jobs: SeparateJobs(RenderPaperJobsForPDF(JSON.parse(paper_job.Kiosks))),
      user: userState.user.fullName,
      email: 'dispatchers@registration-technology.com',
      cc: '',
    };

    axios.post('/api/send_paper_letters', { pdf_obj: pdf_obj, printing: true }, (res) => {
      if (!res) {
        console.error(`there was an issue retrieving this pdf doc`);
      } else {
        setAlertOpen((prevState) => ({
          ...prevState,
          visible: true,
          message: `Email was sent to: ${pdf_obj.email} for printing`,
          severity: 'success',
        }));
      }
    });
  };

  const EmailLetter = () => {
    let paper_job = Jobs.filter(function (id) {
      return id.JobID === jobID;
    })[0];
    const pdf_obj = {
      technician: paper_job.Technician,
      shipping: paper_job.Shipping ? paper_job.Shipping : 'Any',
      job_id: paper_job.JobID,
      jobs: SeparateJobs(RenderPaperJobsForPDF(JSON.parse(paper_job.Kiosks))),
      user: userState.user.fullName,
      email: paper_job.PersonalEmail ? paper_job.PersonalEmail : '',
    };

    if (pdf_obj.email) {
      axios.post('/api/send_paper_letters', { pdf_obj: pdf_obj, printing: false }, (res) => {
        if (res != 'OK') {
          setAlertOpen((prevState) => ({
            ...prevState,
            visible: true,
            message: `ERROR: The system encountered an issue with sending this letter.  Please notify the developer of this issue.`,
            severity: 'error',
          }));
        } else {
          setAlertOpen((prevState) => ({
            ...prevState,
            visible: true,
            message: `Email was sent to: ${pdf_obj.technician}`,
            severity: 'success',
          }));
        }
      });
    } else {
      setAlertOpen((prevState) => ({
        ...prevState,
        visible: true,
        message: `WARNING: Could not send this letter because this technician does not have an email address.  Please set this up within their tech profile and then try again.`,
        severity: 'error',
      }));
    }
  };

  const RenderEmailLettersModal = () => {
    let divtorender = null;

    divtorender = (
      <Modal modal_id="email_letters_modal" dimension={{ width: 350, height: 300 }} modal_header="">
        <div style={{ textAlign: 'center' }}>
          <p style={{ fontSize: '16px' }}>
            Please select the creation date of the paper jobs that you would like to send letters for and click{' '}
            <b>Send Letters</b>
          </p>
          <div style={{ margin: '30px' }}>
            <label>Creation Date</label>
            <br></br>
            <input type="date" id="letter_creation_date"></input>
          </div>
          <div>
            <Button className="rti-blue-round" variant="contained" disabled={SendingLetters} onClick={EmailLettersBulk}>
              {SendingLetters ? 'Sending...' : 'Send Letters'}
            </Button>
          </div>
        </div>
      </Modal>
    );

    return divtorender;
  };

  const EmailLettersBulk = () => {
    setSendingLetters(true);
    const creation_date = document.getElementById('letter_creation_date').value;
    if (creation_date) {
      const pdf_objs = Jobs.reduce((all_jobs, job) => {
        const [date, time] = FormatLocalDateToUTC(new Date(job.CreatedDate)).split(' ');
        if (date.trim() == creation_date) {
          all_jobs.push({
            technician: job.Technician,
            shipping: job.Shipping ? job.Shipping : 'Any',
            job_id: job.JobID,
            jobs: SeparateJobs(RenderPaperJobsForPDF(JSON.parse(job.Kiosks))),
            user: userState.user.fullName,
            email: job.PersonalEmail ? job.PersonalEmail : '',
            new_year: !job.NewYear ? false : job.NewYear,
          });
        }
        return all_jobs;
      }, []);

      if (pdf_objs.length) {
        axios.post('/api/send_paper_letters_bulk', { pdf_objs: pdf_objs, printing: false }, (res) => {
          if (res.status != 200) {
            setSendingLetters(false);
            setAlertOpen((prevState) => ({
              ...prevState,
              visible: true,
              message: `ERROR: The system encountered an issue with sending these letters.  Please notify the developer of this issue.`,
              severity: 'error',
            }));
          } else {
            setSendingLetters(false);
            setAlertOpen((prevState) => ({
              ...prevState,
              visible: true,
              message: `Paper letters are being emailed!  This could take a few minutes to send all letters to these technicians.\n\nYou can close this alert at any time.`,
              severity: 'success',
            }));
          }
        });
      } else {
        setSendingLetters(false);
        setAlertOpen((prevState) => ({
          ...prevState,
          visible: true,
          message: `WARNING:  There are no paper jobs for the creation date that you have selected.  Please choose a different date and try again.`,
          severity: 'warning',
        }));
      }
    } else {
      setSendingLetters(false);
      setAlertOpen((prevState) => ({
        ...prevState,
        visible: true,
        message: `WARNING:  Creation date cannot be blank.`,
        severity: 'warning',
      }));
    }
  };

  const filterTable = (filter) => {
    setJobs([]);
    setLoading(true);

    switch (filter) {
      case 'pending':
        socket.emit('selectFromJobsWherePending', null, (result) => {
          setActiveTab('Pending');
          setJobs(result);
          setLoading(false);
        });
        break;
      case 'complete':
        socket.emit('selectCompletedJobs', (result) => {
          setActiveTab('Completed');
          setJobs(result);
          setLoading(false);
        });
        break;
      case 'open':
        socket.emit('selectFromJobsWhereOpenAwaitingETAsAssigned', (result) => {
          setActiveTab('Open');
          setJobs(result);
          setLoading(false);
        });
        break;
    }
  };

  const search = (param, searchVal) => {
    setSearchValue(searchVal);
    setSearchParam(param);
  };

  const clearSearch = () => {
    setSearchValue('');
  };
  const onDispatchJob = () => {
    $('#CreateJobDialog').show();
    $('#CreateJobDialog').draggable();
    document.getElementById('CreateButton').disabled = false;
  };

  const RenderTableButtons = () => {
    return (
      <div style={{ display: 'flex' }}>
        <Button
          className="rti-blue-round"
          variant="contained"
          endIcon={<SendIcon style={{ color: 'white' }} fontSize="small" />}
          onClick={() => $('#email_letters_modal').toggleClass('flex-box')}
        >
          Email Letters
        </Button>
      </div>
    );
  };

  const RenderMainButtons = () => {
    return (
      <div style={{ display: 'flex' }}>
        <Button className="rti-blue-round" variant="contained" startIcon={<AddIcon />} onClick={onDispatchJob}>
          Dispatch Job
        </Button>
      </div>
    );
  };

  const getETAColor = (eta) => {
    const diff = (new Date(eta).getTime() - new Date().getTime()) / 1000 / 60;
    return diff <= 30 ? 'red' : null;
  };

  const getTableData = () => {
    switch (ActiveTab) {
      case 'Open':
        const open_paper_jobs = [];
        const open_jobs = [];
        const today = new Date();

        Jobs.forEach((jobBody, index) => {
          if (jobBody.JobID[0] == 'P' && jobBody.Technician) {
            const jobs = JSON.parse(jobBody.Kiosks);

            const open_paper = jobs.reduce((paper_jobs, job) => {
              if (job.Confirmed && job.ETA && job.KioskStatus == 'Open') {
                const [date] = job.ETA.split(' ');
                const estimated_arrival = new Date(date.trim());

                if (today.getTime() >= estimated_arrival.getTime()) {
                  paper_jobs.push({
                    kiosk: job.KioskID,
                    eta: job.ETA,
                    server: job.ServerID,
                  });
                }
              }
              return paper_jobs;
            }, []);

            if (open_paper.length && jobBody.JobStatus == 'Pending') {
              open_paper.forEach((job, idx) => {
                const { PrintCountCOVR, PrintCountOne, PrintCountTwo } = KiosksDB.find(
                  (kiosk) => kiosk.KioskID == job.kiosk
                );
                open_paper_jobs.push({
                  key: jobBody.JobID,
                  cells: [
                    { data: `${job.kiosk}\n${job.server}` },
                    { data: jobBody.JobID },
                    { data: jobBody.Dispatcher },
                    { data: `${FormatDate2(jobBody.CreatedDate)}\n${FormatTime(jobBody.CreatedDate)}` },
                    { data: jobBody.Description },
                    { data: jobBody.Reason ? jobBody.Reason : '' },
                    {
                      data: `Star: ${PrintCountCOVR ? PrintCountCOVR : ''}\nDM1: ${
                        PrintCountOne ? PrintCountOne : ''
                      }\nDM2: ${PrintCountTwo ? PrintCountTwo : ''}`,
                    },
                    { data: jobBody.JobStatus },
                    { data: `${FormatDate(jobBody.AssignedDate)}\n${FormatTime(jobBody.AssignedDate)}` },
                    { data: jobBody.Technician ? jobBody.Technician : 'Assign Tech' },
                    {
                      data: job.eta ? DateToLocale(new Date(job.eta)) : '-',
                      color: job.eta ? getETAColor(job.eta) : null,
                    },
                  ],
                });
              });
            } else if (jobBody.JobStatus == 'Open') {
              open_paper_jobs.push({
                key: jobBody.JobID,
                color:
                  (jobBody.JobStatus === 'Awaiting ETAs' || jobBody.JobStatus === 'Awaiting Response') &&
                  !jobBody.Responses
                    ? 'yellow'
                    : (jobBody.JobStatus === 'Awaiting ETAs' || jobBody.JobStatus === 'Awaiting Response') &&
                      jobBody.Responses
                    ? 'red'
                    : '',
                cells: [
                  { data: `${jobBody.KioskID}\n${jobBody.ServerID}` },
                  { data: jobBody.JobID },
                  { data: jobBody.Dispatcher },
                  { data: `${FormatDate2(jobBody.CreatedDate)}\n${FormatTime(jobBody.CreatedDate)}` },
                  { data: jobBody.Description },
                  { data: jobBody.Reason ? jobBody.Reason : '' },
                  { data: `Star: ${jobBody.star_count}\nDM1: ${jobBody.dm1_count}\nDM2: ${jobBody.dm2_count}` },
                  { data: jobBody.JobStatus },
                  { data: `${FormatDate(jobBody.AssignedDate)}\n${FormatTime(jobBody.AssignedDate)}` },
                  { data: jobBody.Technician ? jobBody.Technician : 'Assign Tech' },
                  {
                    data: jobBody.EstimateTimeArrival
                      ? jobBody.EstimateTimeArrival.replace(/\"/g, '')
                      : jobBody.EstimateTimeArrival,
                  },
                ],
              });
            }
          } else {
            open_jobs.push({
              key: jobBody.JobID,
              color:
                (jobBody.JobStatus === 'Awaiting ETAs' || jobBody.JobStatus === 'Awaiting Response') &&
                !jobBody.Responses
                  ? 'yellow'
                  : (jobBody.JobStatus === 'Awaiting ETAs' || jobBody.JobStatus === 'Awaiting Response') &&
                    jobBody.Responses
                  ? 'red'
                  : '',
              cells: [
                { data: `${jobBody.KioskID}\n${jobBody.ServerID}` },
                { data: jobBody.JobID },
                { data: jobBody.Dispatcher },
                { data: `${FormatDate2(jobBody.CreatedDate)}\n${FormatTime(jobBody.CreatedDate)}` },
                { data: jobBody.Description },
                { data: jobBody.Reason ? jobBody.Reason : '' },
                { data: `Star: ${jobBody.star_count}\nDM1: ${jobBody.dm1_count}\nDM2: ${jobBody.dm2_count}` },
                { data: jobBody.JobStatus },
                { data: `${FormatDate(jobBody.AssignedDate)}\n${FormatTime(jobBody.AssignedDate)}` },
                { data: jobBody.Technician ? jobBody.Technician : 'Assign Tech' },
                {
                  data: jobBody.EstimateTimeArrival
                    ? jobBody.EstimateTimeArrival.replace(/\"/g, '')
                    : jobBody.EstimateTimeArrival,
                },
              ],
            });
          }
        });

        return [...open_jobs, ...open_paper_jobs].filter((row) =>
          searchValue && searchParam
            ? row.cells[SearchOptions[searchParam - 1].pidx].data.toUpperCase().includes(searchValue.toUpperCase())
            : row
        );
      case 'Pending':
        let dataRe = [];
        for (let i = 0; i < Jobs.length; i++) {
          if (Jobs[i].Kiosks) {
            let KiosksT = JSON.parse(Jobs[i].Kiosks);
            for (let j = 0; j < KiosksT.length; j++) {
              if (KiosksT[j].KioskStatus == 'Open') {
                dataRe.push({
                  JobID: Jobs[i].JobID,
                  PaperJobID: Jobs[i].PaperJobID,
                  KioskID: KiosksT[j].KioskID,
                  AfterDate: KiosksT[j].AfterDate,
                  StarRolls: KiosksT[j].StarRolls,
                  BeforeDate: KiosksT[j].BeforeDate,
                  ServerID: KiosksT[j].ServerID,
                  StoreName: KiosksT[j].StoreName,
                  StoreAddress: KiosksT[j].StoreAddress,
                  StoreCity: KiosksT[j].StoreCity,
                  JobStatus: KiosksT[j].KioskStatus,
                  Type: KiosksT[j].Type,

                  Kiosks: KiosksT,
                  StoreState: Jobs[i].StoreState,
                  StoreZip: Jobs[i].StoreZip,
                  StartDate: Jobs[i].StartDate,
                  EndDate: Jobs[i].EndDate,
                  Description: Jobs[i].Description,
                  CreatedDate: Jobs[i].CreatedDate,
                  AssignedDate: Jobs[i].AssignedDate,
                  CompletedDate: Jobs[i].CompletedDate,
                  Dispatcher: Jobs[i].Dispatcher,
                  Technician: Jobs[i].Technician,
                  PersonalEmail: Jobs[i].PersonalEmail,
                  EstimateTimeArrival: Jobs[i].EstimateTimeArrival,
                  MessagesSentTo: Jobs[i].MessagesSentTo,
                  Responses: Jobs[i].Responses,
                  TempTechnician: Jobs[i].TempTechnician,
                  Reason: Jobs[i].Reason,
                });
              }
            }
          }
        }

        dataRe.forEach((job) => {
          const kiosk = KiosksDB.find((kiosk) => kiosk.KioskID == job.KioskID);
          if (kiosk) {
            job.dm1_count = kiosk.PrintCountOne;
            job.dm2_count = kiosk.PrintCountTwo;
            job.star_count = kiosk.PrintCountCOVR;
          }
        });

        let jobs = dataRe;

        return jobs
          .map((jobBody, index) => ({
            key: `${jobBody.JobID}-${index}`,
            color:
              (jobBody.JobStatus === 'Awaiting ETAs' || jobBody.JobStatus === 'Awaiting Response') && !jobBody.Responses
                ? 'yellow'
                : (jobBody.JobStatus === 'Awaiting ETAs' || jobBody.JobStatus === 'Awaiting Response') &&
                  jobBody.Responses
                ? 'red'
                : '',
            cells: [
              { data: `${jobBody.KioskID}\n${jobBody.ServerID}` },
              { data: jobBody.JobID },
              { data: jobBody.Dispatcher },
              { data: `${FormatDate2(jobBody.CreatedDate)}\n${FormatTime(jobBody.CreatedDate)}` },
              { data: jobBody.Description },
              { data: jobBody.Reason ? jobBody.Reason : '' },
              { data: `Star: ${Job.star_count}\nDM1: ${Job.dm1_count}\nDM2: ${Job.dm2_count}` },
              { data: jobBody.JobStatus },
              { data: `${FormatDate(jobBody.AssignedDate)}\n${FormatTime(jobBody.AssignedDate)}` },
              { data: jobBody.Technician ? jobBody.Technician : 'Assign Tech' },
              {
                data: jobBody.EstimateTimeArrival
                  ? jobBody.EstimateTimeArrival.replace(/\"/g, '')
                  : jobBody.EstimateTimeArrival,
              },
            ],
          }))
          .filter((row) =>
            searchValue && searchParam
              ? row.cells[SearchOptions[searchParam - 1].pidx].data.toUpperCase().includes(searchValue.toUpperCase())
              : row
          );
      case 'Completed':
        let tempdata = [];

        tempdata = Jobs.sort(function (a, b) {
          let c = new Date(a.CompletedDate);
          let d = new Date(b.CompletedDate);

          return d.getTime() - c.getTime();
        });

        return tempdata
          .map((jobBody, index) => ({
            key: `${jobBody.JobID}-${index}`,
            color:
              (jobBody.JobStatus === 'Awaiting ETAs' || jobBody.JobStatus === 'Awaiting Response') && !jobBody.Responses
                ? 'yellow'
                : (jobBody.JobStatus === 'Awaiting ETAs' || jobBody.JobStatus === 'Awaiting Response') &&
                  jobBody.Responses
                ? 'red'
                : '',
            cells: [
              { data: `${jobBody.KioskID}\n${jobBody.ServerID}` },
              { data: jobBody.JobID },
              { data: jobBody.Dispatcher },
              { data: `${FormatDate2(jobBody.CreatedDate)}\n${FormatTime(jobBody.CreatedDate)}` },
              { data: jobBody.Description },
              { data: jobBody.Reason ? jobBody.Reason : '' },
              { data: `Star: ${jobBody.star_count}\nDM1: ${jobBody.dm1_count}\nDM2: ${jobBody.dm2_count}` },
              { data: jobBody.JobStatus },
              { data: `${FormatDate(jobBody.AssignedDate)}\n${FormatTime(jobBody.AssignedDate)}` },
              { data: jobBody.Technician ? jobBody.Technician : 'Assign Tech' },
              {
                data: jobBody.EstimateTimeArrival
                  ? jobBody.EstimateTimeArrival.replace(/\"/g, '')
                  : jobBody.EstimateTimeArrival,
              },
            ],
          }))
          .filter((row) =>
            searchValue && searchParam
              ? row.cells[SearchOptions[searchParam - 1].pidx].data.toUpperCase().includes(searchValue.toUpperCase())
              : row
          );
    }
  };

  const handleCloseSnack = () => {
    setAlertOpen((prevState) => ({ ...prevState, visible: false }));
  };

  return (
    <div className="ViewedContentContainer">
      <div className="ViewedContentContainer" id="JobContainer">
        <div id="JobModal" className="JobModal">
          {RenderJobModal()}
        </div>

        {RenderEmailLettersModal()}

        <div className="TitleBarContainer">
          <h1
            title="View Open, Pending, Completed, or Deleted Jobs from this screen. Open jobs include any regular jobs dispatched for an issue, or Paper Change jobs pending assignment. Pending Jobs are assigned Paper Change Jobs. Completed Jobs include Completed and Manually Deleted Jobs."
          >
            Jobs
          </h1>
        </div>

        <AlertBar
          visible={alertOpen.visible}
          onClose={handleCloseSnack}
          message={alertOpen.message}
          severity={alertOpen.severity}
        />

        <CustomTable
          searchable
          paginate
          loading_data={Loading}
          search={search}
          clear_search={clearSearch}
          search_options={SearchOptions}
          tabs={TableTabs}
          tab_style="button"
          active_tab={ActiveTab}
          filter_data={filterTable}
          headers={TableHeaders}
          rows={getTableData()}
          onClick={AssignTech}
          onRefresh={RefreshTableData}
          table_buttons={ActiveTab == 'Pending' ? RenderTableButtons() : RenderMainButtons()}
        />

        <audio id="responseRecievedAudio" src={JobResponse}></audio>
      </div>
    </div>
  );
};

export default Jobs;
