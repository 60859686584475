/**
 * AlertBar component.
 * 
 * @component
 * @param {string} message - The message to be displayed in the alert bar.
 * @param {boolean} visible - Determines whether the alert bar is visible or not.
 * @param {string} severity - The severity level of the alert bar. Possible values are 'success', 'error', and 'warning'.
 * @param {function} onClose - The function to be called when the alert bar is closed.
 * @returns {JSX.Element} The AlertBar component.
 */
import React, { useRef, useState } from 'react';
import SnackBar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { AiOutlineClose } from 'react-icons/ai';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { AiOutlineWarning } from 'react-icons/ai';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import '../StyleSheets/Snack.css';

const AlertBar = ({ message = '', visible = false, severity = 'success', onClose }) => {
    return (
        <SnackBar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            className={`snack ${severity}`}
            open={visible}
            message={message}
            autoHideDuration={severity === 'success' ? 3000 : null}
            onClose={onClose}
            action={
                severity !== 'success' ? (
                    <div>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
                            <AiOutlineClose fontSize="small" />
                        </IconButton>
                    </div>
                ) : null
            }
        ></SnackBar>
    );
};

export default AlertBar;
